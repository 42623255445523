import React from 'react';

import { TextField } from '@material-ui/core';

import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { defaultTo, get } from 'lodash';

const FormikTextField = ({
  type = 'text',
  name,
  variant = 'outlined',
  helperText,
  ...rest
}) => {
  const {
    values,
    errors,
    touched,
    submitCount,
    handleBlur,
    handleChange
  } = useFormikContext();

  // Show field error if form has been submitted or if the field has been touched
  const showError = Boolean((submitCount > 0 || get(touched, name)) && get(errors, name));

  return (
    <TextField
      type={type}
      name={name}
      variant={variant}
      value={defaultTo(get(values, name), '')}
      onBlur={handleBlur}
      onChange={handleChange}
      error={showError}
      helperText={showError ? get(errors, name) : helperText}
      {...rest}
    />
  );
};

FormikTextField.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  variant: PropTypes.string,
  helperText: PropTypes.string
};

export default FormikTextField;
