/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';

import {
  withStyles,
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

import PropTypes from 'prop-types';
import { DropzoneArea } from 'material-ui-dropzone';

const styles = (theme) => ({
  dropzoneRoot: {
    backgroundColor: grey[50],
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),

    '& .MuiDropzonePreviewList-removeButton': {
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)'
    }
  },

  dropzoneIcon: {
    color: grey[500]
  },

  dropzoneText: {
    color: grey[600],
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1)
  },
});

const DropzoneAreaStyled = ({ classes, ...rest }) => {
  return (
    <DropzoneArea
      classes={{
        root: classes.dropzoneRoot,
        icon: classes.dropzoneIcon,
        text: classes.dropzoneText
      }}
      {... rest}
      // previewGridProps={{ container: { spacing: 1 }, item: { xs: 6, sm: 4 } }}
    />
  );
};

DropzoneAreaStyled.propTypes = {
  classes: PropTypes.object
};

export default withStyles(styles)(DropzoneAreaStyled);
