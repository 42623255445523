import {
  InsertPhoto as DefaultItemIcon,
} from '@material-ui/icons';
import { useCompositionContext } from 'src/composition';

const useItemIcon = (options) => {
  const composition = useCompositionContext();

  const { defaultIcon } = options || {};

  const fetchItemIcon = (item) => {
    if (item) {
      if (item.iconResource) {
        const IconResource = composition.fetchResource(item.iconResource);
        if (IconResource) {
          return IconResource;
        }
      }

      if (item.icon) {
        return item.icon;
      }
    }

    if (defaultIcon) {
      return defaultIcon;
    }

    return DefaultItemIcon;
  };

  return { fetchItemIcon };
};

export default useItemIcon;
