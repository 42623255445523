/* eslint-disable no-unused-vars */
import React, { useState, useRef, forwardRef, useEffect, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';

import { useWindowResizeDebounced } from 'src/utils/react';

const withWidth = (WrappedComponent) => {
  const WidthProvider = forwardRef(({
    measureBefore,
    debounceInterval = 250,
    defaultWidth = 1280,
    maxWidth,
    adjustWidth = 0,
    componentRef,
    ...rest }, ref) => {
    const [width, setWidth] = useState(-1);
    const measureRef = useRef();

    const debounceTimerRef = useRef();
    const debounceIntervalRef = useRef();

    const evaluateWidth = () => {
      const node = measureRef.current;
      if (node instanceof HTMLElement) {
        let newWidth = Math.max(0, node.offsetWidth + adjustWidth);
        if (maxWidth && newWidth > maxWidth) {
          newWidth = maxWidth;
        }
        setWidth(newWidth);
      }
    };

    useImperativeHandle(ref, () => ({
      refresh: () => evaluateWidth()
    }));

    useWindowResizeDebounced(evaluateWidth, debounceInterval);

    useEffect(() => {
      evaluateWidth();
    }, [maxWidth]);

    const componentWidth = width !== -1 ? width : defaultWidth;
    const renderWrapped = !measureBefore || width !== -1;

    return (
      <>
        <div ref={measureRef} style={{ height: 0, width: '100%', visibility: 'hidden' }} />
        {renderWrapped && (
          <WrappedComponent ref={componentRef} width={componentWidth} {...rest} />
        )}
      </>
    );
  });

  WidthProvider.propTypes = {
    measureBefore: PropTypes.bool,
    debounceInterval: PropTypes.number,
    defaultWidth: PropTypes.number,
    maxWidth: PropTypes.number,
    adjustWidth: PropTypes.number,
    componentRef: PropTypes.any
  };

  return WidthProvider;
};

export default withWidth;
