/* eslint-disable max-len */
import React from 'react';
import { SvgIcon } from '@material-ui/core';

const Icon = (props) => {
  return (
    <SvgIcon {...props}>
      <g id="Device">
        <g transform="matrix(0.764706,0,0,1.125,2.44118,-2.0625)">
          <path d="M21,6.181C21,5.253 19.892,4.5 18.527,4.5L6.473,4.5C5.108,4.5 4,5.253 4,6.181L4,18.819C4,19.747 5.108,20.5 6.473,20.5L18.527,20.5C19.892,20.5 21,19.747 21,18.819L21,6.181Z" style={{ fill: 'none', stroke: 'currentColor', strokeWidth: '2.08px' }} />
        </g>
        <g transform="matrix(0.628865,0,0,0.91818,4.14475,0.359496)">
          <path d="M7.277,17.09L7.277,14.8L13.695,14.8L13.695,12.678L18.454,15.945L13.695,19.212L13.695,17.09L7.277,17.09Z" />
        </g>
        <g transform="matrix(-0.671006,0,0,0.956437,20.6329,-6.12552)">
          <path d="M7.277,17.09L7.277,14.801L13.808,14.801L13.808,12.678L18.454,15.945L13.808,19.212L13.808,17.09L7.277,17.09Z" />
        </g>
        <g transform="matrix(6.12323e-17,-1,1,6.12323e-17,0.25,23.75)">
          <path d="M19.75,20.125C19.75,19.78 19.47,19.5 19.125,19.5L4.875,19.5C4.53,19.5 4.25,19.78 4.25,20.125L4.25,21.375C4.25,21.72 4.53,22 4.875,22L19.125,22C19.47,22 19.75,21.72 19.75,21.375L19.75,20.125ZM17.5,20C17.914,20 18.25,20.336 18.25,20.75C18.25,21.164 17.914,21.5 17.5,21.5C17.086,21.5 16.75,21.164 16.75,20.75C16.75,20.336 17.086,20 17.5,20ZM12,20C12.414,20 12.75,20.336 12.75,20.75C12.75,21.164 12.414,21.5 12,21.5C11.586,21.5 11.25,21.164 11.25,20.75C11.25,20.336 11.586,20 12,20ZM9.25,20C9.664,20 10,20.336 10,20.75C10,21.164 9.664,21.5 9.25,21.5C8.836,21.5 8.5,21.164 8.5,20.75C8.5,20.336 8.836,20 9.25,20ZM6.5,20C6.914,20 7.25,20.336 7.25,20.75C7.25,21.164 6.914,21.5 6.5,21.5C6.086,21.5 5.75,21.164 5.75,20.75C5.75,20.336 6.086,20 6.5,20ZM14.75,20C15.164,20 15.5,20.336 15.5,20.75C15.5,21.164 15.164,21.5 14.75,21.5C14.336,21.5 14,21.164 14,20.75C14,20.336 14.336,20 14.75,20Z" />
        </g>
        <g transform="matrix(6.12323e-17,-1,1,6.12323e-17,-17.75,23.75)">
          <path d="M19.75,20.125C19.75,19.78 19.47,19.5 19.125,19.5L4.875,19.5C4.53,19.5 4.25,19.78 4.25,20.125L4.25,21.375C4.25,21.72 4.53,22 4.875,22L19.125,22C19.47,22 19.75,21.72 19.75,21.375L19.75,20.125ZM17.5,20C17.914,20 18.25,20.336 18.25,20.75C18.25,21.164 17.914,21.5 17.5,21.5C17.086,21.5 16.75,21.164 16.75,20.75C16.75,20.336 17.086,20 17.5,20ZM12,20C12.414,20 12.75,20.336 12.75,20.75C12.75,21.164 12.414,21.5 12,21.5C11.586,21.5 11.25,21.164 11.25,20.75C11.25,20.336 11.586,20 12,20ZM9.25,20C9.664,20 10,20.336 10,20.75C10,21.164 9.664,21.5 9.25,21.5C8.836,21.5 8.5,21.164 8.5,20.75C8.5,20.336 8.836,20 9.25,20ZM6.5,20C6.914,20 7.25,20.336 7.25,20.75C7.25,21.164 6.914,21.5 6.5,21.5C6.086,21.5 5.75,21.164 5.75,20.75C5.75,20.336 6.086,20 6.5,20ZM14.75,20C15.164,20 15.5,20.336 15.5,20.75C15.5,21.164 15.164,21.5 14.75,21.5C14.336,21.5 14,21.164 14,20.75C14,20.336 14.336,20 14.75,20Z" />
        </g>
      </g>
    </SvgIcon>
  );
};

export default Icon;
