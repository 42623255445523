/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React, { useState, useRef, useEffect } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  makeStyles,
} from '@material-ui/core';
// import { grey } from '@material-ui/core/colors';
import {
  LockOutlined as HeaderIcon
} from '@material-ui/icons';

import PropTypes from 'prop-types';
import { Formik, useFormikContext } from 'formik';
import { useErrorHandler } from 'react-error-boundary';
import { get, includes } from 'lodash';
import { grey } from '@material-ui/core/colors';
import * as Yup from 'yup';

import { useSystemContext } from 'src/service/System';
import {
  FormProgress,
  FormikSelect,
  FormikTextField,
  FormikCheckboxes,
  formMergeErrors,
  HiddenSubmitButton,
} from 'src/components/form';
import { useIsMounted } from 'src/utils/react';
import { ActionDialogTitle, LimitedBackdrop, GlobalMessages } from 'src/components/core';

const useStyles = makeStyles(() => ({
  listItem: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: grey[300]
    }
  },

  listItemSelected: {
    background: grey[100],
  },

  checkboxesItem: {
    flexBasis: '100%',
  },

}));

const focusInput = (input, name, focus) => {
  if (focus === name && input.current) {
    input.current.focus();
  }
};

const ForgotPasswordDialog = ({
  open,
  onCancel,
  messages,
  setMessages,
  waiting = false,
  avatar,
  focus,
  ...rest
}) => {
  const {
    handleSubmit,
    values,
    submitForm,
    isSubmitting,
  } = useFormikContext();

  const classes = useStyles();
  const isMounted = useIsMounted();

  const forgotNameInputRef = useRef();

  useEffect(() => {
    /*
     * Note: autoFocus or callback refs to focus don't work on dialogs
     * because they are called before the inputs are focusable
     * (may not yet be added to the page DOM). Using a hacky delay
     * until we find a cleaner solution.
     */
    setTimeout(() => {
      if (focus && isMounted()) {
        focusInput(forgotNameInputRef, 'forgotName', focus);
      }
    }, 100);
  }, [focus]);

  const handleClose = () => {
    if (onCancel) {
      onCancel();
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        {...rest}
      >
        <LimitedBackdrop open={waiting} />
        {waiting && <FormProgress />}
        <ActionDialogTitle title="Forgot Password" avatar={avatar || HeaderIcon} />
        <DialogContent dividers className={classes.dialogContent}>
          <form name="forgot" onSubmit={handleSubmit} autoComplete="off" noValidate>
            <GlobalMessages
              messages={messages}
              severity="error"
              onClear={() => setMessages(null)}
              containerProps={{ mb: 3 }}
            />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormikTextField name="forgotName" label="User Name" required fullWidth inputRef={forgotNameInputRef} />
              </Grid>
            </Grid>
            <HiddenSubmitButton />
          </form>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button disabled={isSubmitting} onClick={submitForm} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

ForgotPasswordDialog.propTypes = {
  messages: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  setMessages: PropTypes.func,
  open: PropTypes.bool,
  onCancel: PropTypes.func,
  focus: PropTypes.string,
  waiting: PropTypes.bool,
  avatar: PropTypes.oneOfType([PropTypes.element, PropTypes.elementType]),
};

const ForgotPassword = ({
  initializeWait,
  onSave,
  onCancel,
  ...rest
}) => {
  const [open, setOpen] = useState(() => (initializeWait == null || initializeWait <= 0));
  const [messages, setMessages] = useState(null);

  const handleError = useErrorHandler();
  const isMounted = useIsMounted();
  const system = useSystemContext();

  useEffect(() => {
    if (initializeWait != null && initializeWait > 0) {
      setTimeout(() => {
        if (isMounted()) {
          setOpen(true);
        }
      }, initializeWait);
    }
  }, []);

  const processSubmit = (values, actions) => {
    setMessages(null);
    system.axios()
      .post('/password/send', values)
      .then(({ data }) => {
        actions.setSubmitting(false);
        if (!data.errors) {
          if (onSave()) {
            onSave();
          }
        } else {
          formMergeErrors(data.errors, actions, setMessages);
        }
      })
      .catch(handleError);
  };

  return (
    <Formik
      enableReinitialize
      validationSchema={Yup.object().shape({
        forgotName: Yup.string()
          .max(255)
          .required(),
      })}
      onSubmit={processSubmit}
      initialValues={{}}
    >
      {({ isSubmitting }) => {
        const waiting = isSubmitting;

        return (
          <ForgotPasswordDialog
            open={open}
            onCancel={onCancel}
            messages={messages}
            setMessages={setMessages}
            waiting={waiting}
            {...rest}
          />
        );
      }}
    </Formik>
  );
};

ForgotPassword.propTypes = {
  initializeWait: PropTypes.number,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
};

const ForgotPasswordWrapper = ({ open, ...rest }) => {
  return !open ? null : <ForgotPassword {...rest} />;
};

ForgotPasswordWrapper.propTypes = {
  open: PropTypes.bool
};

export default ForgotPasswordWrapper;
