/* eslint-disable no-unused-vars */
import { CompassCalibrationRounded } from '@material-ui/icons';
import { compact, defaultTo } from 'lodash';

import { CompareToBuilder } from 'src/utils/core';

const fetchSortString = (item) => {
  if (item.sortKey) {
    return `${item.sortKey}_${item.title}`;
  }

  return item.title;
};

const formatItems = (items, options, level, sort) => {
  if (!items) {
    return [];
  }

  const sorting = defaultTo(sort, true);

  const tempItems = compact(items.map((item) => {
    if (options.filter && !options.filter(item)) {
      return null;
    }

    if (!item.items) {
      return item;
    }

    const formattedItems = formatItems(item.items, options, level + 1, defaultTo(item.sort, sorting));
    if (formattedItems.length === 0) {
      if (options.removeEmpty) {
        return null;
      }
    } else if (formattedItems.length === 1) {
      if (options.collapse) {
        return formattedItems[0];
      }
    }

    return { ...item, items: formattedItems };
  }));

  if (sorting && options.sortLevel !== -1 && level >= options.sortLevel) {
    tempItems.sort((a, b) => (
      new CompareToBuilder()
        .appendString(fetchSortString(a), fetchSortString(b))
        .toComparison()
    ));
  }

  return tempItems;
};

const DEFAULT_OPTIONS = {
  removeEmpty: true,
  collapse: true,
  sortLevel: 1
};

export default (items, options) => {
  return formatItems(items, { ...DEFAULT_OPTIONS, ...options }, 0);
};
