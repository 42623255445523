import React from 'react';

import ErrorView from './ErrorView';

const NotFoundView = () => {
  return (
    <ErrorView title="404">
      Page not found.
    </ErrorView>
  );
};

export default NotFoundView;
