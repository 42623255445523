/* eslint-disable no-unused-vars */
/**
 * Fixes react router V6 navigation and links that are not relative to the base url.
 */
import React, { forwardRef, useContext, useState, useEffect, useMemo } from 'react';
import {
  Link,
  NavLink,
  Navigate,
  Outlet,
  useHref,
  useNavigate,
  useLocation,
  useParams,
  usePrompt
} from 'react-router-dom';
import PropTypes from 'prop-types';

function resolveHref(href) {
  if (href && href.startsWith('/')) {
    return process.env.PUBLIC_URL + href;
  }

  return href;
}

// function withFixedTo(WrappedComponent) {
//   const Enhanced = forwardRef(({ to, ...rest }, ref) => {
//     // const { to } = props;
//     // const newProps = { ...props, to: resolveHref(to) };
//     return <WrappedComponent ref={ref} to={resolveHref(to)} {...rest} />;
//   });

//   Enhanced.propTypes = {
//     to: PropTypes.string
//   };

//   return Enhanced;
// }

// function useHref(to) {
//   return routerUseHref(resolveHref(to));
// }

// function useNavigate() {
//   const routerNavigate = routerUseNavigate();
//   return (to, options) => {
//     return routerNavigate(resolveHref(to), options);
//   };
// }

function withOutlet(WrappedComponent) {
  const Enhanced = forwardRef(({ ...rest }, ref) => {
    return (
      <WrappedComponent ref={ref} {...rest}>
        <Outlet />
      </WrappedComponent>
    );
  });

  // Enhanced.propTypes = {
  //   to: PropTypes.string
  // };

  return Enhanced;
}

// /**
//  * Prevents navigation away from the current page using a window.confirm prompt
//  * with the given message.
//  */
// function usePrompt(message, when = true) {
//   const blocker = React.useCallback(
//     (tx) => {
//       // eslint-disable-next-line no-alert
//       if (window.confirm(message)) tx.retry();
//     },
//     [message]
//   );

//   useBlocker(blocker, when);
// }

// const Link = withFixedTo(RouterLink);

// const NavLink = withFixedTo(RouterNavLink);

// const Navigate = withFixedTo(RouterNavigate);

export { resolveHref, useHref, useNavigate, useLocation, useParams, withOutlet, usePrompt };

export { Link, NavLink, Navigate, Outlet };
