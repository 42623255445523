/* eslint-disable no-unused-vars */
import React from 'react';

import {
  // Button,
  Link,
  Snackbar,
  makeStyles
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';

import PropTypes from 'prop-types';
import { isArray, first } from 'lodash';

const useStyles = makeStyles((theme) => ({
  alertIcon: {
    // alignItems: 'center'
  },

  alertAction: {
    paddingTop: theme.spacing(0.5),
    alignItems: 'flex-start'
  }
}));

const VersionSnackbar = ({ open = true, onClose, ...rest }) => {
  const classes = useStyles();

  if (!open) {
    return null;
  }

  const handleOnClose = () => {
    if (onClose) {
      onClose();
    }
  };

  const handleRefresh = () => {
    window.location.href = process.env.PUBLIC_URL || '/';
  };

  return (
    <Snackbar open {...rest}>
      <Alert
        elevation={6}
        variant="outlined"
        onClose={handleOnClose}
        severity="warning"
        style={{ backgroundColor: '#FFFFFF' }}
        classes={{ icon: classes.alertIcon, action: classes.alertAction }}
      >
        {/* <AlertTitle>Site Version</AlertTitle> */}
        A new version of this site is available. Refresh your browser or
        {' '}
        <Link underline="always" onClick={handleRefresh}>
          click here
        </Link>
        {' '}
        to reload.
      </Alert>
    </Snackbar>
  );
};

VersionSnackbar.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default VersionSnackbar;
