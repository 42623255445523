import React, { useEffect, useRef } from 'react';

import { makeStyles } from '@material-ui/core';

import PropTypes from 'prop-types';
import Prism from 'prismjs';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  pre: {
    '&[class*="language-"]': {
      margin: 0
    }
  },
}));

const PrismCode = ({ className, plugins, fullWidth, language, children }) => {
  const codeRef = useRef();

  const baseClasses = useStyles();

  useEffect(() => {
    // Prism.highlightAll();
    if (codeRef.current) {
      Prism.highlightElement(codeRef.current);
    }
  });

  return (
    <pre className={clsx(plugins, className, baseClasses.pre)} style={fullWidth == null ? null : { width: '100%' }}>
      <code ref={codeRef} className={`language-${language}`}>{children}</code>
    </pre>
  );
};

PrismCode.propTypes = {
  // code: PropTypes.string,
  className: PropTypes.string,
  plugins: PropTypes.array,
  fullWidth: PropTypes.bool,
  language: PropTypes.string,
  children: PropTypes.any,
};

export default PrismCode;
