import React from 'react';

import {
  Box,
  Dialog,
  Divider,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import {
  Close as CloseIcon,
} from '@material-ui/icons';
// import { grey } from '@material-ui/core/colors';

import PropTypes from 'prop-types';

import { FormProgress } from 'src/components/form';
import { ActionDialogTitle } from 'src/components/core';

const useStyles = makeStyles(() => ({
  dialog: {
    height: '600px',
    maxHeight: '90%'
  },

  popoverContent: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
}));

const PickerDialog = ({
  title,
  loading = false,
  children,
  maxWidth = 'sm',
  open = false,
  onClose
}) => {
  const classes = useStyles();

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{ paper: classes.dialog }}
      maxWidth={maxWidth}
      fullWidth
    >
      {loading && <FormProgress />}
      <Box className={classes.popoverContent}>
        <ActionDialogTitle
          title={title}
          actions={() => (
            <>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </>
          )}
        />
        <Divider />
        {children}
        {/* <DialogActions>
          <Button onClick={handleClose} color="primary">
            Ok
          </Button>
        </DialogActions> */}
      </Box>
    </Dialog>
  );
};

PickerDialog.propTypes = {
  title: PropTypes.string,
  loading: PropTypes.bool,
  children: PropTypes.node.isRequired,
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

export default PickerDialog;
