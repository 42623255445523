import React, { useRef } from 'react';
import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useStateRef } from 'src/utils/react';

const DebouncedSearchField = ({ initialValue = '', debounceInterval = 300, onChangeDebounced, ...rest }) => {
  const [search, setSearch, searchRef] = useStateRef(initialValue);

  // const searchTouched = useRef(false);
  const debounceTimerRef = useRef();

  const debounce = () => {
    if (debounceTimerRef.current) {
      clearTimeout(debounceTimerRef.current);
    }
    debounceTimerRef.current = setTimeout(() => onChangeDebounced(searchRef.current), debounceInterval);
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
    debounce();
  };

  return (
    <TextField
      type="Text"
      label="Search"
      variant="outlined"
      size="small"
      fullWidth
      value={search}
      // onFocus={() => { searchTouched.current = true; }}
      onChange={handleSearchChange}
      {...rest}
    />
  );
};

DebouncedSearchField.propTypes = {
  initialValue: PropTypes.string,
  debounceInterval: PropTypes.number,
  onChangeDebounced: PropTypes.func
};

export default DebouncedSearchField;
