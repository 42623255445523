/* eslint-disable max-classes-per-file */
/* eslint-disable no-unused-vars */
/* eslint-disable class-methods-use-this */
import React, { useContext, useState, useRef, useEffect } from 'react';

import PropTypes from 'prop-types';
import axios from 'axios';
import { get, isEqual, isString, defaultTo, first, keyBy, includes } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import qs from 'qs';
import remote from 'loglevel-plugin-remote';
import { DateTime } from 'luxon';
import CryptoJS from 'crypto-js';


import { parseJwtToken } from 'src/utils/core';
import { useNavigate, useLocation } from 'src/components/Router';
import { tryParse } from 'src/utils/json';
import log from 'src/support/Logger';

const USER_DATA = 'SYSTEM_USER';

const ATTRIBUTE_DATA = 'SYSTEM_ATTRIBUTES';

const SYSTEM_CUSTOMER = 'SYSTEM_CUSTOMER';

const CUSTOMER_ID = 'SYSTEM_CUSTOMER_ID';

const SESSION_ID = 'SYSTEM_SESSION_ID';

const TOKEN = 'SYSTEM_TOKEN';

const REFRESH_TOKEN = 'SYSTEM_REFRESH_TOKEN';

const PROXY = 'SYSTEM_PROXY';

const AUTH_KEY = 'SYSTEM_AUTH_KEY';

const UNPROXY = 'SYSTEM_UNPROXY';

const UNPROXY_TOKEN = 'SYSTEM_UNPROXY_TOKEN';

const UNPROXY_TO = 'SYSTEM_UNPROXY_TO';

const AUTHENTICATE_URL = '/token/authenticate';

const REFRESH_TOKEN_URL = '/token/refresh';

const HEARTBEAT_INTERVAL = 2 * 60 * 1000;

const OFFSET_THRESHOLD = 60 + 1000;

const OFFSET_MILLIS = Symbol.for('SYSTEM_OFFSET_MILLIS');

const AUTH_SECRET = "vyL7-12$6jEka_cd"

/*
 * By default axios sends array parameters as "param[]=value1&param[]=value2".
 * Spring binding only accepts "param=value1&param=value2" or
 * "param[0]=value1&param[1]=value2". Override the params serializer to remove
 * the brackets.
 */
axios.defaults.paramsSerializer = (params) => qs.stringify(params, { indices: false });

class SystemService {
  constructor(
    apiUrl,
    version,
    user,
    setUser,
    attributes,
    setAttributes,
    dataRef,
    navigate,
    onVersionMissmatch,
    plugins = []
  ) {
    this._user = user;
    this._setUserCallback = setUser;
    this._attributes = attributes;
    this._setAttributesCallback = setAttributes;
    this._dataRef = dataRef;
    this._navigate = navigate;
    this._plugins = plugins;
    this._pluginMap = keyBy(plugins, (plugin) => plugin.id);
    this._version = version;
    this._onVersionMissmatch = onVersionMissmatch;

    this._noInterceptorAxios = axios.create({
      baseURL: apiUrl
    });

    this._apiAxios = axios.create({
      baseURL: apiUrl
    });

    this._apiAxios.interceptors.request.use((config) => {
      const token = sessionStorage.getItem(TOKEN);
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    });

    this._apiAxios.interceptors.response.use(
      (response) => response,
      (error) => {
        // Only want to trap "Unauthorized"
        if (get(error, 'response.status', null) !== 401) {
          return Promise.reject(error);
        }

        // Only trap if we have a refresh token
        const refreshToken = sessionStorage.getItem(REFRESH_TOKEN);
        if (!refreshToken) {
          return Promise.reject(error);
        }

        return this._refreshToken(refreshToken)
          .then((data) => {
            // Retry original request with new token;
            const { config } = error;
            config.headers.Authorization = `Bearer ${data.token}`;
            return this._noInterceptorAxios.request(config);
          })
          .catch((refreshError) => {
            this.logout();
            return Promise.reject(refreshError);
          });
      }
    );
  }

  axios() {
    return this._apiAxios;
  }

  login(username, password, authkey) {
    this._reset();

    return this._noInterceptorAxios
      .post(AUTHENTICATE_URL, { username, password, authkey })
      .then((response) => {
        const { data } = response;
        return this._performLogin(data);
      });
  }

  tokenLogin(refreshToken) {
    return this._noInterceptorAxios
      .post(REFRESH_TOKEN_URL, { token: refreshToken })
      .then((response) => {
        const { data } = response;
        return this._performLogin(data);
      }).then(() => {
        if (this.isProxy()) {
          // If login is proxy pull the unproxy token into session.
          sessionStorage.setItem(UNPROXY_TOKEN, localStorage.getItem(UNPROXY_TOKEN));
        }
      });
  }

  changeCustomer(customerId) {
    if (customerId === this.getCustomerId()) {
      return Promise.resolve();
    }

    return this._noInterceptorAxios
      .post(`${REFRESH_TOKEN_URL}/${customerId}`, { token: sessionStorage.getItem(REFRESH_TOKEN) })
      .then((response) => {
        const { data } = response;
        return this._performLogin(data);
      })
      .then(() => {
        this._navigate('/', { replace: true });
      });
  }

  reset() {
    this._reset(true);
  }

  logout() {
    this._reset(true);
    this._navigate('/', { replace: true });
  }

  restore(data) {
    sessionStorage.setItem(SESSION_ID, data.sessionId);
    this._setTokens(data.token, data.refreshToken);
    this._setAttributes(data.attributes);
    this._setUser(data.user);
  }

  plugin(id) {
    const plugin = this._pluginMap[id];

    if (plugin == null) {
      throw new Error(`Could not find plugin id ${id}`);
    }

    if (plugin.build == null) {
      throw new Error(`Plugin id ${id} has no build method`);
    }

    const service = plugin.build({ system: this });

    if (service == null) {
      throw new Error(`Plugin id ${id} build returned null`);
    }

    return service;
  }

  proxy(userId, unproxyTo) {
    if (this.proxyInProgress) {
      return Promise.resolve({ success: false, message: 'Proxy request already in progress' });
    }
    this.proxyInProgress = true;

    this.setSessionItem(UNPROXY_TO, unproxyTo);

    return Promise.resolve(this._proxy(userId))
      .then((message) => {
        if (message) {
          return { success: false, message };
        }
        this._navigate('/', { replace: true });
        return { success: true };
      })
      .finally(() => {
        this.proxyInProgress = false;
      });
  }

  unproxy() {
    if (!this.isProxy()) {
      return Promise.resolve();
    }

    return Promise.resolve(this._unproxy())
      .finally(() => {
        const unproxyTo = this.getSessionItem(UNPROXY_TO);
        if (unproxyTo) {
          this._navigate(unproxyTo, { replace: true });
        } else {
          this._navigate('/', { replace: true });
        }
      });
  }

  isUserLoggedIn() {
    return this._user != null;
  }

  isProxy() {
    return Boolean(sessionStorage.getItem(PROXY));
  }

  isAuthKey() {
    return this.getAttribute(AUTH_KEY) === "true"; 
  }

  hasAuthority(authority) {
    return authority
      && this._user
      && includes(this._user.roles, `ROLE_${authority.toUpperCase()}`);
  }

  hasFeature(feature) {
    const customer = this.getCustomer();
    return feature
      && customer
      && includes(customer.features, feature.toUpperCase());
  }

  hasCustomerAccess(customerAccess) {
    const customer = this.getCustomer();
    return customerAccess
      && customer
      && includes(customer.access, customerAccess.toUpperCase());
  }

  getCustomerId() {
    const customer = this.getCustomer();
    return customer == null ? null : customer.id;
  }

  getCustomer() {
    if (!this._user) {
      return null;
    }

    let customerMap = this.getDataItem(SYSTEM_CUSTOMER);
    if (!customerMap) {
      customerMap = keyBy(this._user.customers || [], (e) => e.id);
      this.setDataItem(SYSTEM_CUSTOMER, customerMap);
    }
    // return customerMap[sessionStorage.getItem(CUSTOMER_ID)];
    return customerMap[this.getAttribute(CUSTOMER_ID)];
  }

  getCustomers() {
    return get(this, '_user.customers') || [];
  }

  refreshUser() {
    return this.refreshToken();
  }

  getUser() {
    return this._user;
  }

  getUserId() {
    return this._user == null ? null : this._user.id;
  }

  /**
   * Returns a unique key for each login session
   */
  getSessionId() {
    return sessionStorage.getItem(SESSION_ID);
  }

  setDataItem(key, item) {
    if (key == null) {
      return;
    }

    if (item == null) {
      this.removeDataItem(key);
      return;
    }

    this._dataRef.current[key] = item;
  }

  getDataItem(key, defaultValue = null) {
    if (key == null) {
      return defaultValue;
    }

    const item = this._dataRef.current[key];

    return item != null ? item : defaultValue;
  }

  removeDataItem(key) {
    delete this._dataRef.current[key];
  }

  /**
   * Sets an item in session storage for the current login session.
   */
  setSessionItem(key, item) {
    if (key == null) {
      return;
    }

    if (item == null) {
      this.removeSessionItem(key);
      return;
    }

    let value;
    if (typeof item === 'boolean') {
      value = `boolean:${item}`;
    } else if (typeof item === 'number') {
      value = `number:${item}`;
    } else if (typeof item === 'object' && !(isString(item))) {
      value = `object:${JSON.stringify(item)}`;
    } else {
      value = `string:${item}`;
    }

    sessionStorage.setItem(this._getQualifiedSessionKey(key), value);
  }

  /**
   * Gets an item in session storage for the current login session.
   */
  getSessionItem(key, defaultValue = null) {
    if (key == null) {
      return defaultValue;
    }

    const item = sessionStorage.getItem(this._getQualifiedSessionKey(key));
    if (isString(item)) {
      const index = item.indexOf(':');
      if (index !== -1) {
        const type = item.substring(0, index);
        const stringVal = item.substring(index + 1);

        if (type === 'boolean') {
          return (stringVal === 'true');
        }

        if (type === 'number') {
          return Number(stringVal);
        }

        if (type === 'object') {
          return tryParse(item.substring(7));
        }

        if (type === 'string') {
          return item.substring(7);
        }
      }
    }
    return item != null ? item : defaultValue;
  }

  /**
   * Removes an item in session storage for the current login session.
   */
  removeSessionItem(key) {
    if (key == null) {
      return;
    }

    sessionStorage.removeItem(this._getQualifiedSessionKey(key));
  }

  setUser(user) {
    if (this.isUserLoggedIn() && user) {
      this._setUser(user);
    }
  }

  setAgreedToTermsDate(agreedToTermsDate) {
    if (this._user != null) {
      this._setUser({ ...this._user, agreedToTermsDate });
    }
  }

  getAttribute(key) {
    return get(this._attributes, key);
  }

  setAttribute(key, value) {
    const newAttributes = { ...this._attributes };
    newAttributes[key] = value;
    this._setAttributes(newAttributes);
  }

  setAttributes(attributes) {
    this._setAttributes({ ...this._attributes, ...attributes });
  }

  removeAttribute(key) {
    const newAttributes = { ...this._attributes };
    delete newAttributes[key];
    this._setAttributes(newAttributes);
  }

  refreshToken() {
    const refreshToken = sessionStorage.getItem(REFRESH_TOKEN);
    if (!refreshToken) {
      return Promise.reject(new Error('Cannot refresh token. No refresh token found'));
    }

    return this._refreshToken(refreshToken);
  }

  getOffsetMillis() {
    return defaultTo(this.getDataItem(OFFSET_MILLIS), 0);
  }

  now() {
    return DateTime.now().plus(this.getOffsetMillis());
  }

  _syncLogToken() {
    const token = sessionStorage.getItem(TOKEN);
    if (token) {
      remote.setToken(token);
    } else {
      remote.setToken('');
    }
  }

  _getQualifiedSessionKey(key) {
    return `${this.getSessionId()}-${key}`;
  }

  _setUser(user) {
    if (!isEqual(this._user, user)) {
      this.removeDataItem(SYSTEM_CUSTOMER);
      sessionStorage.setItem(USER_DATA, JSON.stringify(user));
      this._setUserCallback(user);
    }
  }

  _setAttributes(attributes) {
    if (!isEqual(this._attributes, attributes)) {
      sessionStorage.setItem(ATTRIBUTE_DATA, JSON.stringify(attributes));
      this._setAttributesCallback(attributes);
    }
  }

  _setTokens(token, refreshToken) {
    sessionStorage.setItem(TOKEN, token);
    sessionStorage.setItem(REFRESH_TOKEN, refreshToken);

    const parsedToken = parseJwtToken(token);
    const proxy = get(parsedToken, 'payload.proxy') != null;
    if (proxy) {
      sessionStorage.setItem(PROXY, proxy);
    } else {
      sessionStorage.removeItem(PROXY);
    }
    
    localStorage.setItem(REFRESH_TOKEN, refreshToken);

    this._syncLogToken();
  }

  _reset(clearLocal = false) {
    this._setUser(null);
    this._setAttributes(null);
    this._dataRef.current = {};

    sessionStorage.clear();
    remote.setToken('');

    if (clearLocal) {
      localStorage.clear();
    }
  }

  _refreshToken(refreshToken) {
    return this._noInterceptorAxios
      .post(REFRESH_TOKEN_URL, { token: refreshToken })
      .then((response) => {
        const { data } = response;

        this._setTokens(data.token, data.refreshToken);
        this._setUser(data.user);
        this._setOffsetMillis(data.currentTimeMillis);

        return data;
      });
  }

  _setOffsetMillis(serverMillis) {
    if (serverMillis) {
      let offset = serverMillis - Date.now();
      if (Math.abs(offset) < OFFSET_THRESHOLD) {
        offset = 0;
      }
      this.setDataItem(OFFSET_MILLIS, offset);
    }
  }

  _performLogin(data) {
    // Set tokens so login handlers can make authentiated axios calls.
    this._setTokens(data.token, data.refreshToken);
    this._setOffsetMillis(data.currentTimeMillis);
    sessionStorage.setItem(SESSION_ID, uuidv4());

    const parsedToken = parseJwtToken(data.refreshToken);
    const attributes = {};
    attributes[CUSTOMER_ID] = get(parsedToken, 'payload.ctx.customerId');
    attributes[AUTH_KEY] = get(parsedToken, 'payload.authkey');

    let loginPromise = Promise.resolve();

    this._plugins.forEach((plugin) => {
      if (plugin.onLogin) {
        loginPromise = loginPromise.then(() => plugin.onLogin({
          system: this,
          user: data.user,
          setAttribute: (key, value) => { attributes[key] = value; }
        }));
      }
    });

    return loginPromise
      .then(() => {
        this._dataRef.current = {};
        this._setAttributes(attributes);
        this._setUser(data.user);
        return data;
      })
      .catch((error) => {
        log.error('Login error', error);
        // If any login handlers fail remove the tokens we set above.
        sessionStorage.removeItem(TOKEN);
        sessionStorage.removeItem(REFRESH_TOKEN);
        sessionStorage.removeItem(SESSION_ID);
      });
  }

  _cleanSession(sessionId) {
    Object.keys(sessionStorage)
      .filter((key) => key.startsWith(sessionId))
      .forEach((key) => { sessionStorage.removeItem(key); });
  }

  _proxy(userId) {
    if (!this.isUserLoggedIn()) {
      return 'Can\'t proxy. User not logged in';
    }

    if (this.isProxy()) {
      return 'Already in proxy mode';
    }

    if (userId == null) {
      return 'No user specified';
    }

    return this._apiAxios
      .post(`/proxy/${userId}`)
      .then((response) => {
        const { data } = response;
        if (data.errors) {
          return defaultTo(first(data.errors.globalErrors), 'Unknown error');
        }

        const unproxy = {
          user: this._user,
          attributes: this._attributes,
          token: sessionStorage.getItem(TOKEN),
          refreshToken: sessionStorage.getItem(REFRESH_TOKEN),
          sessionId: sessionStorage.getItem(SESSION_ID),
        };

        sessionStorage.setItem(UNPROXY, JSON.stringify(unproxy));
        localStorage.setItem(UNPROXY_TOKEN, sessionStorage.getItem(REFRESH_TOKEN));

        return this._performLogin(data.data)
          .then(() => null)
          .catch((error) => {
            log.error('Proxy error', error);
            this.logout();
          });
      });
  }

  _unproxy() {
    const unproxy = tryParse(sessionStorage.getItem(UNPROXY));

    if (unproxy) {
      // Unproxy from session info
      const sessionId = this.getSessionId();
      this.restore(unproxy);
      this._cleanSession(sessionId);
      return null;
    }

    const unproxyToken = sessionStorage.getItem(UNPROXY_TOKEN);
    if (unproxyToken) {
      return this.tokenLogin(unproxyToken)
        .catch((error) => {
          this._reset(true);
          return error;
        });
    }

    // logout
    this._reset(true);
    return null;
  }

  _heartbeat() {
    if (this.isUserLoggedIn()) {
      // Requesting a occasional heartbeat will keep the tokens
      // up-to-date and logout the user if they have expired.
      this._apiAxios.get('/heartbeat')
        .then(({ data }) => {
          if (data.version && data.version !== this._version) {
            if (this._onVersionMissmatch) {
              this._onVersionMissmatch(this._version, data.version);
            }
          }
        })
        .catch((error) => {
          // log.error('Heartbeat error', error);
        });
    }
  }
}

const SystemContext = React.createContext({});

const SystemProvider = ({ apiUrl, version, children, loadingComponent = null, onVersionMissmatch, plugins = [] }) => {
  const [initialized, setInitialized] = useState(false);

  const [user, setUser] = useState(null);
  const [attributes, setAttributes] = useState(null);
  const dataRef = useRef({});
  const systemRef = useRef();

  const navigate = useNavigate();
  const location = useLocation();
  const authKey = new URLSearchParams(location.search).get('authkey');
    
  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const systemService = new SystemService(
    apiUrl,
    version,
    user,
    setUser,
    attributes,
    setAttributes,
    dataRef,
    navigate,
    onVersionMissmatch,
    plugins
  );

  systemRef.current = systemService;

  const initialize = () => {
    if (authKey) {
      // Don't try to init from session if using auth key.
      return null;
    }
    /*
     * Attempt to restore user info from session so the app
     * can survive a browser refresh.
     */
    const sessionId = sessionStorage.getItem(SESSION_ID);
    const refreshToken = sessionStorage.getItem(REFRESH_TOKEN);
    const token = sessionStorage.getItem(TOKEN);
    const userData = tryParse(sessionStorage.getItem(USER_DATA));
    const attributeData = tryParse(sessionStorage.getItem(ATTRIBUTE_DATA));

    if (sessionId && refreshToken && token && userData && attributeData) {
      // Restore from session
      systemService.restore({
        sessionId,
        refreshToken,
        token,
        user: userData,
        attributes: attributeData,
      });

      return null;
    }

    const localRefreshToken = localStorage.getItem(REFRESH_TOKEN);
    if (localRefreshToken) {
      // New tab or window.
      return systemService
        .tokenLogin(localRefreshToken)
        .catch((error) => {
          log.error('Token login error', error);
          systemService.logout();
        });
    }

    return null;
  };

  useEffect(() => {
    Promise.resolve(initialize())
      .finally(() => {
        setInitialized(true);
      });

    const heartbeatInterval = setInterval(() => {
      /*
       * Must use a ref or the first systemService will be captured
       * and will never change when user logs in/out.
       */
      if (systemRef.current) {
        systemRef.current._heartbeat();
      }
    }, HEARTBEAT_INTERVAL);

    return () => {
      clearInterval(heartbeatInterval);
    };
  }, []);

  useEffect(() => {
    if (initialized && authKey) {
      // AES ECM 128 Bit
      const key = CryptoJS.enc.Utf8.parse(AUTH_SECRET);
      const decrypted = CryptoJS.AES.decrypt(authKey, key, {
        mode: CryptoJS.mode.ECB
      });
      const authKeyText = decrypted.toString(CryptoJS.enc.Utf8);
      const splitIndex = authKeyText.indexOf("::");
      if (splitIndex > 0) {
        const authUser = authKeyText.substring(0, splitIndex);
        const authPassword = authKeyText.substring(splitIndex + 2);
        
        if (authUser !== get(systemService.getUser(), 'username') || !systemService.isAuthKey()) {
            systemService.login(authUser, null, authPassword)
              .catch((error) => {
                // log.error('Token login error', error);
                systemService.logout();
              });
        }
      }
    }
  }, [initialized, authKey])

  if (!initialized || (authKey && (!systemService.isUserLoggedIn() || !systemService.isAuthKey()))) {
    return loadingComponent == null ? null : React.createElement(loadingComponent);
  }

  return (
    <SystemContext.Provider value={systemService}>
      {children}
    </SystemContext.Provider>
  );
};

SystemProvider.propTypes = {
  apiUrl: PropTypes.string.isRequired,
  version: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  loadingComponent: PropTypes.elementType,
  onVersionMissmatch: PropTypes.func,
  plugins: PropTypes.array
};

const useSystemContext = () => {
  return useContext(SystemContext);
};

export { SystemContext, SystemProvider, useSystemContext, AUTH_SECRET };
