/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';

import PropTypes from 'prop-types';

import { useSystemContext } from 'src/service/System';
import { TermsAndConditionsDialog } from 'src/components/TermsAndConditions';
import UserNavBar from './view/UserNavBar';
import AdminNavBar from './view/AdminNavBar';
import TopBar from './view/TopBar';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1)
    }
  }
}));

const DrawerLayout = ({ admin = false, children }) => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const [isDesktopNavOpen, setDesktopNavOpen] = useState(true);

  const system = useSystemContext();
  const user = system.getUser();
  const agreedToTerms = user == null || system.isProxy() ? true : user.agreedToTermsDate != null;
  const [showTerms, setShowTerms] = useState(!agreedToTerms);

  useEffect(() => {
    setShowTerms(!agreedToTerms);
  }, [agreedToTerms]);

  const wrapperStyle = isDesktopNavOpen ? {} : { paddingLeft: 0 };

  const handleDesktopNavToggle = () => {
    setDesktopNavOpen(!isDesktopNavOpen);
    window.dispatchEvent(new Event('resize'));
  };

  const handleConfirmAgreedToTerms = (confirm) => {
    if (!confirm) {
      system.logout();
      return;
    }

    setShowTerms(false);
  };

  return (
    <div className={classes.root}>
      <TermsAndConditionsDialog open={showTerms} onConfirm={handleConfirmAgreedToTerms} />
      <TopBar
        onMobileNavOpen={() => setMobileNavOpen(true)}
        onDesktopNavToggle={handleDesktopNavToggle}
        isDesktopNavOpen={isDesktopNavOpen}
        admin={admin}
      />
      {admin ? (
        <AdminNavBar
          onMobileClose={() => setMobileNavOpen(false)}
          openMobile={isMobileNavOpen}
          openDesktop={isDesktopNavOpen}
        />
      ) : (
        <UserNavBar
          onMobileClose={() => setMobileNavOpen(false)}
          openMobile={isMobileNavOpen}
          openDesktop={isDesktopNavOpen}
        />
      )}
      <div className={classes.wrapper} style={wrapperStyle}>
        {/* <div className={classes.contentContainer}> */}
        <div className={classes.content}>
          {children}
        </div>
        {/* </div> */}
      </div>
    </div>
  );
};

DrawerLayout.propTypes = {
  admin: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default DrawerLayout;
