/* eslint-disable max-len */
import React from 'react';
import { SvgIcon } from '@material-ui/core';

const Icon = (props) => {
  return (
    <SvgIcon {...props}>
      <g id="Electricity">
        <g transform="matrix(1.5463,0,0,1.5463,-4.4213,-6.10648)">
          <path d="M13,5L6.5,13L9.5,13L8,18.5L14.5,11.125L11.5,11L13,5Z" />
        </g>
        <g transform="matrix(1.04068,0,0,1.04068,-1.27735,-0.930444)">
          <path d="M11.921,2.852C6.957,3.287 3.149,7.443 3.149,12.425C3.149,15.501 4.622,18.391 7.11,20.199L8.301,18.56C6.337,17.133 5.175,14.852 5.175,12.425C5.175,8.493 8.181,5.213 12.097,4.871L11.921,2.852Z" />
        </g>
        <g transform="matrix(1.04068,0,0,1.04068,-1.27735,-0.930444)">
          <path d="M13.429,22.011C18.463,21.659 22.367,17.472 22.367,12.425C22.367,9.467 21.005,6.674 18.674,4.853L17.427,6.449C19.266,7.887 20.341,10.091 20.341,12.425C20.341,16.408 17.26,19.712 13.287,19.99L13.429,22.011Z" />
        </g>
      </g>
    </SvgIcon>
  );
};

export default Icon;
