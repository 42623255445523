/* eslint-disable react/jsx-tag-spacing */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-indent */
/* eslint-disable max-len */
import React from 'react';
import { SvgIcon } from '@material-ui/core';
import PropTypes from 'prop-types';

const Icon = ({ style, ...rest }) => {
  return (
    <SvgIcon {...rest} style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinejoin: 'round', strokeMiterlimit: '2', ...style }}>
      <g id="Layer7">
        <path d="M14.025,2L19.045,2C20.045,2 20.864,2.818 20.864,3.818L20.864,20.182C20.864,21.182 20.045,22 19.045,22L9.955,22C8.955,22 8.136,21.182 8.136,20.182L8.136,17.575C8.42,17.603 8.708,17.618 9,17.618C9.322,17.618 9.641,17.6 9.955,17.565L9.955,18.364L19.045,18.364L19.045,5.636L16.935,5.636C16.314,4.173 15.299,2.917 14.025,2Z" style={{ fillRule: 'nonzero' }} />
        <g transform="matrix(0.41777,0,0,0.41777,-2.6604,0.607433)">
          <path d="M26.333,25L29.667,25L29.667,28.333L26.333,28.333L26.333,25ZM26.333,11.667L29.667,11.667L29.667,21.667L26.333,21.667L26.333,11.667ZM27.983,3.333C18.783,3.333 11.333,10.8 11.333,20C11.333,29.2 18.783,36.667 27.983,36.667C37.2,36.667 44.667,29.2 44.667,20C44.667,10.8 37.2,3.333 27.983,3.333ZM28,33.333C20.633,33.333 14.667,27.367 14.667,20C14.667,12.633 20.633,6.667 28,6.667C35.367,6.667 41.333,12.633 41.333,20C41.333,27.367 35.367,33.333 28,33.333Z" style={{ fillRule: 'nonzero' }} />
        </g>
      </g>
    </SvgIcon>
  );
};

Icon.propTypes = {
  style: PropTypes.object
};

export default Icon;
