/* eslint-disable max-len */
import React from 'react';
import { SvgIcon } from '@material-ui/core';

const Icon = (props) => {
  return (
    <SvgIcon {...props}>
      <g transform="matrix(1,0,0,1,2,-2.5)">
        <g id="Layer2">
          <path d="M2,6.5L18,6.5L12,12.5L12,18.5L8,22.5L8,12.5L2,6.5Z" style={{ fill: 'none', stroke: 'currentColor', strokeWidth: '2px' }} />
        </g>
      </g>
    </SvgIcon>
  );
};

export default Icon;
