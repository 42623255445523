/* eslint-disable no-unused-vars */
import React from 'react';

import {
  Box,
  Chip,
  makeStyles,
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

import PropTypes from 'prop-types';
import clsx from 'clsx';
import { get, concat, defaultTo } from 'lodash';

const useStyles = makeStyles((theme) => ({
  filterContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    marginTop: -theme.spacing(1),
  },

  filterItem: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1)
  },

  filterLabel: {
    fontWeight: 500,
    fontStyle: 'italic',
    color: grey[500]
  }
}));

const FilterChips = ({ chips, classes, onClick, onDelete, ...rest }) => {
  const baseClasses = useStyles();

  if (!chips || chips.length === 0) {
    return null;
  }

  const handleClick = (chip) => {
    if (onClick) {
      onClick(chip);
    }
  };

  const handleDelete = (chip) => {
    if (onDelete) {
      const clearProperties = chip.clearProperties ? concat([], chip.clearProperties) : [chip.id];
      onDelete(chip, clearProperties);
    }
  };

  return (
    <Box className={clsx(baseClasses.filterContainer, get(classes, 'root'))}>
      {chips.map((chip) => (
        <Box className={clsx(baseClasses.filterItem, get(classes, 'item'))} key={chip.id}>
          <Chip
            size="small"
            variant="outlined"
            color="primary"
            label={chip.label}
            onClick={() => handleClick(chip)}
            onDelete={
              onDelete && defaultTo(chip.deletable, true)
                ? () => handleDelete(chip)
                : null
            }
          />
        </Box>
      ))}
    </Box>
  );
};

FilterChips.propTypes = {
  chips: PropTypes.array,
  classes: PropTypes.object,
  onClick: PropTypes.func,
  onDelete: PropTypes.func,
};

export default FilterChips;
