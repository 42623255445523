/* eslint-disable react/jsx-tag-spacing */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-indent */
/* eslint-disable max-len */
import React from 'react';
import { SvgIcon } from '@material-ui/core';
import PropTypes from 'prop-types';

const Icon = ({ style, ...rest }) => {
  return (
    <SvgIcon {...rest} style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinejoin: 'round', strokeMiterlimit: '2', ...style }}>
      <g id="Layer5" transform="matrix(1,0,0,1,0,1)">
        <path d="M20,5C20,4.448 19.552,4 19,4L5,4C4.448,4 4,4.448 4,5C4,5.552 4.448,6 5,6L19,6C19.552,6 20,5.552 20,5Z" />
        <g transform="matrix(1,0,0,1,1.5,-9.5)">
          <path d="M5.5,15.5C5.5,18.261 7.739,20.5 10.5,20.5C13.261,20.5 15.5,18.261 15.5,15.5L13.697,15.5C13.697,17.266 12.266,18.697 10.5,18.697C8.734,18.697 7.303,17.266 7.303,15.5L5.5,15.5Z" />
        </g>
        <g transform="matrix(0.864695,0,0,0.833333,1.94132,2.16667)">
          <path d="M12.5,13.45C12.5,13.202 12.306,13 12.066,13L11.199,13C10.96,13 10.765,13.202 10.765,13.45L10.765,18.55C10.765,18.798 10.96,19 11.199,19L12.066,19C12.306,19 12.5,18.798 12.5,18.55L12.5,13.45Z" />
        </g>
        <g transform="matrix(0.748848,-0.432347,0.416667,0.721688,1.62226,7.6825)">
          <path d="M12.5,13.45C12.5,13.202 12.306,13 12.066,13L11.199,13C10.96,13 10.765,13.202 10.765,13.45L10.765,18.55C10.765,18.798 10.96,19 11.199,19L12.066,19C12.306,19 12.5,18.798 12.5,18.55L12.5,13.45Z" />
        </g>
        <g transform="matrix(0.432347,-0.748848,0.721688,0.416667,3.52084,12.9843)">
          <path d="M12.5,13.45C12.5,13.202 12.306,13 12.066,13L11.199,13C10.96,13 10.765,13.202 10.765,13.45L10.765,18.55C10.765,18.798 10.96,19 11.199,19L12.066,19C12.306,19 12.5,18.798 12.5,18.55L12.5,13.45Z" />
        </g>
        <g transform="matrix(0.748848,0.432347,-0.416667,0.721688,4.95559,-2.37618)">
          <path d="M12.5,13.45C12.5,13.202 12.306,13 12.066,13L11.199,13C10.96,13 10.765,13.202 10.765,13.45L10.765,18.55C10.765,18.798 10.96,19 11.199,19L12.066,19C12.306,19 12.5,18.798 12.5,18.55L12.5,13.45Z" />
        </g>
        <g transform="matrix(0.432347,0.748848,-0.721688,0.416667,10.5177,-4.43789)">
          <path d="M12.5,13.45C12.5,13.202 12.306,13 12.066,13L11.199,13C10.96,13 10.765,13.202 10.765,13.45L10.765,18.55C10.765,18.798 10.96,19 11.199,19L12.066,19C12.306,19 12.5,18.798 12.5,18.55L12.5,13.45Z" />
        </g>
      </g>
    </SvgIcon>
  );
};

Icon.propTypes = {
  style: PropTypes.object
};

export default Icon;
