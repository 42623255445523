import React, { useState, useRef } from 'react';

import {
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
  makeStyles,
} from '@material-ui/core';

import {
  MoreVert as MoreIcon,
} from '@material-ui/icons';

import PropTypes from 'prop-types';
import { slice } from 'lodash';

const useStyles = makeStyles(() => ({
  actionListItemIcon: {
    minWidth: 40
  }
}));

const ActionMenu = ({
  actions,
  maxItems = 5
}) => {
  const [showActions, setShowActions] = useState(false);
  const actionsButtonRef = useRef();

  const classes = useStyles();

  actions = actions || [];
  let mainActions = actions;
  let moreActions = [];
  if (actions.length > maxItems) {
    mainActions = slice(actions, 0, maxItems - 1);
    moreActions = slice(actions, maxItems - 1);
  }

  return (
    <>
      {mainActions.map((action) => (
        <Tooltip key={action.key} title={action.name}>
          <IconButton onClick={action.onClick}>
            <action.icon />
          </IconButton>
        </Tooltip>
      ))}
      {moreActions.length > 0 && (
        <>
          <Tooltip title="More">
            <IconButton
              aria-label="more"
              aria-controls="more-menu"
              aria-haspopup="true"
              ref={actionsButtonRef}
              onClick={() => setShowActions(true)}
            >
              <MoreIcon />
            </IconButton>
          </Tooltip>
          <Menu
            id="more-menu"
            anchorEl={() => actionsButtonRef.current}
            keepMounted
            open={showActions}
            onClose={() => setShowActions(false)}
          >
            {moreActions.map((action) => (
              <MenuItem key={action.key} onClick={action.onClick}>
                <ListItemIcon classes={{ root: classes.actionListItemIcon }}>
                  <action.icon />
                </ListItemIcon>
                {action.name}
              </MenuItem>
            ))}
          </Menu>
        </>
      )}
    </>
  );
};

ActionMenu.propTypes = {
  actions: PropTypes.array,
  maxItems: PropTypes.number
};

export default ActionMenu;
