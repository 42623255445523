import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import log from 'src/support/Logger';
import { useSystemContext } from 'src/service/System';
import { useIsMounted } from 'src/utils/react';
// import { delayPromise } from 'src/utils/core';

const AuthImage = ({ src, alt = '', loadingAlt = '', loadingComponent, fallbackComponent, ...rest }) => {
  const [state, setState] = useState({ src: null, alt: loadingAlt });
  const [loadError, setLoadError] = useState(false);
  const system = useSystemContext();
  const isMounted = useIsMounted();

  const revokeObjectURL = () => {
    if (state.src) {
      URL.revokeObjectURL(state.src);
    }
  };

  useEffect(() => {
    // Cleanup object url on unmount;
    return revokeObjectURL;
  }, []);

  useEffect(() => {
    if (!system.isUserLoggedIn()) {
      return;
    }

    setState({ src: null, alt: loadingAlt });
    revokeObjectURL();

    system.axios()
      .get(src, {
        responseType: 'blob'
      })
      // .then((response) => {
      //   return delayPromise(2000, response);
      // })
      .then((response) => {
        if (isMounted()) {
          revokeObjectURL();
          setState({ src: URL.createObjectURL(response.data), alt });
        }
      })
      .catch((error) => {
        if (isMounted()) {
          setLoadError(true);
          log.error('Error loading image', src, error);
        }
      });
  }, [src]);

  if (!state.src) {
    if (loadError && fallbackComponent) {
      return (
        React.isValidElement(fallbackComponent)
          ? fallbackComponent
          : React.createElement(fallbackComponent)
      );
    }

    if (loadingComponent) {
      return (
        React.isValidElement(loadingComponent)
          ? loadingComponent
          : React.createElement(loadingComponent)
      );
    }
  }

  return (
    <img src={state.src} alt={state.alt} {...rest} />
  );
};

AuthImage.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  loadingAlt: PropTypes.string,
  loadingComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.elementType]),
  fallbackComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.elementType]),
};

export default AuthImage;
