/* eslint-disable max-len */
import React from 'react';
import { SvgIcon } from '@material-ui/core';

const Icon = (props) => {
  return (
    <SvgIcon {...props}>
      <g id="Grow-Historical">
        <g transform="matrix(0.857143,0,0,0.857143,3.71429,4.71429)">
          <path d="M5,15.5L7.917,15.5L8,19L5,19L5,15.5ZM9.667,9.083L12.583,9.083L12.583,19L9.667,19L9.667,9.083ZM14.333,4.417L17.25,4.417L17.25,19L14.333,19L14.333,4.417Z" style={{ fillRule: 'nonzero' }} />
        </g>
        <g transform="matrix(0.761905,0,0,0.761905,0.972462,1.2381)">
          <path d="M5.6,10.25C5.6,11.63 6.72,12.75 8.1,12.75C8.63,12.75 9.11,12.59 9.52,12.31L9.5,12.5C9.5,13.88 10.62,15 12,15C13.38,15 14.5,13.88 14.5,12.5L14.48,12.31C14.88,12.59 15.37,12.75 15.9,12.75C17.28,12.75 18.4,11.63 18.4,10.25C18.4,9.25 17.81,8.4 16.97,8C17.81,7.6 18.4,6.75 18.4,5.75C18.4,4.37 17.28,3.25 15.9,3.25C15.37,3.25 14.89,3.41 14.48,3.69L14.5,3.5C14.5,2.12 13.38,1 12,1C10.62,1 9.5,2.12 9.5,3.5L9.52,3.69C9.12,3.41 8.63,3.25 8.1,3.25C6.72,3.25 5.6,4.37 5.6,5.75C5.6,6.75 6.19,7.6 7.03,8C6.19,8.4 5.6,9.25 5.6,10.25ZM12,5.5C13.38,5.5 14.5,6.62 14.5,8C14.5,9.38 13.38,10.5 12,10.5C10.62,10.5 9.5,9.38 9.5,8C9.5,6.62 10.62,5.5 12,5.5ZM4.63,13.756C5.942,18.719 7.255,20.031 12.505,20.688C11.192,15.738 9.88,14.412 4.63,13.756Z" style={{ fillRule: 'nonzero' }} />
        </g>
      </g>
    </SvgIcon>
  );
};

export default Icon;
