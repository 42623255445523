import { isArrayLike } from 'lodash';

export default (items, selectedCallback) => {
  if (!isArrayLike(items) || items.length === 0) {
    return null;
  }

  let foundSelected = false;
  let foundUnselected = false;
  for (let i = 0; i < items.length; i++) {
    if (selectedCallback(items[i])) {
      foundSelected = true;
    } else {
      foundUnselected = true;
    }

    if (foundSelected && foundUnselected) {
      // Some are selected and some are unselected
      return null;
    }
  }

  if (foundSelected) {
    // All are selected
    return true;
  }

  // All are unselected
  return false;
};
