import {
  Backdrop,
  withStyles
} from '@material-ui/core';

const LimitedBackdrop = withStyles({
  root: {
    position: 'absolute',
    zIndex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.1)'
  }
})(Backdrop);

export default LimitedBackdrop;
