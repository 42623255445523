import React from 'react';

import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Switch,
} from '@material-ui/core';

import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { defaultTo, get } from 'lodash';

const FormikSwitch = ({
  name,
  label,
  disabled,
  helperText,
  labelPlacement,
  size,
  color,
  onChange,
  ...rest
}) => {
  const {
    values,
    errors,
    touched,
    submitCount,
    handleChange
  } = useFormikContext();

  // Show field error if form has been submitted or if the field has been touched
  const showError = Boolean((submitCount > 0 || get(touched, name)) && get(errors, name));

  const processChange = (event) => {
    handleChange(event);
    if (onChange) {
      onChange(event);
    }
  };

  const tempHelperText = showError ? get(errors, name) : helperText;

  return (
    <FormControl
      disabled={disabled}
      error={showError}
      size={size}
      {...rest}
    >
      {/* <InputLabel id={labelId}>{label}</InputLabel> */}
      <FormControlLabel
        control={(
          <Switch
            name={name}
            checked={defaultTo(get(values, name), false)}
            onChange={processChange}
            size={size}
            color={color}
          />
        )}
        label={label}
        labelPlacement={labelPlacement}
      />
      {tempHelperText && <FormHelperText>{tempHelperText}</FormHelperText>}
    </FormControl>
  );
};

FormikSwitch.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.any,
  labelPlacement: PropTypes.string,
  disabled: PropTypes.bool,
  size: PropTypes.string,
  color: PropTypes.string,
  helperText: PropTypes.string,
  onChange: PropTypes.func
};

export default FormikSwitch;
