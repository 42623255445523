/* eslint-disable no-unused-vars */
import React from 'react';

import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { defaultTo, get } from 'lodash';

import FormEditor from './FormEditor';

const FormikEditor = ({ name, ...rest }) => {
  const {
    values,
    errors,
    touched,
    submitCount,
    setFieldValue,
    setFieldTouched
  } = useFormikContext();

  const errorText = (submitCount > 0 || get(touched, name)) ? get(errors, name) : null;

  return (
    <FormEditor
      value={defaultTo(get(values, name), '')}
      name={name}
      onValueChange={(code) => setFieldValue(name, code)}
      onBlur={() => setFieldTouched(name)}
      errorMessage={errorText}
      {...rest}
    />
  );
};

FormikEditor.propTypes = {
  name: PropTypes.string.isRequired,
};

export default FormikEditor;
