/* eslint-disable react/jsx-tag-spacing */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-indent */
/* eslint-disable max-len */
import React from 'react';
import { SvgIcon } from '@material-ui/core';
import PropTypes from 'prop-types';

const Icon = ({ style, ...rest }) => {
  return (
    <SvgIcon {...rest} style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinecap: 'round', strokeLinejoin: 'round', strokeMiterlimit: '1.5', ...style }}>
      <g transform="matrix(0.863331,0,0,0.863331,-1.27966,0.636128)">
        <g transform="matrix(0.810282,0.586041,-0.586041,0.810282,8.68299,-0.834604)">
            <path d="M6.842,5.12C8.43,5.12 8.679,6.417 8.679,6.841C8.679,7.264 8.43,7.664 6.842,7.664C5.254,7.664 3.573,5.194 3.573,4.77C3.573,4.347 5.254,5.12 6.842,5.12Z"/>
        </g>
        <g transform="matrix(-0.643173,0.389728,0.35885,0.592215,16.972,2.98706)">
            <path d="M6.842,5.12C8.43,5.12 8.679,6.417 8.679,6.841C8.679,7.264 8.43,7.664 6.842,7.664C5.254,7.664 2.54,5.785 2.54,5.361C2.54,4.937 5.254,5.12 6.842,5.12Z"/>
        </g>
        <g transform="matrix(1,0,0,1,1,0)">
            <g transform="matrix(0.702181,-0.049488,0.0698114,0.990547,1.97801,0.394019)">
                <path d="M11.032,9.627C11.032,9.627 9.529,8.625 10.21,9.582C10.551,10.061 11.538,11.688 11.537,13.623C11.536,16.217 10.47,19.205 10.47,19.205L11.119,19.038L12.193,13.516L11.032,9.627Z" style={{ stroke: 'currentColor', strokeWidth: '0.27px' }}/>
            </g>
            <g transform="matrix(-0.447686,-0.154541,-0.189532,0.549049,19.6416,6.43117)">
                <path d="M11.032,9.627C11.032,9.627 9.529,8.625 10.21,9.582C10.551,10.061 11.538,11.688 11.537,13.623C11.536,16.217 10.47,19.205 10.47,19.205L11.119,19.038L12.193,13.516L11.032,9.627Z" style={{ stroke: 'currentColor', strokeWidth: '0.44px' }}/>
            </g>
        </g>
    </g>
    <g transform="matrix(0.857143,0,0,0.857143,3.71429,4.71429)">
        <path d="M5,15.5L7.917,15.5L8,19L5,19L5,15.5ZM9.667,9.083L12.583,9.083L12.583,19L9.667,19L9.667,9.083ZM14.333,4.417L17.25,4.417L17.25,19L14.333,19L14.333,4.417Z" style={{ fillRule: 'nonzero' }}/>
    </g>
    </SvgIcon>
  );
};

Icon.propTypes = {
  style: PropTypes.object
};

export default Icon;
