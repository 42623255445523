import { isString } from 'lodash';

class CompareToBuilder {
  _nullLast = true;

  _compare = 0;
  // constructor () {}

  appendString(a, b, caseInsensitive = true) {
    if (this._compare !== 0 || (a == null && b == null)) {
      return this;
    }

    this._compare = this._testNull(a, b);
    if (this._compare !== 0) {
      return this;
    }

    if (!isString(a) || !isString(b)) {
      return this;
    }

    if (caseInsensitive) {
      this._compare = a.toLowerCase().localeCompare(b.toLowerCase());
    } else {
      this._compare = a.localeCompare(b);
    }

    return this;
  }

  appendBoolean(a, b) {
    if (this._compare !== 0 || (a == null && b == null)) {
      return this;
    }

    this._compare = this._testNull(a, b);
    if (this._compare !== 0) {
      return this;
    }

    if (a !== b) {
      this._compare = a ? 1 : -1;
    }

    return this;
  }

  appendNumber(a, b) {
    if (this._compare !== 0 || (a == null && b == null)) {
      return this;
    }

    this._compare = this._testNull(a, b);
    if (this._compare !== 0) {
      return this;
    }

    if (a > b) {
      this._compare = 1;
    } else if (b > a) {
      this._compare = -1;
    }

    return this;
  }

  toComparison() {
    return this._compare;
  }

  _testNull(a, b) {
    if (a == null && b != null) {
      return this._nullLast ? 1 : -1;
    }

    if (a != null && b == null) {
      return this._nullLast ? -1 : 1;
    }

    return 0;
  }
}

export default CompareToBuilder;
