import React, { useState } from 'react';

import {
  Tooltip,
  IconButton,
  InputAdornment
} from '@material-ui/core';

import {
  QrCode as QrCodeIcon
} from 'src/icons';

import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';

import { FormikTextField } from 'src/components/form';
import { useCompositionContext } from 'src/composition';
import { QrCodeDialog } from 'src/components/core';

const QrEndAdornment = ({ onClick }) => {
  return (
    <InputAdornment position="end">
      <Tooltip title="QR Code">
        <IconButton
          tabIndex="-1"
          onClick={onClick}
          edge="end"
        >
          <QrCodeIcon />
        </IconButton>
      </Tooltip>
    </InputAdornment>
  );
};

QrEndAdornment.propTypes = {
  onClick: PropTypes.func,
};

const formatHex = (text) => {
  return !text ? text : text
    .replaceAll(/(0x)|([,:.;])/ig, ' ')
    .replaceAll(/(\s+)/g, ' ')
    .trim();
};

const FormikQrCodeTextField = ({
  name,
  InputProps,
  format,
  ...rest
}) => {
  const [open, setOpen] = useState(false);
  const {
    setFieldValue
  } = useFormikContext();

  const composition = useCompositionContext();
  const qrCodeEnabled = composition.options.qrCode || false;

  const handleSuccess = (text) => {
    let formatted = null;

    if (format === 'hex') {
      formatted = formatHex(text);
    } else if (typeof format === 'function') {
      formatted = format(text);
    } else {
      formatted = text;
    }
    setFieldValue(name, formatted);
    setOpen(false);
  };

  return (
    <>
      <QrCodeDialog
        open={open}
        onCancel={() => setOpen(false)}
        onSuccess={handleSuccess}
      />
      <FormikTextField
        name={name}
        InputProps={{
          ...InputProps,
          endAdornment: qrCodeEnabled ? <QrEndAdornment onClick={() => setOpen(true)} /> : null
        }}
        {...rest}
      />
    </>
  );
};

FormikQrCodeTextField.propTypes = {
  name: PropTypes.string.isRequired,
  InputProps: PropTypes.object,
  format: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
};

export default FormikQrCodeTextField;
