/* eslint-disable no-unused-vars */
import React, { useState } from 'react';

import {
  Box,
  DialogTitle,
  Typography,
  makeStyles,
} from '@material-ui/core';

import PropTypes from 'prop-types';
import { isString, get } from 'lodash';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  headerIcon: {
    display: 'block',
    marginLeft: -theme.spacing(1),
    marginRight: theme.spacing(2),
  },

  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
    minHeight: 54,
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: theme.spacing(1)
  }
}));

const ActionDialogTitle = ({
  title,
  disableTypography,
  className,
  classes,
  compact,
  avatar,
  avatarProps,
  actions,
  children,
  ...rest
}) => {
  const baseClasses = useStyles();

  const avatarNode = (avatar == null || React.isValidElement(avatar))
    ? avatar
    : React.createElement(avatar, {
      color: 'primary',
      className: clsx(baseClasses.headerIcon, get(classes, 'headerIcon')),
      ...avatarProps
    });

  const content = title || children;
  let contentNode = null;
  if (content) {
    if (isString(content) && !disableTypography) {
      contentNode = <Typography variant="h5" className={get(classes, 'title')}>{content}</Typography>;
    } else {
      contentNode = content;
    }
  }

  return (
    <DialogTitle
      disableTypography
      classes={{
        root: clsx(baseClasses.dialogTitle, get(classes, 'root'))
      }}
      {...rest}
    >
      {avatarNode}
      {contentNode}
      {actions && (
        <>
          <Box flexGrow={1} />
          <Box>{actions}</Box>
        </>
      )}
    </DialogTitle>
  );
};

ActionDialogTitle.propTypes = {
  disableTypography: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  className: PropTypes.string,
  classes: PropTypes.object,
  compact: PropTypes.bool,
  avatar: PropTypes.oneOfType([PropTypes.element, PropTypes.elementType]),
  avatarProps: PropTypes.object,
  actions: PropTypes.any,
  children: PropTypes.any,
};

export default ActionDialogTitle;
