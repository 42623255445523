import React from 'react';

import { Box, Checkbox, Radio, FormControlLabel, Typography, colors, makeStyles } from '@material-ui/core';

import clsx from 'clsx';
import PropTypes from 'prop-types';
import { defaultTo, get } from 'lodash';

const useCheckboxStyles = makeStyles((theme) => ({
  legendItem: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
    // flexBasis: 160,
    flexShrink: 1,
    flexGrow: 0
  },
  label: {
    fontSize: '0.75rem',
    maxWidth: 125
    // whiteSpace: 'nowrap'
  },
  checkedLabel: {
    // color: 'green'
  },
  uncheckedLabel: {
    color: colors.grey[600],
    textDecoration: (props) => props.uncheckedDecoration
  },
  checkbox: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 10,
    paddingRight: 0,
    color: (props) => props.color
  }
}));

const useStyles = makeStyles((theme) => ({
  container: {
    // paddingLeft: theme.spacing(1),
    // paddingRight: theme.spacing(1),
    marginLeft: theme.spacing(-2),
    marginTop: theme.spacing(-1),
    display: 'flex',
    flexWrap: 'wrap'
  }
}));

const LegendCheckbox = ({ legendItem, setSelected, checkboxProps, checkboxComponent, width, ...rest }) => {
  const classes = useCheckboxStyles({ color: defaultTo(legendItem.color, colors.grey[500]),
    uncheckedDecoration: defaultTo(legendItem.uncheckedDecoration, 'line-through') });
  const handleChange = (event) => {
    setSelected(legendItem.id, event.target.checked);
  };
  const LegendItem = checkboxComponent || Checkbox;

  const style = width ? { flexBasis: width } : null;

  return (
    <FormControlLabel
      className={classes.legendItem}
      style={style}
      control={(
        <LegendItem
          checked={legendItem.checked}
          onChange={handleChange}
          className={classes.checkbox}
          color="default"
          classes={{
            root: classes.checkbox
          }}
          {...checkboxProps}
        />
      )}
      label={(
        <Box>
          <Typography
            variant="body1"
            className={clsx(classes.label, legendItem.checked ? classes.checkedLabel : classes.uncheckedLabel)}
            noWrap
            title={legendItem.name}
          >
            {legendItem.name}
          </Typography>
          {legendItem.deviceName && (
            <Typography
              variant="body2"
              className={clsx(classes.label, legendItem.checked ? classes.checkedLabel : classes.uncheckedLabel)}
              noWrap
              title={legendItem.name}
              style={{ fontSize: '0.65rem', color: 'grey' }}
            >
              {legendItem.deviceName}
            </Typography>
          )}
        </Box>
      )}
      {...rest}
    />
  );
};

LegendCheckbox.propTypes = {
  legendItem: PropTypes.object,
  setSelected: PropTypes.func,
  checkboxProps: PropTypes.object,
  width: PropTypes.number,
  checkboxComponent: PropTypes.oneOf([Checkbox, Radio])
};

const FlexLegend = ({ legendItems = [], setSelected, containerProps, checkboxProps, checkboxComponent, itemWidth }) => {
  const classes = useStyles();

  return (
    <Box {...containerProps} className={clsx(classes.container, get(containerProps, 'className'))}>
      {legendItems.map((legendItem) => (
        <LegendCheckbox
          key={legendItem.id}
          legendItem={legendItem}
          setSelected={setSelected}
          checkboxProps={checkboxProps}
          checkboxComponent={checkboxComponent}
          width={itemWidth}
        />
      ))}
    </Box>
  );
};

FlexLegend.propTypes = {
  legendItems: PropTypes.array,
  setSelected: PropTypes.func,
  containerProps: PropTypes.object,
  checkboxProps: PropTypes.object,
  itemWidth: PropTypes.number,
  checkboxComponent: PropTypes.oneOf([Checkbox, Radio])
};

export default FlexLegend;
