/* eslint-disable no-unused-vars */
import React, { useState } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  // DialogTitle,
  makeStyles
} from '@material-ui/core';
import {
  ErrorOutline as ErrorIcon,
  ReportProblemOutlined as WarningIcon,
  CheckCircleOutline as SuccessIcon,
} from '@material-ui/icons';

import PropTypes from 'prop-types';

import { FormProgress } from 'src/components/form';

import ActionDialogTitle from './ActionDialogTitle';

const SEVERITY_ICONS = {
  success: SuccessIcon,
  warning: WarningIcon,
  error: ErrorIcon,
};

const useStyles = makeStyles((theme) => ({
  successIcon: {
    color: theme.palette.success.main
  },
  warningIcon: {
    color: theme.palette.warning.main
  },
  errorIcon: {
    color: theme.palette.error.main
  }
}));

const ConfirmDialogComponent = ({
  title,
  message,
  onConfirm,
  onCancel,
  children,
  severity,
  avatar,
  avatarProps,
  disableTypography = false,
  ...rest
}) => {
  const [loading, setLoading] = useState(false);

  const classes = useStyles();

  const performCancel = () => {
    if (!loading && onCancel) {
      onCancel();
    }
  };

  const performConfirm = () => {
    if (!loading && onConfirm) {
      setLoading(true);
      onConfirm();
    }
  };

  let headerIconClass = null;
  if (!avatar && severity) {
    avatar = SEVERITY_ICONS[severity];
    headerIconClass = classes[`${severity}Icon`];
  }

  let content = message || children;
  if (!disableTypography) {
    content = <DialogContentText>{content}</DialogContentText>;
  }

  return (
    <Dialog
      open
      onClose={performCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      {...rest}
    >
      {loading && <FormProgress />}
      {title && (
        <ActionDialogTitle
          avatar={avatar}
          avatarProps={avatarProps}
          classes={{ headerIcon: headerIconClass }}
          id="alert-dialog-title"
        >
          {title}
        </ActionDialogTitle>
      )}
      <DialogContent>
        {content}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={performCancel} color="primary" disabled={loading}>
          Cancel
        </Button>
        <Button onClick={performConfirm} color="primary" disabled={loading}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmDialogComponent.propTypes = {
  title: PropTypes.string,
  message: PropTypes.node,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  severity: PropTypes.string,
  avatar: PropTypes.oneOfType([PropTypes.element, PropTypes.elementType]),
  avatarProps: PropTypes.object,
  disableTypography: PropTypes.bool,
  children: PropTypes.node
};

const ConfirmDialog = ({ open, ...rest }) => {
  if (!open) {
    return null;
  }

  return <ConfirmDialogComponent {...rest} />;
};

ConfirmDialog.propTypes = {
  open: PropTypes.bool
};

export default ConfirmDialog;
