import React from 'react';

import { Box, Container, makeStyles } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { grey } from '@material-ui/core/colors';

import PropTypes from 'prop-types';

import Page from 'src/components/Page';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },

  alert: {
    // '& .MuiAlert-icon': {
    //   marginTop: 4
    // },
    '& .MuiSvgIcon-root': {
      fontSize: 30
    },
    fontSize: '1rem'
  },

  title: {
    fontSize: 20,
    fontWeight: 'bold'
  },

  continue: {
    textAlign: 'center',
    marginTop: theme.spacing(2),
    fontSize: '1rem',
    color: grey[500],
    '& a': {
      color: grey[600],
      textDecoration: 'underline'
    },
  }
}));

const ErrorView = ({ title, children }) => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title={title}>
      <Container maxWidth="md">
        <Alert variant="filled" severity="error" className={classes.alert}>
          <AlertTitle className={classes.title}>{title}</AlertTitle>
          {children}
        </Alert>
        <Box className={classes.continue}>
          <a href={process.env.PUBLIC_URL}>Click here</a> to continue.
        </Box>
      </Container>
    </Page>
  );
};

ErrorView.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any
};

export default ErrorView;
