import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  Toolbar,
  makeStyles,
} from '@material-ui/core';

import Logo from 'src/components/Logo';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    width: 60,
    height: 60
  },

  proxy: {
    position: 'fixed',
    left: '50%',
    transform: 'translate(-50%, 0)',
    zIndex: 2000,
    borderWidth: '0 2px 2px 2px',
    borderStyle: 'solid',
    borderColor: '#b7332c',
    backgroundColor: '#f44336',
    color: theme.palette.getContrastText('#f44336'),
    textTransform: 'none',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    '&:hover': {
      backgroundColor: '#dd3e33',
    }
    // backgroundColor: 'red',
  },

  proxyLabel: {
    marginLeft: 20,
    minWidth: '140px',
    maxWidth: '220px',
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },

}));

const TopBar = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <>
      <AppBar
        className={clsx(classes.root, className)}
        elevation={3}
        color="primary"
        {...rest}
      >
        <Toolbar>
          <Box ml={1}>
            {/* <RouterLink to="/">
              <Logo style={{ maxHeight: 54 }} />
            </RouterLink> */}
            <Logo style={{ maxHeight: 54 }} />
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
};

export default TopBar;
