import React from 'react';

import { KeyboardDateTimePicker } from '@material-ui/pickers';

import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { get } from 'lodash';

const FormikKeyboardDateTimePicker = ({
  name,
  helperText,
  format,
  value,
  onChange,
  ...rest
}) => {
  const {
    values,
    errors,
    touched,
    submitCount,
    handleBlur,
    setFieldValue,
    setFieldTouched
  } = useFormikContext();

  // Show field error if form has been submitted or if the field has been touched
  const showError = Boolean((submitCount > 0 || get(touched, name)) && get(errors, name));

  let pickerValue = null;
  if (typeof value !== 'undefined') {
    pickerValue = value;
  } else {
    pickerValue = get(values, name, null); // .toFormat(format);
  }

  return (
    <KeyboardDateTimePicker
      name={name}
      error={showError}
      helperText={showError ? get(errors, name) : helperText}
      format={format}
      onBlur={handleBlur}
      value={pickerValue}
      onChange={((date) => {
        setFieldValue(name, date);
        if (onChange) {
          onChange(date);
        }
      })}
      onAccept={() => {
        setFieldTouched(name, true, false);
      }}
      {...rest}
    />
  );
};

FormikKeyboardDateTimePicker.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  format: PropTypes.string,
  value: PropTypes.any,
  helperText: PropTypes.string,
  dateOnly: PropTypes.bool,
  onChange: PropTypes.func
};

export default FormikKeyboardDateTimePicker;
