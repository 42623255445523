/* eslint-disable react/jsx-tag-spacing */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-indent */
/* eslint-disable max-len */
import React from 'react';
import { SvgIcon } from '@material-ui/core';
import PropTypes from 'prop-types';

const Icon = ({ style, ...rest }) => {
  return (
    <SvgIcon {...rest} style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinecap: 'round', strokeLinejoin: 'round', strokeMiterlimit: '1.5', ...style }}>
      <g id="IndustrialSensor">
        <g transform="matrix(0.823529,0,0,1.1875,1.70588,-2.84375)">
            <path d="M21,15.868L21,6.215C21,5.268 19.892,4.5 18.527,4.5L6.473,4.5C5.108,4.5 4,5.268 4,6.215L4,18.785C4,19.732 5.108,20.5 6.473,20.5L16.143,20.438" style={{ fill: 'none', stroke: 'currentColor', strokeWidth: '1.96px' }}/>
        </g>
        <g transform="matrix(1.125,0,0,1.25,-1.5,-1.15)">
            <rect x="8" y="6" width="8" height="4" style={{ fill: 'none', stroke: 'currentColor', strokeWidth: '1.05px' }}/>
        </g>
    </g>
    <g transform="matrix(0.152778,0,0,0.152778,6.54167,10.75)">
        <g id="Layer1">
            <path d="M69,24C69,27.3 66.3,30 63,30C62.46,30 61.95,29.94 61.47,29.79L50.79,40.44C50.94,40.92 51,41.46 51,42C51,45.3 48.3,48 45,48C41.7,48 39,45.3 39,42C39,41.46 39.06,40.92 39.21,40.44L31.56,32.79C31.08,32.94 30.54,33 30,33C29.46,33 28.92,32.94 28.44,32.79L14.79,46.47C14.94,46.95 15,47.46 15,48C15,51.3 12.3,54 9,54C5.7,54 3,51.3 3,48C3,44.7 5.7,42 9,42C9.54,42 10.05,42.06 10.53,42.21L24.21,28.56C24.06,28.08 24,27.54 24,27C24,23.7 26.7,21 30,21C33.3,21 36,23.7 36,27C36,27.54 35.94,28.08 35.79,28.56L43.44,36.21C43.92,36.06 44.46,36 45,36C45.54,36 46.08,36.06 46.56,36.21L57.21,25.53C57.06,25.05 57,24.54 57,24C57,20.7 59.7,18 63,18C66.3,18 69,20.7 69,24Z" style={{ fillRule: 'nonzero' }}/>
        </g>
    </g>
    </SvgIcon>
  );
};

Icon.propTypes = {
  style: PropTypes.object
};

export default Icon;
