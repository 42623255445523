import React from 'react';

import {
  Box,
  FormControl,
  FormHelperText,
  Typography
} from '@material-ui/core';
import { useTheme } from '@material-ui/styles';

import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { get } from 'lodash';

import ColorPicker from 'src/components/core/ColorPicker';

const FormikColorPicker = ({ label, name, helperText, ...rest }) => {
  const theme = useTheme();

  const {
    values,
    errors,
    touched,
    submitCount,
    setFieldValue
  } = useFormikContext();

  // Show field error if form has been submitted or if the field has been touched
  const showError = Boolean((submitCount > 0 || get(touched, name)) && get(errors, name));

  const tempHelperText = showError ? get(errors, name) : helperText;

  return (
    <FormControl error={showError}>
      <Box display="inline-flex" alignItems="center">
        {label && (
          <Typography
            variant="body1"
            className="MuiFormControlLabel-label"
            style={{ marginRight: theme.spacing(1) }}
          >
            {label}
          </Typography>
        )}
        <ColorPicker
          color={get(values, name)}
          onChangeComplete={(changedValue) => setFieldValue(name, changedValue)}
          {...rest}
        />
      </Box>
      {tempHelperText && <FormHelperText>{tempHelperText}</FormHelperText>}
    </FormControl>
  );
};

FormikColorPicker.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  helperText: PropTypes.string
};

export default FormikColorPicker;
