import React, { useRef, forwardRef, useEffect } from 'react';
import PropTypes from 'prop-types';

/*
 * HOC that fixes the size of the wrapped component to the last rendered
 * size when fixed = true. Useful for drag/drop where the draggable
 * doesn't remember size once dragging has started.
 */
const withFixedSize = (WrappedComponent) => {
  const FixedSize = forwardRef(({ fixed, children, ...rest }, ref) => {
    const wrappedRef = useRef();
    const rectRef = useRef();

    const getRef = () => ref || wrappedRef;

    useEffect(() => {
      const itemRef = getRef();
      if (itemRef.current && !fixed) {
        const { width, height } = itemRef.current.getBoundingClientRect();
        rectRef.current = { width, height };
      }
    });

    useEffect(() => {
      const wrapped = getRef().current;
      if (!wrapped) {
        return;
      }

      if (fixed) {
        const rect = rectRef.current;
        if (rect) {
          wrapped.style.width = `${rect.width}px`;
          wrapped.style.height = `${rect.height}px`;
        }
      } else {
        wrapped.style.removeProperty('width');
        wrapped.style.removeProperty('height');
      }
    }, [fixed]);

    return (
      <WrappedComponent ref={ref || wrappedRef} {...rest}>
        {children}
      </WrappedComponent>
    );
  });

  FixedSize.propTypes = {
    fixed: PropTypes.bool,
    children: PropTypes.any
  };

  return FixedSize;
};

export default withFixedSize;
