/* eslint-disable eqeqeq */
import React from 'react';
import PropTypes from 'prop-types';

import {
  FormControlLabel,
  Checkbox,
  makeStyles
} from '@material-ui/core';

import { get } from 'lodash';

import FormSelect from 'src/components/form/FormSelect';

const useStyles = makeStyles(() => ({
  timeZoneAdornment: {
    marginLeft: '0px'
  }
}));

const TimeZoneSelect = ({ value, showAll, showAllName, onShowAllChange, disabled, timeZones, ...rest }) => {
  const classes = useStyles();

  const baseTimeZones = get(timeZones, 'timeZones', []);
  const allTimeZones = get(timeZones, 'allTimeZones', []);
  let timeZoneOptions = allTimeZones;
  if (!showAll) {
    timeZoneOptions = baseTimeZones;
    if (value) {
      if (!baseTimeZones.find((e) => e.id == value)) {
        const foundInAll = allTimeZones.find((e) => e.id == value);
        if (foundInAll) {
          /*
           * If we have a currently selected zone that is not in the short list but
           * is in the full list then add it to the options.
           */
          timeZoneOptions = [...baseTimeZones, foundInAll];
        }
      }
    }
  }

  return (
    <FormSelect
      value={value}
      options={timeZoneOptions}
      disabled={disabled}
      endAdornment={(
        <FormControlLabel
          label="All" disabled={disabled} className={classes.timeZoneAdornment}
          control={<Checkbox checked={Boolean(showAll)} onChange={onShowAllChange} name={showAllName} />}
        />
      )}
      {...rest}
    />
  );
};

TimeZoneSelect.propTypes = {
  value: PropTypes.any,
  showAll: PropTypes.bool,
  showAllName: PropTypes.string,
  onShowAllChange: PropTypes.func,
  disabled: PropTypes.bool,
  timeZones: PropTypes.object
};

export default TimeZoneSelect;
