/* eslint-disable react/jsx-tag-spacing */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-indent */
/* eslint-disable max-len */
import React from 'react';
import { SvgIcon } from '@material-ui/core';
import PropTypes from 'prop-types';

const Icon = ({ style, ...rest }) => {
  return (
    <SvgIcon {...rest} style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinejoin: 'round', strokeMiterlimit: '2', ...style }}>
      <g id="Layer2" transform="matrix(1.72155,0,0,1.72155,-7.4788,-6.47182)">
        <g transform="matrix(0.198465,0,0,0.198465,3.90132,3.73026)">
            <path d="M36,6L19.5,33L52.5,33L36,6Z" style={{ fillRule: 'nonzero' }}/>
        </g>
        <g transform="matrix(0.198465,0,0,0.198465,3.90132,3.73026)">
            <circle cx="52.5" cy="52.5" r="13.5"/>
        </g>
        <g transform="matrix(0.198465,0,0,0.198465,3.90132,3.73026)">
            <rect x="9" y="40.5" width="24" height="24" style={{ fillRule: 'nonzero' }}/>
        </g>
    </g>
    </SvgIcon>
  );
};

Icon.propTypes = {
  style: PropTypes.object
};

export default Icon;
