/* eslint-disable max-len */
import React from 'react';
import { SvgIcon } from '@material-ui/core';

const Icon = (props) => {
  return (
    <SvgIcon {...props}>
      <g transform="matrix(0.0638549,0,0,0.0594055,2.32592,3)">
        <path d="M151.501,0C218.251,0 274.695,27.559 276.605,60.549C274.695,93.536 218.251,121.102 151.501,121.102C84.749,121.102 28.31,93.542 26.397,60.549C28.31,27.559 84.749,0 151.501,0Z" />
        <path d="M276.785,240.744C276.785,274.491 219.416,303.002 151.501,303.002C83.586,303.002 26.217,274.491 26.217,240.744L26.217,210.973C47.666,236.727 95.104,254.243 151.501,254.243C207.898,254.243 255.335,236.722 276.785,210.973L276.785,240.744Z" />
        <path d="M276.785,179.688C276.785,213.434 219.416,241.946 151.501,241.946C83.586,241.946 26.217,213.434 26.217,179.688L26.217,148.679C47.666,174.427 95.104,191.938 151.501,191.938C207.898,191.938 255.335,174.416 276.785,148.679L276.785,179.688Z" />
        <path d="M276.785,117.381C276.785,151.129 219.416,179.638 151.501,179.638C83.586,179.638 26.217,151.128 26.217,117.381L26.217,90.135C47.666,115.889 95.104,133.393 151.501,133.393C207.898,133.393 255.335,115.883 276.785,90.135L276.785,117.381Z" />
      </g>
    </SvgIcon>
  );
};

export default Icon;
