/* eslint-disable no-unused-vars */
import React from 'react';

import {
  Box,
  Checkbox,
  FormControl,
  FormHelperText,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  Typography,
  makeStyles
} from '@material-ui/core';
import { useTheme } from '@material-ui/styles';

import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { get, isArrayLike } from 'lodash';

import FormControlContainer from './FormControlContainer';

const DAY_INDEXES = [6, 0, 1, 2, 3, 4, 5];

const useStyles = makeStyles((theme) => ({
  dayTable: {
    '& .MuiTableCell-root': {
      borderBottom: 'none',
      textAlign: 'center',
      padding: 0
    },

    '& .MuiCheckbox-root': {
      marginTop: -9,
      padding: 9
    }
  }
}));

const DayCheckbox = ({ value, index, onChange, ...rest }) => {
  return (
    <Checkbox
      checked={get(value, index, false)}
      onChange={(event) => onChange(index, event)}
      {...rest}
    />
  );
};

DayCheckbox.propTypes = {
  value: PropTypes.array,
  index: PropTypes.number,
  onChange: PropTypes.func,
};

const FormikDayOfWeekPicker = ({ name, disabled, ...rest }) => {
  const classes = useStyles();

  const {
    values,
    errors,
    touched,
    submitCount,
    setFieldValue,
    setFieldTouched
  } = useFormikContext();

  let value = get(values, name);
  if (!isArrayLike(value)) {
    value = [];
  }

  const performChange = (index, event) => {
    const newValue = [...value];
    newValue[index] = event.target.checked;
    setFieldValue(name, newValue);
    setFieldTouched(name, true, false);
  };

  // Show field error if form has been submitted or if the field has been touched
  const errorMessage = (submitCount > 0 || get(touched, name)) ? get(errors, name) : null;

  return (
    <FormControlContainer
      {...rest}
      errorMessage={errorMessage}
    >
      <Box mt={0.5}>
        <Table className={classes.dayTable}>
          <TableBody>
            <TableRow>
              <TableCell>Sun</TableCell>
              <TableCell>Mon</TableCell>
              <TableCell>Tue</TableCell>
              <TableCell>Wed</TableCell>
              <TableCell>Thu</TableCell>
              <TableCell>Fri</TableCell>
              <TableCell>Sat</TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            <TableRow>
              {DAY_INDEXES.map((i) => (
                <TableCell key={i}>
                  <DayCheckbox value={value} index={i} onChange={performChange} disabled={disabled} />
                </TableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    </FormControlContainer>
  );
};

FormikDayOfWeekPicker.propTypes = {
  name: PropTypes.string,
  disabled: PropTypes.bool
};

export default FormikDayOfWeekPicker;
