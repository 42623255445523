/* eslint-disable react/jsx-tag-spacing */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-indent */
/* eslint-disable max-len */
import React from 'react';
import { SvgIcon } from '@material-ui/core';
import PropTypes from 'prop-types';

const Icon = ({ style, ...rest }) => {
  return (
    <SvgIcon {...rest} style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinejoin: 'round', strokeMiterlimit: '2', ...style }}>
      <g transform="matrix(4.28626e-17,0.7,-1,6.12323e-17,26.215,2.6)">
        <path d="M17,16.99C15.65,16.99 14.8,17.41 14.05,17.79C13.4,18.12 12.87,18.39 12,18.39C11.1,18.39 10.6,18.14 9.95,17.79C9.2,17.41 8.38,16.99 7,16.99C5.62,16.99 4.8,17.41 4.05,17.79C3.4,18.12 2.88,18.39 2,18.39L2,20.34C3.35,20.34 4.2,19.92 4.95,19.54C5.6,19.21 6.12,18.94 7,18.94C7.88,18.94 8.4,19.19 9.05,19.54C9.8,19.92 10.62,20.34 12,20.34C13.38,20.34 14.2,19.92 14.95,19.54C15.6,19.21 16.13,18.94 17,18.94C17.9,18.94 18.4,19.19 19.05,19.54C19.8,19.92 20.63,20.34 22,20.34L22,18.39C21.1,18.39 20.6,18.14 19.95,17.79C19.2,17.41 18.35,16.99 17,16.99ZM17,12.54C15.65,12.54 14.8,12.97 14.05,13.34C13.4,13.66 12.87,13.94 12,13.94C11.1,13.94 10.6,13.69 9.95,13.34C9.2,12.96 8.38,12.54 7,12.54C5.62,12.54 4.8,12.97 4.05,13.34C3.4,13.66 2.88,13.94 2,13.94L2,15.89C3.35,15.89 4.2,15.46 4.95,15.09C5.6,14.74 6.1,14.49 7,14.49C7.9,14.49 8.4,14.74 9.05,15.09C9.8,15.47 10.62,15.89 12,15.89C13.38,15.89 14.2,15.46 14.95,15.09C15.6,14.74 16.1,14.49 17,14.49C17.9,14.49 18.4,14.74 19.05,15.09C19.8,15.47 20.63,15.89 22,15.89L22,13.94C21.1,13.94 20.6,13.69 19.95,13.34C19.2,12.96 18.35,12.54 17,12.54ZM17,8.09C15.65,8.09 14.8,8.52 14.05,8.89C13.4,9.24 12.9,9.49 12,9.49C11.1,9.49 10.6,9.24 9.95,8.89C9.2,8.51 8.38,8.09 7,8.09C5.62,8.09 4.8,8.52 4.05,8.89C3.4,9.24 2.9,9.49 2,9.49L2,11.44C3.35,11.44 4.2,11.01 4.95,10.64C5.6,10.32 6.13,10.04 7,10.04C7.87,10.04 8.4,10.29 9.05,10.64C9.8,11.02 10.62,11.44 12,11.44C13.38,11.44 14.2,11.01 14.95,10.64C15.6,10.32 16.13,10.04 17,10.04C17.9,10.04 18.4,10.29 19.05,10.64C19.8,11.02 20.63,11.44 22,11.44L22,9.49C21.1,9.49 20.6,9.24 19.95,8.89C19.2,8.51 18.35,8.09 17,8.09Z" style={{ fillRule: 'nonzero' }}/>
    </g>
    <rect x="4" y="20" width="16" height="2"/>
    </SvgIcon>
  );
};

Icon.propTypes = {
  style: PropTypes.object
};

export default Icon;
