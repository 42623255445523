/* eslint-disable react/jsx-tag-spacing */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-indent */
/* eslint-disable max-len */
import React from 'react';
import { SvgIcon } from '@material-ui/core';
import PropTypes from 'prop-types';

const Icon = ({ style, ...rest }) => {
  return (
    <SvgIcon {...rest} style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinecap: 'round', strokeLinejoin: 'round', strokeMiterlimit: '1.5', ...style }}>
      <g id="Layer4" transform="matrix(1,0,0,1,0,5)">
        <g transform="matrix(1.0466,0,0,1,1.65721,1.5)">
            <path d="M17.765,5.5C17.765,3.568 16.266,2 14.42,2L5.344,2C3.498,2 2,3.568 2,5.5C2,7.432 3.498,9 5.344,9L14.42,9C16.266,9 17.765,7.432 17.765,5.5Z" style={{ fill: 'white', fillOpacity: '0', stroke: 'currentColor', strokeWidth: '1.22px' }}/>
        </g>
        <g transform="matrix(1.12032,0,0,1.12032,-11.0454,-0.84224)">
            <circle cx="17" cy="7" r="2"/>
        </g>
    </g>
    </SvgIcon>
  );
};

Icon.propTypes = {
  style: PropTypes.object
};

export default Icon;
