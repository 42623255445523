/* eslint-disable max-len */
import React from 'react';
import { SvgIcon } from '@material-ui/core';

const Icon = (props) => {
  return (
    <SvgIcon {...props}>
      <g id="Air" transform="matrix(1,0,0,1,-1,-1)">
        <g transform="matrix(1,0,0,1,1,1)">
          <path d="M15.033,8.5L15.045,8.5C16.954,8.494 18.5,7.041 18.5,5.25C18.5,3.456 16.947,2 15.033,2C13.119,2 11.567,3.455 11.567,5.25L13.699,5.25C13.699,4.559 14.296,3.999 15.033,3.999C15.77,3.999 16.368,4.559 16.368,5.25C16.368,5.941 15.771,6.501 15.033,6.501L15.033,6.5L2,6.5L2,8.5L15.033,8.5Z" />
        </g>
        <g transform="matrix(1,0,0,1,1,1)">
          <path d="M18.033,11C19.948,11 21.5,12.455 21.5,14.25C21.5,16.044 19.947,17.5 18.033,17.5C16.119,17.5 14.567,16.045 14.567,14.25L16.699,14.25C16.699,14.941 17.296,15.501 18.033,15.501C18.77,15.501 19.368,14.941 19.368,14.25C19.368,13.559 18.771,12.999 18.033,12.999M18.033,12.999L2,13L2,11L18.033,11" />
        </g>
        <g transform="matrix(1,0,0,1,1,1)">
          <path d="M10.533,15.5C12.433,15.517 13.967,16.965 13.967,18.75C13.967,20.544 12.413,22 10.5,22C8.585,22 7.033,20.545 7.033,18.75L9.165,18.75C9.165,19.441 9.763,20.001 10.5,20.001C11.237,20.001 11.835,19.441 11.835,18.75C11.835,18.069 11.255,17.516 10.533,17.499L10.533,17.5L2,17.5L2,15.5L10.533,15.5L10.533,15.5Z" />
        </g>
      </g>
    </SvgIcon>
  );
};

export default Icon;
