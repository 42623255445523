/* eslint-disable no-unused-vars */
import React, { useState } from 'react';

import {
  Button,
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles
} from '@material-ui/core';
import {
  Inbox as InboxIcon,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon
} from '@material-ui/icons';
import { getThemeProps } from '@material-ui/styles';

import clsx from 'clsx';
import PropTypes from 'prop-types';

import { NavLink as RouterLink } from 'src/components/Router';
import { useSystemContext } from 'src/service/System';

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },

  button: {
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: '10px 8px',
    textTransform: 'none',
    width: '100%'
  },

  icon: {
    marginRight: theme.spacing(1)
  },

  title: {
    marginRight: 'auto'
  },

  active: {
    color: theme.palette.primary.main,
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium
    },
    '& $icon': {
      color: theme.palette.primary.main
    }
  },

  subMenu: {
    paddingLeft: (props) => theme.spacing((1 + props.level) * 2)
  }
}));

const NavItem = ({
  className,
  href,
  icon: Icon,
  title,
  sortKey,
  sort,
  items,
  level = 0,
  menuId = '',
  ...rest
}) => {
  const getSessionKey = () => {
    return `MENU_STATE-${menuId}-${level}-${title}`;
  };

  const system = useSystemContext();
  const [open, setOpen] = useState(system.getSessionItem(getSessionKey(), false));
  const classes = useStyles({ level });

  const toggleSubMenu = () => {
    const newOpen = !open;
    system.setSessionItem(getSessionKey(), newOpen);
    setOpen(newOpen);
  };

  return (
    <>
      <ListItem
        className={clsx(classes.item, className)}
        disableGutters
        {...rest}
      >
        {items ? (
          <>
            <Button
              type="button"
              // activeClassName={classes.active}
              className={classes.button}
              onClick={toggleSubMenu}
            >
              {Icon && (
                <Icon className={classes.icon} />
              )}
              <span className={classes.title}>
                {title}
              </span>
              {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </Button>
          </>
        ) : (
          <Button
            // activeClassName={classes.active}
            className={classes.button}
            component={RouterLink}
            to={href}
          >
            {Icon && (
              <Icon className={classes.icon} />
            )}
            <span className={classes.title}>
              {title}
            </span>
          </Button>
        )}
      </ListItem>
      {items && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding className={classes.subMenu}>
            {items.map((item) => (
              <NavItem
                key={item.title}
                href={item.href}
                icon={item.icon}
                title={item.title}
                items={item.items}
                level={level + 1}
                menuId={menuId}
              />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
};

/*

items.map((item) => (
        <NavItem key={item.title} {...item} />
      )

  <ListItem button onClick={handleClick}>
  <ListItemIcon>
    <InboxIcon />
  </ListItemIcon>
  <ListItemText primary="Inbox" />
  {open ? <ExpandLess /> : <ExpandMore />}
</ListItem>
<Collapse in={open} timeout="auto" unmountOnExit>
  <List component="div" disablePadding>
    <ListItem button className={classes.nested}>
      <ListItemIcon>
        <StarBorder />
      </ListItemIcon>
      <ListItemText primary="Starred" />
    </ListItem>
  </List>
</Collapse>
*/

NavItem.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  icon: PropTypes.elementType,
  title: PropTypes.string,
  sortKey: PropTypes.string,
  sort: PropTypes.bool,
  items: PropTypes.array,
  level: PropTypes.number,
  menuId: PropTypes.string,
};

export default NavItem;
