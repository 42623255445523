/* eslint-disable max-len */
import React from 'react';
import { SvgIcon } from '@material-ui/core';

const Icon = (props) => {
  return (
    <SvgIcon {...props}>
      <g id="Air" transform="matrix(1,0,0,1,-1,-1)">
        <g>
          <g transform="matrix(0.965432,0,0,1,0.58642,0)">
            <rect x="2.5" y="7.5" width="13.5" height="2" />
          </g>
          <g transform="matrix(0.866667,0,0,0.866667,6.93333,-2.63333)">
            <path d="M10.5,14C12.709,14 14.5,12.321 14.5,10.25C14.5,8.18 12.708,6.5 10.5,6.5C8.291,6.5 6.5,8.179 6.5,10.25L8.96,10.25C8.96,9.453 9.649,8.806 10.5,8.806C11.35,8.806 12.04,9.453 12.04,10.25C12.04,11.047 11.351,11.694 10.5,11.694L10.5,14Z" />
          </g>
        </g>
        <g transform="matrix(1,0,0,-1,3,20.25)">
          <g transform="matrix(1.18765,-2.2981e-17,-2.2981e-17,1,-2.96914,-2.91265e-17)">
            <rect x="2.5" y="7.5" width="13.5" height="2" />
          </g>
          <g transform="matrix(0.866667,0,0,0.866667,6.93333,-2.63333)">
            <path d="M10.5,14C12.709,14 14.5,12.321 14.5,10.25C14.5,8.18 12.708,6.5 10.5,6.5C8.291,6.5 6.5,8.179 6.5,10.25L8.96,10.25C8.96,9.453 9.649,8.806 10.5,8.806C11.35,8.806 12.04,9.453 12.04,10.25C12.04,11.047 11.351,11.694 10.5,11.694L10.5,14Z" />
          </g>
        </g>
        <g transform="matrix(0.90584,0,0,1.42342,3.48266,-3.21531)">
          <g transform="matrix(5,0,0,5,1.73913,18.3536)">
            <path d="M0.023,-0.716L0.695,-0.716L0.695,-0.539L0.47,-0.539L0.47,-0L0.249,-0L0.249,-0.539L0.023,-0.539L0.023,-0.716Z" style={{ fillRule: 'nonzero' }} />
          </g>
          <g transform="matrix(5,0,0,5,5.34997,18.3536)">
            <path d="M0.002,-0.716L0.234,-0.716L0.395,-0.201L0.554,-0.716L0.778,-0.716L0.513,-0L0.273,-0L0.002,-0.716Z" style={{ fillRule: 'nonzero' }} />
          </g>
          <g transform="matrix(5,0,0,5,9.06823,18.3536)">
            <path d="M0.045,-0.357C0.045,-0.474 0.078,-0.565 0.143,-0.63C0.208,-0.695 0.299,-0.728 0.415,-0.728C0.534,-0.728 0.626,-0.696 0.69,-0.632C0.755,-0.568 0.787,-0.479 0.787,-0.363C0.787,-0.28 0.773,-0.211 0.745,-0.157C0.717,-0.104 0.676,-0.062 0.623,-0.032C0.57,-0.003 0.503,0.012 0.424,0.012C0.343,0.012 0.276,-0.001 0.223,-0.026C0.17,-0.052 0.128,-0.093 0.095,-0.148C0.062,-0.204 0.045,-0.274 0.045,-0.357ZM0.267,-0.356C0.267,-0.284 0.28,-0.232 0.307,-0.201C0.334,-0.169 0.37,-0.153 0.417,-0.153C0.464,-0.153 0.501,-0.169 0.527,-0.2C0.553,-0.231 0.566,-0.286 0.566,-0.366C0.566,-0.434 0.552,-0.483 0.525,-0.514C0.498,-0.545 0.461,-0.561 0.415,-0.561C0.37,-0.561 0.334,-0.545 0.307,-0.513C0.28,-0.482 0.267,-0.429 0.267,-0.356Z" style={{ fillRule: 'nonzero' }} />
          </g>
          <g transform="matrix(5,0,0,5,13.2333,18.3536)">
            <path d="M0.55,-0.292L0.744,-0.234C0.731,-0.18 0.71,-0.134 0.682,-0.098C0.654,-0.061 0.619,-0.034 0.578,-0.015C0.536,0.003 0.484,0.013 0.419,0.013C0.342,0.013 0.278,0.001 0.229,-0.021C0.179,-0.044 0.137,-0.084 0.101,-0.141C0.065,-0.198 0.047,-0.271 0.047,-0.359C0.047,-0.478 0.079,-0.569 0.142,-0.633C0.205,-0.696 0.294,-0.728 0.409,-0.728C0.499,-0.728 0.57,-0.71 0.622,-0.673C0.673,-0.637 0.712,-0.581 0.737,-0.505L0.542,-0.462C0.535,-0.484 0.528,-0.5 0.52,-0.51C0.508,-0.527 0.493,-0.54 0.475,-0.549C0.457,-0.558 0.437,-0.563 0.415,-0.563C0.364,-0.563 0.326,-0.542 0.299,-0.502C0.279,-0.472 0.269,-0.425 0.269,-0.361C0.269,-0.281 0.281,-0.227 0.305,-0.198C0.329,-0.168 0.363,-0.153 0.407,-0.153C0.449,-0.153 0.481,-0.165 0.503,-0.189C0.524,-0.213 0.54,-0.247 0.55,-0.292Z" style={{ fillRule: 'nonzero' }} />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default Icon;
