/* eslint-disable max-len */
import React from 'react';
import { SvgIcon } from '@material-ui/core';

const Icon = (props) => {
  return (
    <SvgIcon {...props}>
      <g id="Grow-Historical">
        <g transform="matrix(0.711362,0,0,0.711362,3.46366,3.46564)">
          <path d="M6.05,8.05C3.32,10.78 3.32,15.2 6.03,17.93C7.5,14.53 10.12,11.69 13.39,10C10.62,12.34 8.68,15.61 8,19.32C10.6,20.55 13.8,20.1 15.95,17.95C19.43,14.47 20,4 20,4C20,4 9.53,4.57 6.05,8.05Z" style={{ fillRule: 'nonzero' }} />
        </g>
        <g transform="matrix(1.05882,0,0,1.125,-1.23529,-2.0625)">
          <path d="M21,6.827C21,5.543 19.892,4.5 18.527,4.5L6.473,4.5C5.108,4.5 4,5.543 4,6.827L4,18.173C4,19.457 5.108,20.5 6.473,20.5L18.527,20.5C19.892,20.5 21,19.457 21,18.173L21,6.827Z" style={{ fill: 'none', stroke: 'currentColor', strokeWidth: '1.83px' }} />
        </g>
      </g>
    </SvgIcon>
  );
};

export default Icon;
