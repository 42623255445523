/* eslint-disable react/jsx-tag-spacing */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-indent */
/* eslint-disable max-len */
import React from 'react';
import { SvgIcon } from '@material-ui/core';
import PropTypes from 'prop-types';

const Icon = ({ style, ...rest }) => {
  return (
    <SvgIcon {...rest} style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinejoin: 'round', strokeMiterlimit: '2', ...style }}>
      <g id="Layer3">
        <path d="M21,11L13,11L13,21L21,21L21,11ZM11,15L3,15L3,21L11,21L11,15ZM19,13L19,19L15,19L15,13L19,13ZM9,17L9,19L5,19L5,17L9,17ZM11,3L3,3L3,13L11,13L11,3ZM9,5L9,11L5,11L5,5L9,5Z" style={{ fillRule: 'nonzero' }} />
        <g transform="matrix(2.06556,0,0,2.06556,-17.6934,7.31576)">
          <path d="M14.86,-0.634L15.129,-1.466C15.75,-1.247 16.201,-1.058 16.483,-0.898C16.408,-1.605 16.369,-2.091 16.365,-2.357L17.215,-2.357C17.203,-1.97 17.158,-1.486 17.08,-0.904C17.483,-1.107 17.944,-1.294 18.463,-1.466L18.733,-0.634C18.237,-0.47 17.75,-0.361 17.274,-0.306C17.512,-0.099 17.848,0.27 18.281,0.801L17.578,1.3C17.352,0.991 17.084,0.571 16.776,0.04C16.487,0.591 16.233,1.01 16.014,1.3L15.322,0.801C15.776,0.243 16.1,-0.126 16.295,-0.306C15.791,-0.404 15.313,-0.513 14.86,-0.634Z" style={{ fillRule: 'nonzero' }} />
        </g>
      </g>
    </SvgIcon>
  );
};

Icon.propTypes = {
  style: PropTypes.object
};

export default Icon;
