import React, { useState, useEffect, useRef, useCallback } from 'react';

import {
  Button,
  ButtonGroup,
  Box,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  Grid,
  TextField,
  Tooltip,
  makeStyles,
  Divider,
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

import {
  PhotoCamera as CameraIcon,
  InsertDriveFile as FileIcon,
  PauseCircleFilled as PauseIcon,
  PlayCircleFilled as ResumeIcon,
  SwitchCamera as SwitchCameraIcon,
  ZoomIn as ZoomInIcon,
  ZoomOut as ZoomOutIcon,
} from '@material-ui/icons';

import {
  QrCode as QrCodeIcon
} from 'src/icons';

import PropTypes from 'prop-types';
import {
  ActionDialogTitle,
  GlobalMessages,
  TooltipButton
} from 'src/components/core';

import { Html5Qrcode } from 'html5-qrcode';
import { v4 as uuid } from 'uuid';
import { get } from 'lodash';

import { useStateRef } from 'src/utils/react';
import { useSystemContext } from 'src/service/System';
import log from 'src/support/Logger';
import { trimToNull } from 'src/utils/core';

import DropzoneAreaStyled from './DropzoneAreaStyled';

const useStyles = makeStyles((theme) => ({
  scanArea: {
    overflow: 'auto',
    border: '1px solid green',
    width: '100%',
    height: 150,
  },

  dropzoneRoot: {
    minHeight: 180
  },

  dropzoneText: {
    marginTop: theme.spacing(1)
  },

  modeButtonGroup: {
    marginRight: theme.spacing(1),
  },

  modeIcon: {
    color: grey[600]
  },

  buttonGroupSelected: {
    backgroundColor: grey[300],
    '&:hover': {
      backgroundColor: grey[400],
    }
  },

  cameraToolbar: {
    backgroundColor: grey[600],
    display: 'flex',
    justifyContent: 'flex-end',
    paddingLeft: theme.spacing(1),
    alignItems: 'center',

    '& .MuiSvgIcon-root': {
      color: grey[300]
    }
  },

  scannerContainer: {
    backgroundColor: grey[900],
    minHeight: (props) => (props.camera ? 180 : null),
  }
}));

const PAGE_STATE = 'QR_CODE_PAGE_STATE';

const DEFAULT_STATE = {
  camera: false,
  front: false,
  size: 200
};

const MIN_SIZE = 100;
const MAX_SIZE = 250;

const FileMode = ({ scanner, setMessages, setText }) => {
  const [hasFile, setHasFile] = useState(false);

  const classes = useStyles();

  const handleChange = (files) => {
    setText(null);
    setMessages(null);

    if (files == null || files.length === 0) {
      setHasFile(false);
      return;
    }

    setHasFile(true);
    const file = files[0];
    scanner.scanFileV2(file, false)
      .then((result) => {
        setText(result.decodedText);
      })
      .catch((error) => {
        log.error('Scan error', error);
        setMessages({ message: 'No barcode or QR code detected', severity: 'error' });
      });
  };

  return (
    <DropzoneAreaStyled
      Icon={hasFile ? () => null : null}
      showAlerts={['error']}
      acceptedFiles={['image/*']}
      maxFileSize={15 * 1024 * 1024}
      filesLimit={1}
      dropzoneText="Drag 'n' drop image, or click to select"
      onChange={handleChange}
      previewGridProps={{ container: { spacing: 0 }, item: { xs: 12 } }}
      classes={{
        dropzoneRoot: classes.dropzoneRoot,
        dropzoneText: classes.dropzoneText
      }}
    />
  );
};

FileMode.propTypes = {
  scanner: PropTypes.object,
  setMessages: PropTypes.func,
  setText: PropTypes.func,
};

const CameraMode = ({
  config,
  scanner,
  setMessages,
  setText,
  stop,
  front,
  setFront,
  size: sizeArg,
  setSize,
  containerRef,
}) => {
  const [supports] = useState(() => Boolean('mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices));
  const [scannerState, setScannerState] = useState(0);

  const classes = useStyles();

  const updateScannerState = () => {
    setScannerState(scanner.getState());
  };

  const pause = () => {
    scanner.pause();
    updateScannerState();
  };

  const size = Math.max(Math.min((sizeArg || DEFAULT_STATE.size), MAX_SIZE), MIN_SIZE);

  const resume = () => {
    scanner.resume();
    updateScannerState();
  };

  const decrementSize = () => {
    setSize(Math.max((size - 50), MIN_SIZE));
  };

  const incrementSize = () => {
    setSize(Math.min((size + 50), MAX_SIZE));
  };

  useEffect(() => {
    if (!supports) {
      setMessages({ message: 'Camera not available', severity: 'error' });
    } else if (scanner) {
      const container = containerRef.current;
      const height = get(container, 'offsetHeight', 0);
      // console.log(`Height ${height}`);
      if (height > 50) {
        container.style.height = `${height}px`;
      }
      stop()
        .then(() => {
          const newConfig = {
            fps: 10,
            ...config,
            qrbox: { width: size, height: size }
          };
          return scanner.start(
            { facingMode: front ? 'user' : 'environment' },
            newConfig,
            (_, result) => {
              pause();
              setText(result.decodedText);
            }
          );
        })
        .then(() => {
          updateScannerState();
        })
        .catch((error) => {
          log.error('Failed to start scanner', error);
        })
        .finally(() => {
          if (container) {
            container.style.removeProperty('height');
          }
        });
    }
  }, [scanner, front, size]);

  useEffect(() => {
    return stop;
  }, []);

  if (!supports || !scanner) {
    return null;
  }

  const paused = scannerState === 3;

  return (
    <Box className={classes.cameraToolbar}>
      <Box>
        <ButtonGroup size="small">
          <TooltipButton
            title="Zoom Out"
            wrap={false}
            onClick={incrementSize}
            disabled={size >= MAX_SIZE}
          >
            <ZoomOutIcon className={classes.modeIcon} />
          </TooltipButton>
          <TooltipButton
            title="Zoom In"
            wrap={false}
            onClick={decrementSize}
            disabled={size <= MIN_SIZE}
          >
            <ZoomInIcon className={classes.modeIcon} />
          </TooltipButton>
        </ButtonGroup>
      </Box>
      <Box flexGrow={1} />
      <Tooltip title="Switch Camera">
        <IconButton onClick={() => setFront(!front)}>
          <SwitchCameraIcon />
        </IconButton>
      </Tooltip>
      {paused ? (
        <Tooltip title="Resume Scanning">
          <IconButton onClick={resume}>
            <ResumeIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Pause Scanning">
          <IconButton onClick={pause}>
            <PauseIcon />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
};

CameraMode.propTypes = {
  config: PropTypes.object,
  scanner: PropTypes.object,
  setMessages: PropTypes.func,
  setText: PropTypes.func,
  stop: PropTypes.func,
  front: PropTypes.bool,
  setFront: PropTypes.func,
  size: PropTypes.number,
  setSize: PropTypes.func,
  containerRef: PropTypes.object,
};

const QrCodeDialog = ({
  config,
  onSuccess,
  onCancel,
}) => {
  const [text, setText] = useState(null);
  const [messages, setMessages] = useState();
  const [id] = useState(() => uuid());
  const [scanner, setScanner, scannerRef] = useStateRef();

  const system = useSystemContext();
  const containerRef = useRef();

  const containerCallbackRef = useCallback((element) => {
    containerRef.current = element;
    if (!element) {
      return;
    }
    setScanner(new Html5Qrcode(
      id,
      {
        experimentalFeatures: {
          useBarCodeDetectorIfSupported: true
        }
      }
    ));
  }, []);

  const [pageState, setPageState] = useState(() => {
    const savedState = system.getSessionItem(PAGE_STATE);
    return { ...DEFAULT_STATE, ...savedState };
  });

  const classes = useStyles({ camera: pageState.camera });

  const updatePageState = (state) => {
    const newState = { ...pageState, ...state };
    setPageState(newState);
    system.setSessionItem(PAGE_STATE, newState);
  };

  const stop = () => {
    const currentScanner = scannerRef.current;
    if (currentScanner && currentScanner.isScanning) {
      return currentScanner.stop()
        .then(() => {
          currentScanner.clear();
        })
        .catch((error) => {
          log.error('Error stopping Html5Qrcode', error);
        });
    }
    return Promise.resolve();
  };

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
  };

  const handleOk = () => {
    if (onSuccess) {
      onSuccess(text);
    }
  };

  const handleSetText = (textArg) => {
    setText(trimToNull(textArg));
  };

  const handleModeChange = (cameraModeArg) => {
    setText(null);
    setMessages(null);
    updatePageState({ camera: cameraModeArg });
  };

  return (
    <>
      <Dialog
        open
        maxWidth="xs"
        onClose={handleCancel}
      >
        <ActionDialogTitle
          title="QR Code"
          avatar={QrCodeIcon}
          actions={(
            <Box position="relative">
              <ButtonGroup size="small" className={classes.modeButtonGroup}>
                <TooltipButton
                  title="File"
                  className={pageState.camera ? null : classes.buttonGroupSelected}
                  onClick={() => handleModeChange(false)}
                  wrap={false}
                >
                  <FileIcon className={classes.modeIcon} />
                </TooltipButton>
                <TooltipButton
                  title="Camera"
                  className={pageState.camera ? classes.buttonGroupSelected : null}
                  onClick={() => handleModeChange(true)}
                  wrap={false}
                >
                  <CameraIcon className={classes.modeIcon} />
                </TooltipButton>
              </ButtonGroup>
            </Box>
          )}
        />
        <Divider />
        <DialogContent>
          <div id={id} className={classes.scannerContainer} ref={containerCallbackRef} />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {pageState.camera ? (
                <CameraMode
                  config={config}
                  scanner={scanner}
                  setMessages={setMessages}
                  setText={handleSetText}
                  stop={stop}
                  front={pageState.front}
                  setFront={(frontArg) => updatePageState({ front: frontArg })}
                  size={pageState.size}
                  setSize={(sizeArg) => updatePageState({ size: sizeArg })}
                  containerRef={containerRef}
                />
              ) : (
                <FileMode scanner={scanner} setMessages={setMessages} setText={handleSetText} />
              )}
            </Grid>
            <Grid item xs={12}>
              <Box style={{ position: 'relative' }}>
                <GlobalMessages
                  messages={messages}
                  severity="error"
                  onClear={() => setMessages(null)}
                  timeout={0}
                  containerProps={{ mb: 3, position: 'absolute', width: '100%' }}
                  alertProps={{ variant: 'outlined' }}
                />
                <TextField
                  label="Scanned Text"
                  value={text || ''}
                  onChange={(e) => setText(e.target.value)}
                  spellCheck="false"
                  variant="outlined"
                  size="small"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  multiline
                  rows={3}
                  style={{ visibility: messages != null ? 'hidden' : 'visible' }}
                />
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleOk} color="primary" disabled={!text}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

QrCodeDialog.propTypes = {
  config: PropTypes.object,
  onSuccess: PropTypes.func,
  onCancel: PropTypes.func
};

const QrCodeDialogWrapper = ({ open, ...rest }) => {
  if (!open) {
    return null;
  }

  return <QrCodeDialog {...rest} />;
};

QrCodeDialogWrapper.propTypes = {
  open: PropTypes.bool,
};

export default QrCodeDialogWrapper;
