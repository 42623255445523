/* eslint-disable react/jsx-tag-spacing */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-indent */
/* eslint-disable max-len */
import React from 'react';
import { SvgIcon } from '@material-ui/core';
import PropTypes from 'prop-types';

const Icon = ({ style, ...rest }) => {
  return (
    <SvgIcon {...rest} style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinecap: 'round', strokeLinejoin: 'round', strokeMiterlimit: '1.5', ...style }}>
      <g id="IndustrialSensor">
        <g transform="matrix(0.823529,0,0,1.1875,1.70588,-2.84375)">
            <path d="M21,15.868L21,6.215C21,5.268 19.892,4.5 18.527,4.5L6.473,4.5C5.108,4.5 4,5.268 4,6.215L4,18.785C4,19.732 5.108,20.5 6.473,20.5L16.143,20.438" style={{ fill: 'none', stroke: 'currentColor', strokeWidth: '1.96px' }}/>
        </g>
        <g transform="matrix(1.125,0,0,1.25,-1.5,-1.15)">
            <rect x="8" y="6" width="8" height="4" style={{ fill: 'none', stroke: 'currentColor', strokeWidth: '1.05px' }}/>
        </g>
    </g>
    <g transform="matrix(0.113583,0,0,0.113583,8.16277,12.1282)">
        <g id="Layer1">
            <path d="M3,27.5L9,33.5C23.91,18.59 48.09,18.59 63,33.5L69,27.5C50.79,9.29 21.24,9.29 3,27.5ZM27,51.5L36,60.5L45,51.5C40.05,46.52 31.98,46.52 27,51.5ZM15,39.5L21,45.5C29.28,37.22 42.72,37.22 51,45.5L57,39.5C45.42,27.92 26.61,27.92 15,39.5Z" style={{ fillRule: 'nonzero' }}/>
        </g>
    </g>
    </SvgIcon>
  );
};

Icon.propTypes = {
  style: PropTypes.object
};

export default Icon;
