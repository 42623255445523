/* eslint-disable react/jsx-tag-spacing */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-indent */
/* eslint-disable max-len */
import React from 'react';
import { SvgIcon } from '@material-ui/core';
import PropTypes from 'prop-types';

const Icon = ({ style, ...rest }) => {
  return (
    <SvgIcon {...rest} style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinejoin: 'round', strokeMiterlimit: '2', ...style }}>
      <g id="Layer6">
        <g transform="matrix(5.24487e-17,0.856552,-1.38,8.45005e-17,23.525,7.01034)">
          <path d="M3,12.562L3,9.938L11.458,9.938L11.458,7.5L17.5,11.25L11.458,15L11.458,12.562L3,12.562Z" />
        </g>
        <g transform="matrix(5.24487e-17,-0.856552,-1.38,-8.45005e-17,31.525,17.0697)">
          <path d="M3,12.562L3,9.938L11.458,9.938L11.458,7.5L17.5,11.25L11.458,15L11.458,12.562L3,12.562Z" />
        </g>
      </g>
    </SvgIcon>
  );
};

Icon.propTypes = {
  style: PropTypes.object
};

export default Icon;
