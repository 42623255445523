import { useMessageSource } from 'react-message-source';

/**
 * getPrefix message acts like 'react-message-source'
 * getMessage() but knows about a message prefix. If the
 * message can't be resolve only the key is returned as
 * the default and not the prefix.
 */
const usePrefixMessageSource = () => {
  const messageSource = useMessageSource();

  const getPrefixMessage = (prefix, key, params) => {
    if (key == null) {
      return null;
    }
    const combinedKey = `${prefix || ''}${key}`;
    const message = messageSource.getMessage(combinedKey, params);
    return message === combinedKey ? `${key}`.toLowerCase().replace(/[-_]/g, ' ') : message;
  };

  return { getPrefixMessage, ...messageSource };
};

export default usePrefixMessageSource;
