/* eslint-disable no-unused-vars */
import React, { Suspense, useEffect, useState } from 'react';

import { Box, ThemeProvider, CssBaseline, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { grey } from '@material-ui/core/colors';
import PropTypes from 'prop-types';

import 'react-perfect-scrollbar/dist/css/styles.css';
import { BrowserRouter, useRoutes, useLocation, useNavigate } from 'react-router-dom';
// import LuxonUtils from '@date-io/luxon';
import { ErrorBoundary } from 'react-error-boundary';
import { stopReportingRuntimeErrors } from 'react-error-overlay';
// import { FetchingProvider as FetchingMessageSourceProvider } from 'react-message-source';
import { defaultTo } from 'lodash';
import { Chart } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';

import LuxonUtils from 'src/utils/date/CustomLuxonUtils';
import log from 'src/support/Logger';
import initYup from 'src/support/YupLocale';
import DeviceGroupSystemPlugin from 'src/service/DeviceGroupSystemPlugin';
import GlobalStyles from 'src/style/GlobalStyles';
import 'src/style/fix.css';
import 'src/mixins/chartjs';
import defaultTheme from 'src/theme';
import buildRoutes from 'src/routes';
import { SystemProvider, useSystemContext } from 'src/service/System';
import SystemErrorView from 'src/modules/core/views/errors/SystemErrorView';
import SimpleLayout from 'src/layouts/SimpleLayout';
import { CompositionProvider, useCompositionContext } from 'src/composition';
import RefreshingMessageSourceProvider from 'src/components/core/RefreshingMessageSourceProvider';

import VersionSnackbar from 'src/modules/core/views/common/VersionSnackbar';

initYup();

Chart.register(annotationPlugin);

if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_DISABLE_ERROR_OVERLAY === 'true') {
  // Disables error overlays (useful for testing error pages)
  stopReportingRuntimeErrors();
}

const API_URL = process.env.REACT_APP_API_URL;
if (!API_URL) {
  throw new Error('Required environment variable "REACT_APP_API_URL" not found. Set environment variable or add ".env" file.');
}

const COMPOSITION = process.env.REACT_APP_COMPOSITION;
// if (!COMPOSITION) {
//   throw new Error('Required environment variable "REACT_APP_COMPOSITION" not found. Set environment variable or add ".env" file.');
// }

const VERSION = defaultTo(process.env.REACT_APP_VERSION, '0.0.0').trim();

const useStyles = makeStyles((theme) => ({
  loadingBox: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },

  loadingText: {
    marginTop: theme.spacing(2),
    color: grey[600],
    fontSize: '1.25rem'
  }
}));

const LoadingView = () => {
  const classes = useStyles();
  return (
    <SimpleLayout>
      <Box className={classes.loadingBox}>
        <CircularProgress />
        <Box className={classes.loadingText}>
          Loading...
        </Box>
      </Box>
    </SimpleLayout>
  );
};

const ErrorFallback = ({ error }) => {
  // console.log(`Error ${JSON.stringify(error)}`);

  return (
    <SimpleLayout>
      <SystemErrorView />
    </SimpleLayout>
  );
};

ErrorFallback.propTypes = {
  error: PropTypes.object
};

const CompositionAppRoutes = ({ composition }) => {
  const systemService = useSystemContext();

  // return useRoutes(buildRoutes(systemService, composition), process.env.PUBLIC_URL);
  return useRoutes(buildRoutes(systemService, composition));
};

const AppRoutes = () => {
  const composition = useCompositionContext();

  if (composition == null) {
    return <LoadingView />;
  }

  return <CompositionAppRoutes composition={composition} />;
};

const CompositionThemeProvider = ({ children }) => {
  const composition = useCompositionContext();
  return (
    <ThemeProvider theme={composition.options.theme || defaultTheme}>
      {children}
    </ThemeProvider>
  );
};

CompositionThemeProvider.propTypes = {
  children: PropTypes.any
};

const App = () => {
  const [showVersion, setShowVersion] = useState(false);

  const handleError = (error) => {
    log.error('System error.', error);
  };

  const handleVersionMissmatch = (version, remoteVersion) => {
    // console.log(`Heartbeat version ${remoteVersion} does not match version ${version}`);
    setShowVersion(true);
  };

  const requeryMessageUrl = () => {
  };

  return (
    <CompositionProvider name={COMPOSITION}>
      <CompositionThemeProvider>
        <CssBaseline />
        <GlobalStyles />
        <ErrorBoundary FallbackComponent={ErrorFallback} onError={handleError}>
          <Suspense fallback={<LoadingView />}>
            <RefreshingMessageSourceProvider
              url={`${API_URL}/messages`}
              timeout={10000}
            >
              <BrowserRouter basename={process.env.PUBLIC_URL}>
                <MuiPickersUtilsProvider utils={LuxonUtils}>
                  <VersionSnackbar
                    open={showVersion}
                    onClose={() => setShowVersion(false)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                  />
                  <SystemProvider
                    apiUrl={API_URL}
                    version={VERSION}
                    local
                    loadingComponent={LoadingView}
                    onVersionMissmatch={handleVersionMissmatch}
                    plugins={[new DeviceGroupSystemPlugin()]}
                  >
                    <AppRoutes />
                  </SystemProvider>
                </MuiPickersUtilsProvider>
              </BrowserRouter>
            </RefreshingMessageSourceProvider>
          </Suspense>
        </ErrorBoundary>
      </CompositionThemeProvider>
    </CompositionProvider>
  );
};

export default App;
