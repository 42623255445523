/* eslint-disable import/prefer-default-export */
/* eslint-disable no-template-curly-in-string */
import * as Yup from 'yup';
import { stripWhitespace } from 'src/support/YupExtensions';

const HEX_REG_EX = /^[0-9a-f]+$/i;

function ignoreWhitespace() {
  return this.transform(stripWhitespace);
}

function hex(message) {
  return this.matches(HEX_REG_EX, message || 'Invalid HEX value');
}

const initYup = () => {
  Yup.setLocale({
    mixed: {
      required: 'Required',
      notType: ({ type }) => {
        switch (type) {
          case 'number':
            return 'Must be a number';
          case 'string':
            return 'Must be a string';
          default:
            return 'Invalid value';
        }
      }
    },

    number: {
      integer: 'Must be an integer',
      min: 'Must be greater than or equal to ${min}',
      max: 'Must be less than or equal to ${max}',
      moreThan: 'Must be greater than ${more}',
      lessThan: 'Must be greater than ${less}'
    }
  });

  Yup.addMethod(Yup.string, 'hex', hex);

  Yup.addMethod(Yup.string, 'ignoreWhitespace', ignoreWhitespace);
};

export default initYup;
