/* eslint-disable max-len */
import React from 'react';
import { SvgIcon } from '@material-ui/core';

const Icon = (props) => {
  return (
    <SvgIcon {...props}>
      <g id="Layer1" transform="matrix(0.885749,0,0,0.885749,1.39926,1.39926)">
        <g transform="matrix(1,0,0,1,-2,-2)">
          <path d="M9.231,6.368C6.599,8.012 5,10.897 5,14C5,18.967 9.033,23 14,23C18.967,23 23,18.967 23,14C23,9.029 18.971,5 14,5L14,7.18C17.766,7.18 20.82,10.234 20.82,14C20.82,17.764 17.764,20.82 14,20.82C10.236,20.82 7.18,17.764 7.18,14C7.18,11.648 8.392,9.463 10.386,8.216L9.231,6.368Z" />
        </g>
        <g transform="matrix(1.12899,0,0,1.12899,-2.14425,-1.54785)">
          <circle cx="12.5" cy="12" r="3" />
        </g>
        <g transform="matrix(1.12899,0,0,1.12899,-1.57975,-1.57975)">
          <path d="M13,19C13,18.724 12.776,18.5 12.5,18.5L11.5,18.5C11.224,18.5 11,18.724 11,19L11,21.5C11,21.776 11.224,22 11.5,22L12.5,22C12.776,22 13,21.776 13,21.5L13,19Z" />
        </g>
        <g transform="matrix(6.91306e-17,1.12899,-1.12899,6.91306e-17,44.1442,-1.54785)">
          <path d="M13,19C13,18.724 12.776,18.5 12.5,18.5L11.5,18.5C11.224,18.5 11,18.724 11,19L11,21.5C11,21.776 11.224,22 11.5,22L12.5,22C12.776,22 13,21.776 13,21.5L13,19Z" />
        </g>
        <g transform="matrix(0.656653,0.882925,-0.517664,0.384999,10.055,-12.858)">
          <path d="M13,19.353C13,18.882 12.776,18.5 12.5,18.5L11.5,18.5C11.224,18.5 11,18.882 11,19.353L11,21.147C11,21.618 11.224,22 11.5,22L12.5,22C12.776,22 13,21.618 13,21.147L13,19.353Z" />
        </g>
        <g transform="matrix(6.91306e-17,1.12899,-1.12899,6.91306e-17,25.516,-1.54785)">
          <path d="M13,19C13,18.724 12.776,18.5 12.5,18.5L11.5,18.5C11.224,18.5 11,18.724 11,19L11,21.5C11,21.776 11.224,22 11.5,22L12.5,22C12.776,22 13,21.776 13,21.5L13,19Z" />
        </g>
        <g transform="matrix(1.12899,0,0,1.29027,-1.57975,-23.1918)">
          <path d="M13,18.938C13,18.696 12.776,18.5 12.5,18.5L11.5,18.5C11.224,18.5 11,18.696 11,18.938L11,21.563C11,21.804 11.224,22 11.5,22L12.5,22C12.776,22 13,21.804 13,21.563L13,18.938Z" />
        </g>
      </g>
    </SvgIcon>
  );
};

export default Icon;
