/* eslint-disable react/jsx-tag-spacing */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-indent */
/* eslint-disable max-len */
import React from 'react';
import { SvgIcon } from '@material-ui/core';
import PropTypes from 'prop-types';

const Icon = ({ style, ...rest }) => {
  return (
    <SvgIcon {...rest} style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinejoin: 'round', strokeMiterlimit: '2', ...style }}>
      <path d="M14,2L6,2C4.9,2 4.01,2.9 4.01,4L4,20C4,21.1 4.89,22 5.99,22L18,22C19.1,22 20,21.1 20,20L20,8L14,2ZM6,20L6,4L13,4L13,9L18,9L18,20L6,20Z" style={{ fillRule: 'nonzero' }}/>
    <g transform="matrix(0.5,0,0,0.5,6,8.52709)">
        <path d="M15,13L15,5C15,3.34 13.66,2 12,2C10.34,2 9,3.34 9,5L9,13C7.79,13.91 7,15.37 7,17C7,19.76 9.24,22 12,22C14.76,22 17,19.76 17,17C17,15.37 16.21,13.91 15,13ZM11,5C11,4.45 11.45,4 12,4C12.55,4 13,4.45 13,5L13,11L11,11L11,5Z" style={{ fillRule: 'nonzero' }}/>
    </g>
    </SvgIcon>
  );
};

Icon.propTypes = {
  style: PropTypes.object
};

export default Icon;
