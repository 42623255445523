import React from 'react';
import {
  Box,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

import PropTypes from 'prop-types';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  container: {
    boxSizing: 'border-box',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: grey[400],
    borderRadius: 4,
    position: 'relative',
  },

  labelContainer: {
    position: 'absolute',
    // top: -9,
    // left: 8,
    top: -2,
    left: 8,
    height: 2,
    backgroundColor: '#ffffff',
    // borderTop: '2px solid #ffffff',
    // borderTop: '2px solid blue',
  },

  label: {
    fontSize: '0.75rem',
    // backgroundColor: '#FFFFFF',
    marginTop: -8,
    paddingLeft: 6,
    paddingRight: 6,
    zIndex: 0,
  },

  checkboxesContainer: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(2),
    marginRight: -theme.spacing(2)
  },

  checkboxRoot: {
    // marginLeft: theme.spacing(2)
  },

  // containerError: {
  //   '& .UiControlContainer-root': {
  //     borderColor: '#f44336 !important',
  //   },

  //   '& .MuiFormHelperText-root': {
  //     color: '#f44336'
  //   },

  //   '& .UiControlLabel-root': {
  //     color: '#f44336'
  //   }
  // },

  containerError: {
    borderColor: `${theme.palette.error.main} !important`,

    '& .UiControlLabel-root': {
      color: theme.palette.error.main
    }
  },

  helperError: {
    color: theme.palette.error.main
  },
}));

const FormControlContainer = ({
  label,
  required = false,
  fullWidth,
  helperText,
  errorMessage,
  className,
  classes: classesArg,
  children,
  ...rest
}) => {
  const baseClasses = useStyles();
  const classes = classesArg || {};

  const tempHelperText = errorMessage || helperText;

  const hasError = errorMessage != null;

  return (
    <>
      <Box
        className={clsx(
          'UiControlContainer-root',
          baseClasses.container,
          classes.root,
          hasError ? baseClasses.containerError : null,
          className,
        )}
        style={fullWidth ? { width: '100%' } : null}
        {...rest}
      >
        <Box className={baseClasses.labelContainer}>
          <Typography
            variant="body2"
            color="textSecondary"
            className={clsx(
              'UiControlLabel-root',
              baseClasses.label,
              classes.label
            )}
            component="div"
          >
            {label}{required && ' *'}
          </Typography>
        </Box>
        {children}
      </Box>
      {tempHelperText && (
        <p className={clsx('MuiFormHelperText-root', 'MuiFormHelperText-contained', hasError ? baseClasses.helperError : null,)}>
          {tempHelperText}
        </p>
      )}
    </>
  );
};

FormControlContainer.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  fullWidth: PropTypes.bool,
  helperText: PropTypes.string,
  errorMessage: PropTypes.string,
  className: PropTypes.string,
  classes: PropTypes.object,
  children: PropTypes.any
};

export default FormControlContainer;
