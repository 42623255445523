/* eslint-disable react/jsx-tag-spacing */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-indent */
/* eslint-disable max-len */
import React from 'react';
import { SvgIcon } from '@material-ui/core';
import PropTypes from 'prop-types';

const Icon = ({ style, ...rest }) => {
  return (
    <SvgIcon {...rest} style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinejoin: 'round', strokeMiterlimit: '2', ...style }}>
      <path d="M9,11L7,11L7,13L9,13L9,11ZM13,11L11,11L11,13L13,13L13,11ZM17,11L15,11L15,13L17,13L17,11ZM19,4L18,4L18,2L16,2L16,4L8,4L8,2L6,2L6,4L5,4C3.89,4 3.01,4.9 3.01,6L3,20C3,21.1 3.89,22 5,22L19,22C20.1,22 21,21.1 21,20L21,6C21,4.9 20.1,4 19,4ZM19,20L5,20L5,9L19,9L19,20Z" style={{ fillRule: 'nonzero' }}/>
    <g transform="matrix(0.392927,0,0,0.333333,12.2206,12.7736)">
        <path d="M11.8,10.9C9.53,10.31 8.8,9.7 8.8,8.75C8.8,7.66 9.81,6.9 11.5,6.9C13.28,6.9 13.94,7.75 14,9L16.21,9C16.14,7.28 15.09,5.7 13,5.19L13,3L10,3L10,5.16C8.06,5.58 6.5,6.84 6.5,8.77C6.5,11.08 8.41,12.23 11.2,12.9C13.7,13.5 14.2,14.38 14.2,15.31C14.2,16 13.71,17.1 11.5,17.1C9.44,17.1 8.63,16.18 8.52,15L6.32,15C6.44,17.19 8.08,18.42 10,18.83L10,21L13,21L13,18.85C14.95,18.48 16.5,17.35 16.5,15.3C16.5,12.46 14.07,11.49 11.8,10.9Z" style={{ fillRule: 'nonzero' }}/>
    </g>
    </SvgIcon>
  );
};

Icon.propTypes = {
  style: PropTypes.object
};

export default Icon;
