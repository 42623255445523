/* eslint-disable max-len */
import React from 'react';
import { SvgIcon } from '@material-ui/core';

const Icon = (props) => {
  return (
    <SvgIcon {...props}>
      <g id="Humidty" transform="matrix(1,0,0,1,0,-1)">
        <g transform="matrix(1,0,0,1,1,0)">
          <path d="M11,4.514C16.379,10.463 17,12.689 17,16C17,19.311 14.311,22 11,22C7.689,22 5,19.311 5,16C5,12.689 5.854,10.494 11,4.514Z" style={{ fill: 'none', stroke: 'currentColor', strokeWidth: '2px' }} />
        </g>
        <g transform="matrix(0.998681,0,0,0.998681,-0.738125,0.525069)">
          <path d="M12.755,10.489C12.755,10.489 13.756,12.992 15.508,10.489C16.208,12.567 16.51,13.856 16.51,15.245C16.51,17.317 14.827,19 12.755,19C10.683,19 9,17.317 9,15.245C9,13.856 9.439,12.428 10.252,10.489C11.754,12.992 12.755,10.489 12.755,10.489Z" />
        </g>
      </g>
    </SvgIcon>
  );
};

export default Icon;
