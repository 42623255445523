import React, { useState, useRef, useEffect } from 'react';

import {
  Box,
  Checkbox,
  FormControlLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { CancelToken } from 'axios';
import { filter, get, isEqual } from 'lodash';
import clsx from 'clsx';
import { useErrorHandler } from 'react-error-boundary';

import { selectAllStatus, Bucket } from 'src/utils/core';
import { useIsMounted } from 'src/utils/react';
import { useSystemContext } from 'src/service/System';
import DebouncedSearchField from './DebouncedSearchField';

const useStyles = makeStyles((theme) => ({
  menuContainer: {
    height: '100%',
    overflow: 'auto'
  },

  notFoundBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 60
  },

  notFoundText: {
    color: grey[500]
  },

  maxResults: {
    flexShrink: 0,
    paddingTop: 4,
    paddingBottom: 4,
    textAlign: 'center',
    color: grey[700],
    // background: grey[100],
    fontSize: 11,
    backgroundColor: grey[200],
  },

  listItemIcon: {
    minWidth: 40
  },

  listCheckboxRoot: {
    margin: -9
  },

  listItem: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: grey[300]
    }
  },

  listItemSelected: {
    background: grey[100],
  },

  listItemText: {

  },

  listHeader: {
    // borderBottomWidth: 1,
    // borderBottomStyle: 'solid',
    // borderColor: grey[200],
    backgroundColor: grey[50]
  },

  selectAllControlRoot: {
    marginLeft: -theme.spacing(1)
  },

  selectAllControlLabel: {
    marginLeft: theme.spacing(1),
    fontStyle: 'italic',
    fontWeight: 500,
    color: grey[500]
  },

  itemIcon: {
    color: grey[400],
  },
}));

const Menu = ({ idSet, data, onChange, mapper }) => {
  const length = get(data, 'length', 0);

  const classes = useStyles();

  if (!length) {
    return (
      <Box className={classes.notFoundBox}>
        <Typography variant="h5" className={classes.notFoundText}>No items found</Typography>
      </Box>
    );
  }

  // const idSet = selectedIds ? new Set(selectedIds) : new Set();

  const handleClick = (item, selected) => {
    if (onChange) {
      onChange([item], selected);
    }
  };

  return (
    <List>
      {data.map((item) => {
        const mapped = mapper(item);

        const selected = idSet.has(mapped.id);

        const iconNode = (mapped.icon == null || React.isValidElement(mapped.icon))
          ? mapped.icon
          : React.createElement(mapped.icon, { className: classes.itemIcon });

        return (
          <ListItem
            key={item.id}
            classes={{ root: clsx(classes.listItem, selected && classes.listItemSelected) }}
            onClick={() => handleClick(item, !selected)}
          >
            <ListItemIcon classes={{ root: classes.listItemIcon }}>
              <Checkbox
                classes={{ root: classes.listCheckboxRoot }}
                // edge="start"
                checked={selected}
                tabIndex={-1}
                disableRipple
                // inputProps={{ 'aria-labelledby': labelId }}
              />
            </ListItemIcon>
            {iconNode && (
              <ListItemIcon classes={{ root: classes.listItemIcon }}>
                {iconNode}
              </ListItemIcon>
            )}
            <ListItemText
              primaryTypographyProps={{
                style: { display: 'inline-block' }
              }}
              secondaryTypographyProps={{
                style: { display: 'inline-block', marginLeft: 6 }
              }}
              secondary={mapped.secondary}
            >
              {mapped.label}
            </ListItemText>
          </ListItem>
        );
      })}
    </List>
  );
};

Menu.propTypes = {
  idSet: PropTypes.object,
  data: PropTypes.array,
  onChange: PropTypes.func,
  mapper: PropTypes.func.isRequired
};

const MemoizedMenu = React.memo(
  Menu,
  (prev, next) => isEqual(prev.idSet, next.idSet) && isEqual(prev.data, next.data)
);

const Picker = ({
  load,
  mapper,
  selectedIds,
  sessionPrefix,
  setLoading,
  onChange,
  showSelectAll = true,
  maxResults,
  filter: filterArg
}) => {
  const searchSessionKey = sessionPrefix == null ? null : `${sessionPrefix}_SEARCH`;

  // const  = sessionPrefix == null ? null : `${sessionPrefix}_SEARCH`;

  const system = useSystemContext();

  const initialSearchRef = useRef(system.getSessionItem(searchSessionKey, ''));
  const [search, setSearch] = useState(() => initialSearchRef.current);

  const [data, setData] = useState();

  const processIdRef = useRef();
  const cancelSourceRef = useRef(null);
  const previousIdsRef = useRef(new Bucket());

  const classes = useStyles();
  const isMounted = useIsMounted();
  const handleError = useErrorHandler();

  const update = (processId, updateData) => {
    if (!isMounted() || processId !== processIdRef.current) {
      // Ignore if a newer request is pending.
      return;
    }

    cancelSourceRef.current = null;
    setData(filterArg == null ? updateData : filter(updateData, filterArg));
    setLoading(false);
  };

  const performLoad = (searchArg, premiseArg) => {
    previousIdsRef.current.push(processIdRef.current);
    const processId = uuidv4();
    processIdRef.current = processId;
    setLoading(true);

    if (cancelSourceRef.current) {
      cancelSourceRef.current.cancel('Operation cancelled by the user.');
    }

    const source = CancelToken.source();
    cancelSourceRef.current = source;

    load(searchArg, premiseArg, source)
      .then((response) => {
        update(processId, response.data);
      }).catch((error) => {
        // axios.isCancel does not work. __CANCEL__ is not set on the error
        // !axios.isCancel(error)) {
        if (!previousIdsRef.current.includes(processId)) {
          handleError(error);
        }
      });
  };

  useEffect(() => {
    performLoad(search);
  }, []);

  const handleSearchChange = (newSearch) => {
    system.setSessionItem(searchSessionKey, newSearch);
    setSearch(newSearch);
    performLoad(newSearch);
  };

  const handleChange = (items, selected) => {
    if (items && items.length > 0 && onChange) {
      onChange(items, selected);
    }
  };

  const idSet = selectedIds ? new Set(selectedIds) : new Set();

  const selectAll = selectAllStatus(data, (item) => idSet.has(mapper(item).id));

  const handleSelectAll = () => {
    const selected = selectAll !== true;
    const items = filter(data, (item) => (idSet.has(mapper(item).id) !== selected));
    handleChange(items, selected);
  };

  const length = get(data, 'length', 0);

  return (
    <>
      <Box flexShrink={0} pt={1.5} px={2}>
        <DebouncedSearchField initialValue={initialSearchRef.current} onChangeDebounced={handleSearchChange} />
      </Box>
      {(length > 0 && showSelectAll) && (
        <Box flexShrink={0} mb={-1} pt={1.5}>
          <Box className={classes.listHeader} px={2}>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={Boolean(selectAll)}
                  onClick={handleSelectAll}
                  disableRipple
                  indeterminate={selectAll == null}
                />
              )}
              label="Select All"
              classes={{
                root: classes.selectAllControlRoot,
                label: classes.selectAllControlLabel
              }}
            />
          </Box>
        </Box>
      )}
      <Box className={classes.menuContainer} mt={1} mb={1}>
        <MemoizedMenu idSet={idSet} data={data} onChange={handleChange} mapper={mapper} />
      </Box>
      {(maxResults && length >= maxResults) && (
        <Box className={classes.maxResults}>
          Max {maxResults} items shown. Search to narrow results.
        </Box>
      )}
    </>
  );
};

Picker.propTypes = {
  load: PropTypes.func.isRequired,
  mapper: PropTypes.func.isRequired,
  selectedIds: PropTypes.array,
  sessionPrefix: PropTypes.string,
  // loading: PropTypes.bool,
  setLoading: PropTypes.func,
  onChange: PropTypes.func,
  showSelectAll: PropTypes.bool,
  maxResults: PropTypes.number,
  filter: PropTypes.func,
};

export default Picker;
