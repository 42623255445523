/* eslint-disable max-len */
import React from 'react';
import { SvgIcon } from '@material-ui/core';

const Icon = (props) => {
  return (
    <SvgIcon {...props}>
      <g transform="matrix(0.0410156,0,0,0.0410156,1.5,1.5)">
        <path d="M499.81,353.524C499.81,353.524 448.49,319.305 370.145,319.305C365.944,319.305 357.632,321.9 353.524,322.1C360.087,316.769 370.72,308.506 376.986,302.24C432.383,246.843 451.048,170.667 451.048,170.667C451.048,170.667 378.122,192.58 322.723,247.977C312.847,257.853 306.453,269.95 298.857,280.381C302.97,256.985 304.762,234.001 304.762,207.238C304.761,95.134 256,12.19 256,12.19C256,12.19 207.238,95.133 207.238,207.238C207.238,234.001 207.912,256.986 212.024,280.381C204.426,269.95 199.152,257.854 189.278,247.978C133.879,192.58 60.952,170.667 60.952,170.667C60.952,170.667 79.618,246.843 135.015,302.24C141.28,308.505 151.913,316.41 158.476,321.742C154.368,321.543 146.057,319.305 141.856,319.305C63.51,319.304 12.19,353.524 12.19,353.524C12.19,353.524 63.51,392.645 141.855,392.645C159.249,392.645 175.904,390.832 191.302,388.019C190.69,388.608 190.081,389.202 189.477,389.807C152.691,426.593 146.286,475.429 146.286,475.429C146.286,475.429 197.334,471.236 234.12,434.45C235.924,432.646 236.97,428.537 238.636,426.667L231.619,512L280.381,512L272.621,426.667C274.286,428.537 276.076,432.647 277.881,434.451C314.667,471.237 365.714,475.429 365.714,475.429C365.714,475.429 359.31,426.594 322.524,389.808C321.92,389.204 321.311,388.608 320.699,388.02C336.097,390.833 352.752,392.646 370.146,392.646C448.49,392.645 499.81,353.524 499.81,353.524Z" style={{ fillRule: 'nonzero' }} />
      </g>
    </SvgIcon>
  );
};

export default Icon;
