/* eslint-disable react/jsx-tag-spacing */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-indent */
/* eslint-disable max-len */
import React from 'react';
import { SvgIcon } from '@material-ui/core';
import PropTypes from 'prop-types';

const Icon = ({ style, ...rest }) => {
  return (
    <SvgIcon {...rest} style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinecap: 'round', strokeLinejoin: 'round', strokeMiterlimit: '1.5', ...style }}>
      <g id="IndustrialSensor">
        <g transform="matrix(0.823529,0,0,1.1875,1.70588,-2.84375)">
            <path d="M21,6.215C21,5.268 19.892,4.5 18.527,4.5L6.473,4.5C5.108,4.5 4,5.268 4,6.215L4,18.785C4,19.732 5.108,20.5 6.473,20.5L18.527,20.5C19.892,20.5 21,19.732 21,18.785L21,6.215Z" style={{ fill: 'none', stroke: 'currentColor', strokeWidth: '1.96px' }}/>
        </g>
        <g transform="matrix(1,0,0,0.65,-8,3.3)">
            <circle cx="17" cy="17" r="1" style={{ fill: 'none', stroke: 'currentColor', strokeWidth: '0.89px' }}/>
        </g>
        <g transform="matrix(1,0,0,0.65,-5,3.3)">
            <circle cx="17" cy="17" r="1" style={{ fill: 'none', stroke: 'currentColor', strokeWidth: '0.89px' }}/>
        </g>
        <g transform="matrix(1,0,0,0.65,-2,3.3)">
            <circle cx="17" cy="17" r="1" style={{ fill: 'none', stroke: 'currentColor', strokeWidth: '0.89px' }}/>
        </g>
        <g transform="matrix(1.125,0,0,1.25,-1.5,-1.15)">
            <rect x="8" y="6" width="8" height="4" style={{ fill: 'none', stroke: 'currentColor', strokeWidth: '1.05px' }}/>
        </g>
    </g>
    </SvgIcon>
  );
};

Icon.propTypes = {
  style: PropTypes.object
};

export default Icon;
