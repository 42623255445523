/* eslint-disable no-unused-vars */
import React, { useState } from 'react';

import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  makeStyles,
  useTheme
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import clsx from 'clsx';
import { defaultTo, get } from 'lodash';

import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/themes/prism.css';

import { FormControlContainer } from 'src/components/form';

const useStyles = makeStyles((theme) => ({
  root: {
    '&:hover': {
      border: '1px solid black'
    }
  },

  rootFocus: {
    margin: -1,
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: theme.palette.primary.main
  },

  labelFocus: {
    color: theme.palette.primary.main,
  },

  textarea: {
    paddingLeft: '40px !important',
    '&:focus': {
      outline: 'none !important'
    }
  },

  pre: {
    paddingLeft: '40px !important',
  },

  editor: {
    counterReset: 'line'
  },

  editorLineNumber: {
    position: 'absolute',
    left: 0,
    color: grey[400],
    textAlign: 'right',
    width: 30,
    fontWeight: 100
  }
}));

const FormEditor = ({
  label,
  id,
  name,
  value,
  fullWidth,
  errorMessage,
  helperText,
  disabled,
  margin,
  required,
  classes: classesArg,
  onValueChange,
  onFocus,
  onBlur,
  fontSize = 14,
  style,
  ...rest
}) => {
  const [focus, setFocus] = useState(false);
  // const controlId = id || uuid();
  // const labelId = `${controlId}-label`;

  const baseClasses = useStyles();
  const classes = classesArg || {};

  const theme = useTheme();

  const hightlightWithLineNumbers = (input, language) => (
    highlight(input, language)
      .split('\n')
      .map((line, i) => `<span class='${baseClasses.editorLineNumber}'>${i + 1}</span>${line}`)
      .join('\n')
  );

  return (
    <FormControlContainer
      label={label}
      fullWidth={fullWidth}
      required={required}
      errorMessage={errorMessage}
      helperText={helperText}
      classes={{
        ...classes,
        root: clsx(
          focus ? baseClasses.rootFocus : baseClasses.root,
          classes.root
        ),
        label: clsx(
          focus ? baseClasses.labelFocus : null,
          classes.label
        ),
      }}
    >
      <Box px={1} py={1}>
        <Editor
          value={value}
          onValueChange={onValueChange}
          highlight={(code) => hightlightWithLineNumbers(code, languages.js)}
          padding={theme.spacing(1)}
          style={{
            fontFamily: '"Fira code", "Fira Mono", monospace',
            fontSize,
            ...style
          }}
          className={baseClasses.editor}
          textareaClassName={clsx(baseClasses.textarea, classes.textarea)}
          preClassName={baseClasses.pre}
          onFocus={(event) => {
            setFocus(true);
            if (onFocus) {
              onFocus(event);
            }
          }}
          onBlur={(event) => {
            setFocus(false);
            if (onBlur) {
              onBlur(event);
            }
          }}
          {...rest}
        />
      </Box>
    </FormControlContainer>
  );
};

FormEditor.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.any,
  errorMessage: PropTypes.string,
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  margin: PropTypes.string,
  required: PropTypes.bool,
  classes: PropTypes.object,
  fontSize: PropTypes.number,
  onValueChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  style: PropTypes.object
};

export default FormEditor;
