/* eslint-disable react/jsx-tag-spacing */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-indent */
/* eslint-disable max-len */
import React from 'react';
import { SvgIcon } from '@material-ui/core';
import PropTypes from 'prop-types';

const Icon = ({ style, ...rest }) => {
  return (
    <SvgIcon {...rest} style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinejoin: 'round', strokeMiterlimit: '2', ...style }}>
      <g id="Layer4">
        <g transform="matrix(0.888889,0,0,0.888889,0.888889,-0.222222)">
          <path d="M12.5,2.5C17.467,2.5 21.5,6.533 21.5,11.5C21.5,16.467 17.467,20.5 12.5,20.5C7.533,20.5 3.5,16.467 3.5,11.5C3.5,6.533 7.533,2.5 12.5,2.5ZM12.5,4.548C16.337,4.548 19.452,7.663 19.452,11.5C19.452,15.337 16.337,18.452 12.5,18.452C8.663,18.452 5.548,15.337 5.548,11.5C5.548,7.663 8.663,4.548 12.5,4.548Z" />
        </g>
        <rect x="10" y="17.5" width="4" height="2" />
        <path d="M17,20C17,19.724 16.776,19.5 16.5,19.5L7.5,19.5C7.224,19.5 7,19.724 7,20L7,21C7,21.276 7.224,21.5 7.5,21.5L16.5,21.5C16.776,21.5 17,21.276 17,21L17,20Z" />
        <g transform="matrix(1.14286,0,0,1.14286,-2.57143,-2.28571)">
          <circle cx="12.75" cy="10.75" r="1.75" />
        </g>
        <g transform="matrix(0.785631,-0.785631,0.942809,0.942809,-7.81221,10.4846)">
          <path d="M17,10.25C17,9.836 16.597,9.5 16.1,9.5L13.4,9.5C12.903,9.5 12.5,9.836 12.5,10.25C12.5,10.664 12.903,11 13.4,11L16.1,11C16.597,11 17,10.664 17,10.25Z" />
        </g>
      </g>
    </SvgIcon>
  );
};

Icon.propTypes = {
  style: PropTypes.object
};

export default Icon;
