/* eslint-disable react/jsx-tag-spacing */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-indent */
/* eslint-disable max-len */
import React from 'react';
import { SvgIcon } from '@material-ui/core';
import PropTypes from 'prop-types';

const Icon = ({ style, ...rest }) => {
  return (
    <SvgIcon {...rest} style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinejoin: 'round', strokeMiterlimit: '2', ...style }}>
      <g transform="matrix(0.793103,-9.71272e-17,9.71272e-17,0.793103,4.54311,5.55172)">
        <g id="Layer6">
            <g>
                <g transform="matrix(3.80063e-17,0.62069,-0.999998,6.12322e-17,23.25,11.1379)">
                    <path d="M3,12.562L3,9.938L11.458,9.938L11.458,7.5L17.5,11.25L11.458,15L11.458,12.562L3,12.562Z"/>
                </g>
                <g transform="matrix(3.80063e-17,-0.62069,-0.999998,-6.12322e-17,28.25,18.3621)">
                    <path d="M3,12.562L3,9.938L11.458,9.938L11.458,7.5L17.5,11.25L11.458,15L11.458,12.562L3,12.562Z"/>
                </g>
            </g>
        </g>
    </g>
    <g transform="matrix(1,0,0,1,-2.36364,-4.44089e-16)">
        <g id="Layer7">
            <path d="M12,22L7.455,22C6.455,22 5.636,21.182 5.636,20.182L5.636,3.818C5.636,2.818 6.455,2 7.455,2L16.545,2C17.545,2 18.364,2.818 18.364,3.818L18.364,12L16.545,12L16.545,5.636L7.455,5.636L7.455,18.364L12,18.364L12,22Z" style={{ fillRule: 'nonzero' }}/>
        </g>
    </g>
    </SvgIcon>
  );
};

Icon.propTypes = {
  style: PropTypes.object
};

export default Icon;
