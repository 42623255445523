import React from 'react';

import { Box, Typography } from '@material-ui/core';

import PropTypes from 'prop-types';
import { get, pad } from 'lodash';
import numeral from 'numeral';

import ValueBox from './ValueBox';

const ValueNumberBox = ({
  value,
  decimals,
  format,
  units,
  classes,
  ...rest
}) => {
  let formatted = null;
  if (value == null) {
    if (decimals) {
      formatted = `--.${pad(null, decimals, '-')}`;
    } else {
      formatted = '--';
    }
  } else if (format != null) {
    formatted = numeral(value).format(format);
  } else if (decimals != null) {
    formatted = value.toFixed(decimals);
  } else {
    formatted = value;
  }

  return (
    <ValueBox classes={classes} {...rest}>
      <Box display="flex" justifyContent="center" alignItems="top">
        <Box>
          {formatted}
        </Box>
        {units && (
          <Box ml={0.5} mt={0.25}>
            <Typography variant="body2" component="div" className={get(classes, 'units')}>{units}</Typography>
          </Box>
        )}
      </Box>
    </ValueBox>
  );
};

ValueNumberBox.propTypes = {
  value: PropTypes.number,
  decimals: PropTypes.number,
  units: PropTypes.string,
  format: PropTypes.string,
  classes: PropTypes.object,
};

export default ValueNumberBox;
