/* eslint-disable no-unused-vars */
import React, { useState } from 'react';

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  makeStyles
} from '@material-ui/core';
import {
  Close as CloseIcon,
  ChevronRight as ChevronRightIcon,
  ChevronLeft as ChevronLeftIcon
} from '@material-ui/icons';
import { grey } from '@material-ui/core/colors';

import PropTypes from 'prop-types';
import { concat, indexOf } from 'lodash';
import clsx from 'clsx';

import { FormProgress } from 'src/components/form';
import AuthImage from 'src/components/core/AuthImage';

const useStyles = makeStyles((theme) => ({
  dialog: {
    height: '100%',
    backgroundColor: grey[700]
  },

  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: 2,
    color: grey[50],
  },

  navButton: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    color: grey[200],
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'rgba(125, 125, 125, 0.5)',
    backgroundColor: 'rgba(75, 75, 75, 0.5)',
    '&:hover': {
      backgroundColor: 'rgba(50, 50, 50, 0.5)'
    },
    '& .MuiSvgIcon-root': {
      fontSize: 48
    }
  },

  leftButton: {
    left: theme.spacing(1),
  },

  rightButton: {
    right: theme.spacing(1),
  },

  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },

  image: {
    maxHeight: '100%',
    maxWidth: '100%',
  },

  progress: {
    color: grey[600]
  }
}));

const ImageViewerComponent = ({ title, onCancel, image, images, ...rest }) => {
  const [currentImage, setCurrentImage] = useState(image);
  const imagesArray = concat([], images || []);

  let prev = -1;
  let next = -1;
  if (imagesArray.length > 1) {
    const imageIndex = indexOf(images, currentImage);
    if (imageIndex === -1) {
      prev = 0;
      next = 0;
    } else {
      prev = (imageIndex > 0) ? imageIndex - 1 : images.length - 1;
      next = (imageIndex < images.length - 1) ? imageIndex + 1 : 0;
    }
  }

  const classes = useStyles();

  const performCancel = () => {
    if (onCancel) {
      onCancel();
    }
  };

  return (
    <Dialog
      open
      onClose={performCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paper: classes.dialog }}
      fullWidth
      maxWidth="md"
      {...rest}
    >
      {onCancel && (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onCancel}>
          <CloseIcon />
        </IconButton>
      )}
      {(prev !== -1) && (
        <IconButton
          aria-label="previous"
          className={clsx(classes.navButton, classes.leftButton)}
          onClick={() => { setCurrentImage(images[prev]); }}
          size="small"
        >
          <ChevronLeftIcon />
        </IconButton>
      )}
      {(next !== -1) && (
        <IconButton
          aria-label="next"
          className={clsx(classes.navButton, classes.rightButton)}
          onClick={() => { setCurrentImage(images[next]); }}
          size="small"
        >
          <ChevronRightIcon />
        </IconButton>
      )}
      {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle> }
      <DialogContent className={classes.imageContainer}>
        {currentImage && (
          <AuthImage
            src={`/files/${currentImage}`}
            className={classes.image}
            loadingComponent={() => <CircularProgress className={classes.progress} />}
          />
        )}
      </DialogContent>
      {/* <DialogActions>
        <Button autoFocus onClick={performCancel} color="primary" disabled={loading}>
          Cancel
        </Button>
        <Button onClick={performConfirm} color="primary" disabled={loading}>
          Ok
        </Button>
      </DialogActions> */}
    </Dialog>
  );
};

ImageViewerComponent.propTypes = {
  title: PropTypes.string,
  onCancel: PropTypes.func,
  image: PropTypes.string,
  images: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
};

const ImageViewerDialog = ({ open, ...rest }) => {
  if (!open) {
    return null;
  }

  return <ImageViewerComponent {...rest} />;
};

ImageViewerDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  onCancel: PropTypes.func,
  image: PropTypes.string,
  images: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
};

export default ImageViewerDialog;
