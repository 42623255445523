import React from 'react';

import {
  Box,
  makeStyles,
} from '@material-ui/core';

import { grey } from '@material-ui/core/colors';

import {
  ErrorOutline as ErrorIcon,
  ReportProblemOutlined as WarningIcon,
  InfoOutlined as InfoIcon,
  CheckCircleOutline as SuccessIcon
} from '@material-ui/icons';

import PropTypes from 'prop-types';
import { get } from 'lodash';
import clsx from 'clsx';

const SEVERITY_ICONS = {
  info: InfoIcon,
  success: SuccessIcon,
  warning: WarningIcon,
  error: ErrorIcon
};

const useStyles = makeStyles((theme) => ({
  detailContainer: {
    display: 'flex',
    alignItems: (props) => props.alignItems
  },

  detailLabel: {
    // flex: '0 0 130px',
    flexBasis: (props) => props.labelWidth,
    maxWidth: (props) => props.labelMaxWidth,
    fontWeight: 500,
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center',

    '& > .MuiSvgIcon-root': {
      fontSize: 24,
      color: grey[800],
      marginRight: theme.spacing(1)
    }
    // overflow: 'hidden',
    // textOverflow: 'ellipsis',
  },

  detailItem: {
    marginLeft: theme.spacing(2),
    display: 'flex',
    minWidth: 0,
    alignItems: 'center',
    '& > .MuiSvgIcon-root': {
      fontSize: 24,
      marginRight: theme.spacing(1)
    },
  },

  detailContent: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingRight: theme.spacing(0.5)
  },

  infoSeverity: {
    color: theme.palette.info.dark,
    '& .MuiSvgIcon-root': {
      color: theme.palette.info.main,
    }
  },

  successSeverity: {
    color: theme.palette.success.dark,
    '& .MuiSvgIcon-root': {
      color: theme.palette.success.main,
    }
  },

  warningSeverity: {
    color: theme.palette.warning.dark,
    '& .MuiSvgIcon-root': {
      color: theme.palette.warning.main,
    }
  },

  errorSeverity: {
    color: theme.palette.error.dark,
    '& .MuiSvgIcon-root': {
      color: theme.palette.error.main,
    }
  },

  valueIcon: {
    color: grey[400]
  }
}));

const DetailItem = ({
  label,
  alignItems = 'center',
  // labelWidth = 100,
  labelWidth = '30%',
  labelMaxWidth = 180,
  shrinkLabel = false,
  noWrap = false,
  hideEmpty = false,
  classes,
  containerComponent = Box,
  containerProps,
  labelIcon,
  valueIcon,
  severity: severityArg,
  severityIcon = false,
  seperator = ':',
  title,
  endAdornment,
  children
}) => {
  const baseClasses = useStyles({ labelWidth, labelMaxWidth, alignItems });

  if (hideEmpty && !children) {
    return null;
  }

  const severity = (severityArg || '').toLowerCase();

  const labelIconNode = (labelIcon == null || React.isValidElement(labelIcon))
    ? labelIcon
    : React.createElement(valueIcon, { className: baseClasses.labelIcon });

  let valueIconNode = (valueIcon == null || React.isValidElement(valueIcon))
    ? valueIcon
    : React.createElement(valueIcon, { className: baseClasses.valueIcon });

  if (!valueIconNode && severityIcon && severity && children) {
    valueIconNode = React.createElement(SEVERITY_ICONS[severity]);
  }

  return React.createElement(
    containerComponent,
    {
      title,
      className: clsx(baseClasses.detailContainer, get(classes, 'root')),
      ...containerProps
    }, (
      <>
        <Box className={clsx(baseClasses.detailLabel, get(classes, 'label'))} style={{ flexShrink: shrinkLabel ? 1 : 0 }}>
          {labelIconNode}
          {label}{seperator}
        </Box>
        <Box
          className={clsx(
            baseClasses.detailItem,
            severity == null ? null : get(baseClasses, `${severity}Severity`),
          )}
          style={noWrap ? { whiteSpace: 'nowrap' } : null}
        >
          {valueIconNode}
          <Box className={clsx(baseClasses.detailContent, get(classes, 'value'))}>
            {children}
          </Box>
        </Box>
        {endAdornment && (
          <Box>
            {endAdornment}
          </Box>
        )}
      </>
    )
  );
};

DetailItem.propTypes = {
  label: PropTypes.string,
  labelWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  labelMaxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  alignItems: PropTypes.string,
  shrinkLabel: PropTypes.bool,
  noWrap: PropTypes.bool,
  hideEmpty: PropTypes.bool,
  classes: PropTypes.object,
  containerComponent: PropTypes.object,
  containerProps: PropTypes.object,
  severity: PropTypes.string,
  labelIcon: PropTypes.oneOfType([PropTypes.element, PropTypes.elementType]),
  valueIcon: PropTypes.oneOfType([PropTypes.element, PropTypes.elementType]),
  severityIcon: PropTypes.bool,
  seperator: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.any,
  endAdornment: PropTypes.any,
};

export default DetailItem;
