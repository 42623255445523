import React from 'react';

import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { defaultTo, get } from 'lodash';

import FormSelect from './FormSelect';

const FormikSelect = ({ name, onChange, ...rest }) => {
  const {
    values,
    errors,
    touched,
    submitCount,
    handleBlur,
    handleChange
  } = useFormikContext();

  const errorText = (submitCount > 0 || get(touched, name)) ? get(errors, name) : null;

  const performChange = (...args) => {
    handleChange(...args);
    if (onChange) {
      onChange(...args);
    }
  };

  return (
    <FormSelect
      name={name}
      onBlur={handleBlur}
      onChange={performChange}
      errorText={errorText}
      value={defaultTo(get(values, name), '')}
      {...rest}
    />
  );
};

FormikSelect.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func
};

export default FormikSelect;
