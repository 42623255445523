/* eslint-disable react/jsx-tag-spacing */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-indent */
/* eslint-disable max-len */
import React from 'react';
import { SvgIcon } from '@material-ui/core';
import PropTypes from 'prop-types';

const Icon = ({ style, ...rest }) => {
  return (
    <SvgIcon {...rest} style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinecap: 'round', strokeLinejoin: 'round', strokeMiterlimit: '1.5', ...style }}>
      <g id="IndustrialSensor" transform="matrix(0.768005,0,0,0.768005,4.69598,4.69598)">
        <g transform="matrix(1,0,0,0.965812,-1,-1.34616)">
          <path d="M21,6.827C21,5.543 19.993,4.5 18.752,4.5L6.248,4.5C5.007,4.5 4,5.543 4,6.827L4,18.173C4,19.457 5.007,20.5 6.248,20.5L18.752,20.5C19.993,20.5 21,19.457 21,18.173L21,6.827Z" style={{ fill: 'none', stroke: 'currentColor', strokeWidth: '2.65px' }} />
        </g>
        <g transform="matrix(0.73515,0,0,0.73515,4.2376,2.7376)">
          <circle cx="17" cy="17" r="1" />
        </g>
        <g transform="matrix(0.73515,0,0,0.73515,4.2376,-6.2327)">
          <circle cx="17" cy="17" r="1" />
        </g>
        <g transform="matrix(0.73515,0,0,0.73515,-6.2327,-6.2327)">
          <circle cx="17" cy="17" r="1" />
        </g>
        <g transform="matrix(0.73515,0,0,0.73515,-6.2327,2.7376)">
          <circle cx="17" cy="17" r="1" />
        </g>
        <g transform="matrix(1,0,0,0.333333,-0.5,14.3333)">
          <path d="M14.5,18.5C14.5,17.672 14.276,17 14,17L10,17C9.724,17 9.5,17.672 9.5,18.5L9.5,21.5C9.5,22.328 9.724,23 10,23L14,23C14.276,23 14.5,22.328 14.5,21.5L14.5,18.5Z" />
        </g>
        <g transform="matrix(1,0,0,1,-0.5,0)">
          <path d="M14,18.75C14,18.336 13.664,18 13.25,18L10.75,18C10.336,18 10,18.336 10,18.75L10,20.25C10,20.664 10.336,21 10.75,21L13.25,21C13.664,21 14,20.664 14,20.25L14,18.75Z" />
        </g>
        <g transform="matrix(2,0,0,1,-23,-3.55271e-15)">
          <path d="M22.5,12.5C22.5,12.224 22.388,12 22.25,12L21.75,12C21.612,12 21.5,12.224 21.5,12.5L21.5,15.5C21.5,15.776 21.612,16 21.75,16L22.25,16C22.388,16 22.5,15.776 22.5,15.5L22.5,12.5Z" />
        </g>
      </g>
      <g transform="matrix(0.768005,0,0,0.741749,-0.0720206,-0.33787)">
        <g id="Group">
          <clipPath id="_clip1">
            <path d="M1.396,3.152L1.396,24.722L6.604,24.722L6.604,7.196L23.531,7.196L23.531,3.152L1.396,3.152Z" />
          </clipPath>
          <g clipPath="url(#_clip1)">
            <path d="M21,6.827C21,5.543 19.993,4.5 18.752,4.5L6.248,4.5C5.007,4.5 4,5.543 4,6.827L4,18.173C4,19.457 5.007,20.5 6.248,20.5L18.752,20.5C19.993,20.5 21,19.457 21,18.173L21,6.827Z" style={{ fill: 'none', stroke: 'currentColor', strokeWidth: '2.65px' }} />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

Icon.propTypes = {
  style: PropTypes.object
};

export default Icon;
