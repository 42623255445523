import React from 'react';

import { Fade, LinearProgress, makeStyles, } from '@material-ui/core';

import PropTypes from 'prop-types';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  root: {
    borderTopLeftRadius: (props) => (props.radius ? 4 : 0),
    borderTopRightRadius: (props) => (props.radius ? 4 : 0),
    position: 'absolute',
    top: 0,
    height: 4,
    width: '100%'
  }
}));

const FormProgress = ({ radius = true, className, ...rest }) => {
  const baseClasses = useStyles({ radius });

  return (
    <Fade in timeout={1000}>
      <LinearProgress className={clsx(className, baseClasses.root)} {...rest} />
    </Fade>
  );
};

FormProgress.propTypes = {
  radius: PropTypes.bool,
  className: PropTypes.string
};

export default FormProgress;
