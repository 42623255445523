/* eslint-disable react/jsx-tag-spacing */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-indent */
/* eslint-disable max-len */
import React from 'react';
import { SvgIcon } from '@material-ui/core';
import PropTypes from 'prop-types';

const Icon = ({ style, ...rest }) => {
  return (
    <SvgIcon {...rest} style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinecap: 'round', strokeLinejoin: 'round', strokeMiterlimit: '1.5', ...style }}>
      <g transform="matrix(1,0,0,1,0,-0.75)">
        <path d="M19.75,20.125C19.75,19.78 19.47,19.5 19.125,19.5L4.875,19.5C4.53,19.5 4.25,19.78 4.25,20.125L4.25,21.375C4.25,21.72 4.53,22 4.875,22L19.125,22C19.47,22 19.75,21.72 19.75,21.375L19.75,20.125ZM17.5,20C17.914,20 18.25,20.336 18.25,20.75C18.25,21.164 17.914,21.5 17.5,21.5C17.086,21.5 16.75,21.164 16.75,20.75C16.75,20.336 17.086,20 17.5,20ZM12,20C12.414,20 12.75,20.336 12.75,20.75C12.75,21.164 12.414,21.5 12,21.5C11.586,21.5 11.25,21.164 11.25,20.75C11.25,20.336 11.586,20 12,20ZM9.25,20C9.664,20 10,20.336 10,20.75C10,21.164 9.664,21.5 9.25,21.5C8.836,21.5 8.5,21.164 8.5,20.75C8.5,20.336 8.836,20 9.25,20ZM6.5,20C6.914,20 7.25,20.336 7.25,20.75C7.25,21.164 6.914,21.5 6.5,21.5C6.086,21.5 5.75,21.164 5.75,20.75C5.75,20.336 6.086,20 6.5,20ZM14.75,20C15.164,20 15.5,20.336 15.5,20.75C15.5,21.164 15.164,21.5 14.75,21.5C14.336,21.5 14,21.164 14,20.75C14,20.336 14.336,20 14.75,20Z"/>
    </g>
    <g id="Gateway" transform="matrix(1,0,0,1,0,-4)">
        <g transform="matrix(0.947368,0,0,0.928571,1.10526,0.5)">
            <path d="M21,15.75C21,14.784 20.231,14 19.285,14L3.715,14C2.769,14 2,14.784 2,15.75L2,19.25C2,20.216 2.769,21 3.715,21L19.285,21C20.231,21 21,20.216 21,19.25L21,15.75Z" style={{ fill: 'none', stroke: 'currentColor', strokeWidth: '2.13px' }}/>
        </g>
        <g transform="matrix(1,0,0,1,2.75,-0.75)">
            <circle cx="15.25" cy="16.75" r="0.75"/>
        </g>
        <g transform="matrix(1,0,0,1,0.25,-0.75)">
            <circle cx="15.25" cy="16.75" r="0.75"/>
        </g>
        <g transform="matrix(1,0,0,1,-2.25,-0.75)">
            <circle cx="15.25" cy="16.75" r="0.75"/>
        </g>
        <g transform="matrix(1,0,0,0.894737,0,1.42105)">
            <path d="M6,4.838C6,4.376 5.664,4 5.25,4C4.836,4 4.5,4.376 4.5,4.838L4.5,12.662C4.5,13.124 4.836,13.5 5.25,13.5C5.664,13.5 6,13.124 6,12.662L6,4.838Z"/>
        </g>
        <g transform="matrix(1,0,0,0.894737,13.5,1.42105)">
            <path d="M6,4.838C6,4.376 5.664,4 5.25,4C4.836,4 4.5,4.376 4.5,4.838L4.5,12.662C4.5,13.124 4.836,13.5 5.25,13.5C5.664,13.5 6,13.124 6,12.662L6,4.838Z"/>
        </g>
        <g transform="matrix(0.107164,0,0,0.107164,8.17851,5.01658)">
            <path d="M3,27.5L9,33.5C23.91,18.59 48.09,18.59 63,33.5L69,27.5C50.79,9.29 21.24,9.29 3,27.5ZM27,51.5L36,60.5L45,51.5C40.05,46.52 31.98,46.52 27,51.5ZM15,39.5L21,45.5C29.28,37.22 42.72,37.22 51,45.5L57,39.5C45.42,27.92 26.61,27.92 15,39.5Z" style={{ fillRule: 'nonzero' }}/>
        </g>
    </g>
    </SvgIcon>
  );
};

Icon.propTypes = {
  style: PropTypes.object
};

export default Icon;
