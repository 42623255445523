import React from 'react';

import ErrorView from './ErrorView';

const SystemErrorView = () => {
  return (
    <ErrorView title="Error">
      A system error has occurred. Contact support for assistance.
    </ErrorView>
  );
};

export default SystemErrorView;
