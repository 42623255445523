/* eslint-disable max-len */
import React from 'react';
import { SvgIcon } from '@material-ui/core';

const Icon = (props) => {
  return (
    <SvgIcon {...props}>
      <g id="HomeSensor">
        <g transform="matrix(1.05882,0,0,1.125,-1.23529,-2.0625)">
          <path d="M21,6.827C21,5.543 19.892,4.5 18.527,4.5L6.473,4.5C5.108,4.5 4,5.543 4,6.827L4,18.173C4,19.457 5.108,20.5 6.473,20.5L18.527,20.5C19.892,20.5 21,19.457 21,18.173L21,6.827Z" style={{ fill: 'none', stroke: 'currentColor', strokeWidth: '1.83px' }} />
        </g>
        <g transform="matrix(1.11111,0,0,1.11111,-1.44444,-1.22222)">
          <circle cx="15.25" cy="8.75" r="2.25" style={{ fill: 'none', stroke: 'currentColor', strokeWidth: '1.35px' }} />
        </g>
        <g transform="matrix(0.75,0,0,0.75,1.8125,3.50427)">
          <g transform="matrix(2,0,0,2,-5.75,-14.0057)">
            <circle cx="6.5" cy="13.5" r="0.5" />
          </g>
          <g transform="matrix(2,0,0,2,-5.75,-8.14401)">
            <circle cx="6.5" cy="13.5" r="0.5" />
          </g>
          <g transform="matrix(2,0,0,2,-7.75,-11.0057)">
            <circle cx="6.5" cy="13.5" r="0.5" />
          </g>
          <g transform="matrix(2,0,0,2,-1.75,-14.0057)">
            <circle cx="6.5" cy="13.5" r="0.5" />
          </g>
          <g transform="matrix(2,0,0,2,-1.75,-8.14401)">
            <circle cx="6.5" cy="13.5" r="0.5" />
          </g>
          <g transform="matrix(2,0,0,2,-3.75,-11.0057)">
            <circle cx="6.5" cy="13.5" r="0.5" />
          </g>
          <g transform="matrix(2,0,0,2,2.25,-14.0057)">
            <circle cx="6.5" cy="13.5" r="0.5" />
          </g>
          <g transform="matrix(2,0,0,2,2.25,-8.14401)">
            <circle cx="6.5" cy="13.5" r="0.5" />
          </g>
          <g transform="matrix(2,0,0,2,0.25,-11.0057)">
            <circle cx="6.5" cy="13.5" r="0.5" />
          </g>
          <g transform="matrix(2,0,0,2,4.25,-11.0057)">
            <circle cx="6.5" cy="13.5" r="0.5" />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default Icon;
