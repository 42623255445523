import React from 'react';

import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

import { useLocation } from 'src/components/Router';

import TopBar from './TopBar';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },

  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: (props) => props.showTopBar ? 64 : 0
  },

  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },

  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));

const SimpleLayout = ({ children }) => {

  const location = useLocation();
  const showTopBar = new URLSearchParams(location.search).get('topbar') !== "false";

  const classes = useStyles({ showTopBar });

  return (
    <div className={classes.root}>
      {showTopBar && (
        <TopBar />
      )}
      <div className={classes.wrapper}>
        {/* <div className={classes.contentContainer}> */}
        <div className={classes.content}>
          {children}
        </div>
        {/* </div> */}
      </div>
    </div>
  );
};

SimpleLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SimpleLayout;
