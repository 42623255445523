import { isArrayLike } from 'lodash';

export default (options, value) => {
  if (value == null || !isArrayLike(options)) {
    return null;
  }

  const option = options.find((e) => e.value === value || e.id === value);

  if (!option) {
    return null;
  }

  return option.label || option.name;
};
