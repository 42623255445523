/* eslint-disable react/jsx-tag-spacing */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-indent */
/* eslint-disable max-len */
import React from 'react';
import { SvgIcon } from '@material-ui/core';
import PropTypes from 'prop-types';

const Icon = ({ style, ...rest }) => {
  return (
    <SvgIcon {...rest} style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinejoin: 'round', strokeMiterlimit: '2', ...style }}>
      <g id="Soil" transform="matrix(1.16464,0,0,1.16464,-1.97565,0.618849)">
        <g transform="matrix(0.277778,0,0,-0.277778,2,19.954)">
            <path d="M48.03,21.5L23.97,21.5L24,21.5L23.97,21.5C21,21.47 18,24.47 18,27.47L18,43.94L28.5,54.5L28.5,63.5L43.5,63.5L43.5,54.5L54,43.97L54,27.47C54,24.47 51,21.47 48.03,21.5Z" style={{ fillRule: 'nonzero' }}/>
        </g>
        <rect x="8.5" y="13.765" width="1.5" height="3.735"/>
        <g transform="matrix(1,0,0,1,5.5,1.77636e-15)">
            <rect x="8.5" y="13.765" width="1.5" height="3.735"/>
        </g>
        <g transform="matrix(1,0,0,1,2.75,1.77636e-15)">
            <rect x="8.5" y="13.765" width="1.5" height="3.735"/>
        </g>
    </g>
    </SvgIcon>
  );
};

Icon.propTypes = {
  style: PropTypes.object
};

export default Icon;
