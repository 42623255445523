/* eslint-disable max-len */
import React from 'react';
import { SvgIcon } from '@material-ui/core';

const Icon = (props) => {
  return (
    <SvgIcon {...props}>
      <g id="Status">
        <g transform="matrix(0.9,0,0,0.9,2.2,1.2)">
          <path d="M3.254,16.848C5.016,20.027 8.365,22 12,22C17.519,22 22,17.519 22,12C22,6.481 17.519,2 12,2C6.477,2 2,6.477 2,12L3.972,12C3.972,7.566 7.566,3.972 12,3.972C16.431,3.972 20.028,7.569 20.028,12C20.028,16.431 16.431,20.028 12,20.028C9.082,20.028 6.394,18.444 4.979,15.892L3.254,16.848Z" />
        </g>
        <g transform="matrix(1.07143,0,0,1,0.857143,1)">
          <path d="M1.533,11L8,11L9.933,8L12.267,14L14,11L16,11" style={{ fill: 'none', stroke: 'currentColor', strokeWidth: '1.93px' }} />
        </g>
      </g>
    </SvgIcon>
  );
};

export default Icon;
