/* eslint-disable no-unused-vars */
/* eslint-disable no-template-curly-in-string */
/* eslint-disable import/prefer-default-export */
import * as Yup from 'yup';
import MixedSchema from 'yup/lib/mixed';
import isAbsent from 'yup/lib/util/isAbsent';
// import locale from 'yup/lib/locale';
import { isString } from 'lodash';
import { DateTime } from 'luxon';
// import initYup from './YupLocale';

// const _locale = require('yup/lib/locale');

// initYup();

const DEFAULT_FORMAT = 'LLL dd, yyyy';

const stripWhitespace = (value) => {
  if (!isString(value)) {
    return value;
  }

  return value.replace(/\s/g, '');
};

const requiredInteger = (minValue, maxValue) => {
  let retValue = Yup
    .number()
    .integer()
    .nullable()
    .required();

  if (minValue != null) {
    retValue = retValue.min(minValue);
  }

  if (maxValue != null) {
    retValue = retValue.max(maxValue);
  }

  return retValue;
};

class LuxonDateTimeSchema extends MixedSchema {
  static create() {
    return LuxonDateTimeSchema();
  }

  constructor() {
    super();
    this._validFormat = DEFAULT_FORMAT;

    this.withMutation(() => {
      // eslint-disable-next-line func-names
      this.transform(function (value) {
        if (this.isType(value)) {
          return value;
        }

        if (DateTime.isDateTime(value)) {
          // Invalid date: didn't pass type validations but is a date.
          return value;
        }

        if (typeof value !== 'string' || !this.validFormat) {
          // Can't parse
          return DateTime.invalid(`Cannot parse type '${typeof value}' with format '${this.validFormat}'`);
        }

        return DateTime.fromFormat(value, this._validFormat);
      });

      this.typeError('Must be a valid date');
    });
  }

  required(message) {
    return super.required(message || 'Required');
  }

  // _requiredDate(dateArg, name) {
  _requiredDate(dateArg) {
    if (!dateArg) {
      // throw new Error(`\`${name}\` is required`);
      throw new Error('Required');
    }

    if (DateTime.isDateTime(dateArg)) {
      if (!dateArg.isValid) {
        throw new TypeError('Must be a valid DateTime');
      }
      return dateArg;
    }

    if (typeof dateArg !== 'string' || !this.validFormat) {
      throw new TypeError('Must be a valid DateTime');
    }

    const retValue = DateTime.fromFormat(dateArg, this._validFormat);

    if (!retValue || !retValue.isValid) {
      throw new TypeError('Must be a valid DateTime');
    }

    return retValue;
  }

  min(min, message = 'Must not be before ${min}') {
    const limit = this._requiredDate(min, 'min');

    const messageParam = this._validFormat ? limit.toFormat(this._validFormat) : limit.toFormat(DEFAULT_FORMAT);

    return this.test({
      message,
      name: 'min',
      exclusive: true,
      params: { min: messageParam },
      test(value) {
        return isAbsent(value) || value >= limit;
      },
    });
  }

  max(max, message = 'Must not be after ${max}') {
    const limit = this._requiredDate(max, 'max');

    const messageParam = this._validFormat ? limit.toFormat(this._validFormat) : limit.toFormat(DEFAULT_FORMAT);

    return this.test({
      message,
      name: 'max',
      exclusive: true,
      params: { max: messageParam },
      test(value) {
        return isAbsent(value) || value <= limit;
      },
    });
  }

  after(min, message = 'Must be after ${min}') {
    const limit = this._requiredDate(min, 'min');

    const messageParam = this._validFormat ? limit.toFormat(this._validFormat) : limit.toFormat(DEFAULT_FORMAT);

    return this.test({
      message,
      name: 'min',
      exclusive: true,
      params: { min: messageParam },
      test(value) {
        return isAbsent(value) || value > limit;
      },
    });
  }

  before(max, message = 'Must be before ${max}') {
    const limit = this._requiredDate(max, 'max');

    const messageParam = this._validFormat ? limit.toFormat(this._validFormat) : limit.toFormat(DEFAULT_FORMAT);

    return this.test({
      message,
      name: 'max',
      exclusive: true,
      params: { max: messageParam },
      test(value) {
        return isAbsent(value) || value < limit;
      },
    });
  }

  // eslint-disable-next-line class-methods-use-this
  _typeCheck(value) {
    // super._typeCheck(value)
    return (
      (DateTime.isDateTime(value) && value.isValid)
    );
  }

  format(format) {
    if (!format) {
      throw new Error('Must enter a valid format');
    }
    const next = this.clone();
    next._validFormat = format;
    return next;
  }
}

export { LuxonDateTimeSchema, stripWhitespace };
