/* eslint-disable react/jsx-tag-spacing */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-indent */
/* eslint-disable max-len */
import React from 'react';
import { SvgIcon } from '@material-ui/core';
import PropTypes from 'prop-types';

const Icon = ({ style, ...rest }) => {
  return (
    <SvgIcon {...rest} style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinecap: 'round', strokeLinejoin: 'round', strokeMiterlimit: '1.5', ...style }}>
      <g id="Layer2">
        <path d="M20.25,3.5C20.25,2.258 19.242,1.25 18,1.25L6,1.25C4.758,1.25 3.75,2.258 3.75,3.5C3.75,3.5 3.75,20.5 3.75,20.5C3.75,21.742 4.758,22.75 6,22.75L18,22.75C19.242,22.75 20.25,21.742 20.25,20.5L20.25,3.5ZM18.75,3.5L18.75,20.5C18.75,20.5 5.25,20.5 5.25,20.5C5.25,20.5 5.25,3.5 5.25,3.5C5.25,3.5 18.75,3.5 18.75,3.5Z"/>
        <g transform="matrix(0.505817,0,0,0.505817,8.90693,-4.09307)">
            <circle cx="17" cy="17" r="1"/>
        </g>
        <g transform="matrix(0.5,0,0,0.5,-2,-3.98837)">
            <circle cx="17" cy="17" r="1"/>
        </g>
        <g transform="matrix(0.5,0,0,0.5,-2,11)">
            <circle cx="17" cy="17" r="1"/>
        </g>
        <g transform="matrix(0.507789,0,0,0.507789,8.8598,10.8598)">
            <circle cx="17" cy="17" r="1"/>
        </g>
        <g transform="matrix(1.36915,0,0,1.2,-4.42978,-3.7)">
            <path d="M16.017,9.003C16.017,8.725 15.82,8.5 15.577,8.5L8.423,8.5C8.18,8.5 7.983,8.725 7.983,9.003L7.983,12.997C7.983,13.275 8.18,13.5 8.423,13.5L15.577,13.5C15.82,13.5 16.017,13.275 16.017,12.997L16.017,9.003Z" style={{ fill: 'none', stroke: 'currentColor', strokeWidth: '0.78px' }}/>
        </g>
        <g transform="matrix(0.643599,0,0,0.838199,2.51893,4.56558)">
            <text x="6.97px" y="16.028px" style={{ fontFamily: '\'Arial-Black\', \'Arial Black\', sans-serif', fontWeight: '900', fontSize: '5px' }}>DENT</text>
        </g>
    </g>
    </SvgIcon>
  );
};

Icon.propTypes = {
  style: PropTypes.object
};

export default Icon;
