import {
  Card,
  withStyles
} from '@material-ui/core';

const RelativeCard = withStyles({
  root: {
    position: 'relative'
  }
})(Card);

export default RelativeCard;
