/* eslint-disable no-unused-vars */
import React from 'react';

import {
  Avatar,
  Box,
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  makeStyles,
} from '@material-ui/core';
import {
  DashboardOutlined as DashboardIcon,
  OfflineBoltOutlined as TestIcon,
  Memory as CodeIcon,
  Timeline as LiveIcon,
  BarChart as HistoricalIcon,
  Inbox as InboxIcon,
  GetApp as DownloadIcon,
  LocalFlorist as GrowIcon,
  // Notifications as NotificationsIcon
  NotificationsNone as NotificationsIcon
} from '@material-ui/icons';
import {
  Data as DataIcon
} from 'src/icons';

import PropTypes from 'prop-types';
import { get } from 'lodash';

import { Link as RouterLink } from 'src/components/Router';
import { mergeMenu, formatMenu } from 'src/utils/menu';
import { useCompositionContext } from 'src/composition';
import { useSystemContext } from 'src/service/System';
import AuthImage from 'src/components/core/AuthImage';
import CustomerPicker from 'src/components/CustomerPicker';
import { FormSelect } from 'src/components/form';

import NavBar from './NavBar';
import NavItem from './NavItem';

const useStyles = makeStyles((theme) => ({
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64,
    marginBottom: 5
  },

  avatarImage: {
    maxWidth: 96,
    maxHeight: 96
  },

  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2)
  }
}));

const UserNavBar = ({ onMobileClose, openMobile, openDesktop }) => {
  const [open, setOpen] = React.useState(true);
  const classes = useStyles();

  const system = useSystemContext();
  const composition = useCompositionContext();

  const items = formatMenu(
    mergeMenu(composition.fetchMenus('app')),
    { filter:
      (item) => (
        (!item.feature || system.hasFeature(item.feature))
        && (!item.customerAccess || system.hasCustomerAccess(item.customerAccess))
      )
    }
  );

  const user = system.getUser();

  const handleClick = () => {
    setOpen(!open);
  };

  const customers = system.getCustomers();

  return (
    <NavBar onMobileClose={onMobileClose} openMobile={openMobile} openDesktop={openDesktop}>
      {user && (
        <>
          <Box className={classes.header}>
            <Avatar
              className={classes.avatar}
              component={RouterLink}
              to="/app/account"
            >
              {user.image && <AuthImage src={`/files/${user.image}`} alt="Profile Image" className={classes.avatarImage} />}
            </Avatar>
            <Typography color="textPrimary" variant="body1" noWrap style={{ maxWidth: '100%' }}>
              {user.firstName} {user.lastName}
            </Typography>
            {(customers.length > 1) && (
              <Box mt={0.5}>
                <CustomerPicker
                  summaries={customers}
                  selectedId={system.getCustomerId()}
                  onSelect={(customerId) => { system.changeCustomer(customerId); }}
                />
              </Box>
            )}
          </Box>
          <Divider />
        </>
      )}
      <Box py={1} px={2}>
        <List>
          {items.map((item) => {
            return (
              <NavItem
                menuId="user"
                key={item.title}
                href={item.href}
                icon={item.icon}
                title={item.title}
                items={item.items}
              />
            );
          })}
        </List>
      </Box>
    </NavBar>
  );
};

UserNavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
  openDesktop: PropTypes.bool
};

export default UserNavBar;
