import { useEffect, useRef } from 'react';

const useWindowResizeDebounced = (handleResize, debounceInterval = 250) => {
  const debounceTimerRef = useRef();
  const debounceIntervalRef = useRef();

  const onWindowResizeDebounced = () => {
    if (!debounceIntervalRef.current) {
      debounceIntervalRef.current = setInterval(handleResize, debounceInterval);
    }
    clearTimeout(debounceTimerRef.current);
    debounceTimerRef.current = setTimeout(() => {
      clearInterval(debounceIntervalRef.current);
      debounceIntervalRef.current = null;
    }, debounceInterval);
  };

  useEffect(() => {
    window.addEventListener('resize', onWindowResizeDebounced);
    return () => {
      window.removeEventListener('resize', onWindowResizeDebounced);
    };
  }, []);
};

export default useWindowResizeDebounced;
