/* eslint-disable react/jsx-tag-spacing */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-indent */
/* eslint-disable max-len */
import React from 'react';
import { SvgIcon } from '@material-ui/core';
import PropTypes from 'prop-types';

const Icon = ({ style, ...rest }) => {
  return (
    <SvgIcon {...rest} style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinecap: 'round', strokeLinejoin: 'round', strokeMiterlimit: '1.5', ...style }}>
      <g id="Gateway" transform="matrix(2.15385,0,0,1,-13.8462,-4.75)">
        <g transform="matrix(6.75224e-17,0.947368,-0.795918,-2.40777e-17,25.9286,5.85526)">
            <path d="M21,14.99C21,14.443 20.197,14 19.209,14L3.791,14C2.803,14 2,14.443 2,14.99L2,20.01C2,20.557 2.803,21 3.791,21L19.209,21C20.197,21 21,20.557 21,20.01L21,14.99Z" style={{ fill: 'none', stroke: 'currentColor', strokeWidth: '1.08px' }}/>
        </g>
        <g transform="matrix(0.464286,0,0,1,6.42857,4.75)">
            <path d="M15,7L9,7L9,17L15,17L15,7ZM14,8L10,8L10,14L14,14L14,8Z"/>
        </g>
        <g transform="matrix(0.732559,0,0,1.5,3.02037,1)">
            <ellipse cx="12.258" cy="6" rx="0.475" ry="0.5"/>
        </g>
        <g transform="matrix(0.732559,0,0,1.5,3.02037,14.5)">
            <ellipse cx="12.258" cy="6" rx="0.475" ry="0.5"/>
        </g>
    </g>
    </SvgIcon>
  );
};

Icon.propTypes = {
  style: PropTypes.object
};

export default Icon;
