/* eslint-disable react/jsx-tag-spacing */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-indent */
/* eslint-disable max-len */
import React from 'react';
import { SvgIcon } from '@material-ui/core';

const Icon = (props) => {
  return (
    <SvgIcon {...props}>
      <g id="Layer3">
        <g>
          <path d="M21,17C21,14.792 19.208,13 17,13L7,13C4.792,13 3,14.792 3,17C3,19.208 4.792,21 7,21L17,21C19.208,21 21,19.208 21,17ZM16,14.75C17.242,14.75 18.25,15.758 18.25,17C18.25,18.242 17.242,19.25 16,19.25C14.758,19.25 13.75,18.242 13.75,17C13.75,15.758 14.758,14.75 16,14.75Z" />
        </g>
        <g transform="matrix(1.0466,0,0,1,1.65721,1.5)">
          <path d="M17.765,5.5C17.765,3.568 16.266,2 14.42,2L5.344,2C3.498,2 2,3.568 2,5.5C2,7.432 3.498,9 5.344,9L14.42,9C16.266,9 17.765,7.432 17.765,5.5Z" style={{ fill: 'white', fillOpacity: '0', stroke: 'currentColor', strokeWidth: '1.22px' }} />
        </g>
        <g transform="matrix(1.12032,0,0,1.12032,-11.0454,-0.84224)">
          <circle cx="17" cy="7" r="2" />
        </g>
      </g>
    </SvgIcon>
  );
};

export default Icon;
