/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Paper,
  makeStyles,
} from '@material-ui/core';

import { grey } from '@material-ui/core/colors';

import clsx from 'clsx';
import { get } from 'lodash';

const useOutlinedStyles = makeStyles((theme) => ({

  container: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    position: 'relative',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: grey[300],
  },

  iconContainer: {
    backgroundColor: grey[100],
    position: 'absolute',
    top: -theme.spacing(1),
    left: theme.spacing(-1),
    padding: 2,
    height: 28
  },

  contentContainer: {
    height: '100%',
    paddingLeft: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },

  titleIcon: {
    // color: theme.palette.secondary.main,
    color: grey[600],
  },

  title: {
    color: theme.palette.text.secondary,
    fontWeight: 600,
    fontSize: 14,
    flexShrink: 0,
  },

  titleOutlined: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },

  valueContainer: {
    // flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  value: {
    fontSize: 20,
    fontWeight: 'bold',
  },

  footer: {
    color: theme.palette.text.secondary,
    fontSize: 12,
    flexShrink: 0,
  }
}));

const useStandardStyles = makeStyles((theme) => ({

  container: {
    display: 'flex',
    alignItems: 'center'
  },

  iconContainer: {
    backgroundColor: grey[100],
  },

  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingLeft: theme.spacing(1),
    // alignItems: 'center'
  },

  titleIcon: {
    // color: theme.palette.secondary.main,
    color: grey[600],
    // fontSize: 48,
    fontSize: 48,
    display: 'block'
  },

  title: {
    color: theme.palette.text.secondary,
    fontWeight: 600,
    fontSize: 14,
    flexShrink: 0,
  },

  valueContainer: {
    // flexGrow: 1,
    display: 'flex',
    // justifyContent: 'center',
    alignItems: 'center',
  },

  value: {
    fontSize: 20,
    fontWeight: 'bold',
  },

  footer: {
    color: theme.palette.text.secondary,
    fontSize: 12,
    flexShrink: 0,
  }
}));

const ValueBoxOutlined = ({
  icon,
  title,
  value,
  footer,
  classes,
  className,
  containerProps,
  iconContainerProps,
  children,
}) => {
  const baseClasses = useOutlinedStyles();

  return (
    <Paper className={clsx(baseClasses.container, get(classes, 'root'), className)} {...containerProps}>
      {icon && (
        <Paper className={clsx(baseClasses.iconContainer, get(classes, 'iconPaper'))} {...iconContainerProps}>
          {React.isValidElement(icon)
            ? icon
            : (
              React.createElement(icon, { className: clsx(baseClasses.titleIcon, get(classes, 'icon')) })
            )}
        </Paper>
      )}
      <Box className={baseClasses.contentContainer}>
        {title != null && (
          <Box className={clsx(baseClasses.title, baseClasses.titleOutlined, get(classes, 'title'))} style={{ marginLeft: icon ? 12 : null }}>
            {title}
          </Box>
        )}
        <Box className={baseClasses.valueContainer}>
          <Box className={clsx(baseClasses.value, get(classes, 'value'))}>
            {value != null ? value : children}
          </Box>
        </Box>
        {footer != null && (
          <Box className={clsx(baseClasses.footer, get(classes, 'footer'))}>
            {footer}
          </Box>
        )}
      </Box>
    </Paper>
  );
};

ValueBoxOutlined.propTypes = {
  title: PropTypes.node,
  value: PropTypes.node,
  footer: PropTypes.node,
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.elementType]),
  classes: PropTypes.object,
  className: PropTypes.string,
  containerProps: PropTypes.object,
  iconContainerProps: PropTypes.object,
  children: PropTypes.node
};

const ValueBoxStandard = ({
  icon,
  title,
  value,
  footer,
  classes,
  className,
  containerProps,
  iconContainerProps,
  children,
}) => {
  const baseClasses = useStandardStyles();

  return (
    <Box className={clsx(baseClasses.container, get(classes, 'root'), className)} {...containerProps}>
      {icon && (
        <Box className={clsx(baseClasses.standardIconContainer, get(classes, 'iconBox'))} {...iconContainerProps}>
          {React.isValidElement(icon)
            ? icon
            : (
              React.createElement(icon, { className: clsx(baseClasses.titleIcon, get(classes, 'icon')) })
            )}
        </Box>
      )}
      <Box className={baseClasses.contentContainer}>
        <Box className={baseClasses.valueContainer}>
          <Box className={clsx(baseClasses.value, get(classes, 'value'))}>
            {value != null ? value : children}
          </Box>
        </Box>
        {title != null && (
          <Box className={clsx(baseClasses.title, get(classes, 'title'))}>
            {title}
          </Box>
        )}
        {footer != null && (
          <Box className={clsx(baseClasses.footer, get(classes, 'footer'))}>
            {footer}
          </Box>
        )}
      </Box>

    </Box>
  );
};

ValueBoxStandard.propTypes = {
  title: PropTypes.node,
  value: PropTypes.node,
  footer: PropTypes.node,
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.elementType]),
  classes: PropTypes.object,
  className: PropTypes.string,
  containerProps: PropTypes.object,
  iconContainerProps: PropTypes.object,
  children: PropTypes.node
};

const ValueBox = ({
  variant,
  // children,
  ...rest
}) => {
  if (variant === 'outlined') {
    return <ValueBoxOutlined {...rest} />;
  }

  return <ValueBoxStandard {...rest} />;
};

ValueBox.propTypes = {
  variant: PropTypes.node,
  title: PropTypes.node,
  value: PropTypes.node,
  footer: PropTypes.node,
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.elementType]),
  classes: PropTypes.object,
  containerProps: PropTypes.object,
  iconContainerProps: PropTypes.object,
  children: PropTypes.node
};

export default ValueBox;
