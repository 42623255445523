import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import log from 'src/support/Logger';

/**
 * Creates a component and sets it innerHTML. This
 * component is unsafe (does no escaping) and should
 * be used with caution.
 */
const RawHtml = ({ html, component = 'div', ...rest }) => {
  const ref = useRef();

  useEffect(() => {
    const node = ref.current;
    if (!(node instanceof HTMLElement)) {
      log.error('Could not render raw html. component is not an HTMLElement');
    } else {
      node.innerHTML = html || '';
    }
  });

  return React.createElement(component, { ref, ...rest });
};

RawHtml.propTypes = {
  html: PropTypes.string,
  component: PropTypes.string
};

export default RawHtml;
