/* eslint-disable react/jsx-tag-spacing */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-indent */
/* eslint-disable max-len */
import React from 'react';
import { SvgIcon } from '@material-ui/core';
import PropTypes from 'prop-types';

const Icon = ({ style, ...rest }) => {
  return (
    <SvgIcon {...rest} style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinecap: 'round', strokeLinejoin: 'round', strokeMiterlimit: '1.5', ...style }}>
      <g id="IndustrialSensor">
        <g transform="matrix(1.05882,0,0,1.125,-1.23529,-2.0625)">
            <path d="M21,12.84L21,6.827C21,5.543 19.892,4.5 18.527,4.5L6.473,4.5C5.108,4.5 4,5.543 4,6.827L4,18.173C4,19.457 5.108,20.5 6.473,20.5L10.972,20.5" style={{ fill: 'none', stroke: 'currentColor', strokeWidth: '1.83px' }}/>
        </g>
        <g transform="matrix(0.5,0,0,0.5,11.2152,12.0206)">
            <path d="M11.99,2C6.47,2 2,6.48 2,12C2,17.52 6.47,22 11.99,22C17.52,22 22,17.52 22,12C22,6.48 17.52,2 11.99,2ZM12,20C7.58,20 4,16.42 4,12C4,7.58 7.58,4 12,4C16.42,4 20,7.58 20,12C20,16.42 16.42,20 12,20Z" style={{ fillRule: 'nonzero' }}/>
        </g>
        <g transform="matrix(0.5,0,0,0.5,11.2152,12.0206)">
            <path d="M12.5,7L11,7L11,13L16.25,16.15L17,14.92L12.5,12.25L12.5,7Z" style={{ fillRule: 'nonzero' }}/>
        </g>
    </g>
    <g id="Layer2" transform="matrix(1.1768,0,0,1.1768,-1.00552,-0.87002)">
        <g transform="matrix(0.198465,0,0,0.198465,3.90132,3.73026)">
            <path d="M36,6L19.5,33L52.5,33L36,6Z" style={{ fillRule: 'nonzero' }}/>
        </g>
        <g transform="matrix(0.198465,0,0,0.198465,3.90132,3.73026)">
            <rect x="9" y="40.5" width="24" height="24" style={{ fillRule: 'nonzero' }}/>
        </g>
    </g>
    </SvgIcon>
  );
};

Icon.propTypes = {
  style: PropTypes.object
};

export default Icon;
