import React from 'react';

import {
  makeStyles,
} from '@material-ui/core';

import PropTypes from 'prop-types';
import clsx from 'clsx';
import { get } from 'lodash';
import ReactMarkdown from 'react-markdown';

const useStyles = makeStyles(() => ({
  noMarginRoot: {
    '& > :first-child': {
      marginTop: 0
    },

    '& > :last-child': {
      marginBottom: 0
    }
  }
}));

const ReactMarkdownStyled = ({ noMargin = true, className, classes, value, children, ...rest }) => {
  const baseClasses = useStyles();

  return (
    <ReactMarkdown
      className={clsx(
        noMargin ? baseClasses.noMarginRoot : null,
        get(classes, 'root'),
        className
      )}
      {...rest}
    >
      {value || children}
    </ReactMarkdown>
  );
};

ReactMarkdownStyled.propTypes = {
  noMargin: PropTypes.bool,
  className: PropTypes.string,
  classes: PropTypes.object,
  value: PropTypes.any,
  children: PropTypes.any
};

export default ReactMarkdownStyled;
