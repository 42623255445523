/* eslint-disable react/jsx-tag-spacing */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-indent */
/* eslint-disable max-len */
import React from 'react';
import { SvgIcon } from '@material-ui/core';
import PropTypes from 'prop-types';

const Icon = ({ style, ...rest }) => {
  return (
    <SvgIcon {...rest} style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinecap: 'round', strokeLinejoin: 'round', strokeMiterlimit: '1.5', ...style }}>
      <g id="IndustrialSensor">
        <g transform="matrix(0.705882,0,0,0.965812,0.48178,-0.572656)">
          <path d="M21,6.288C20.829,5.285 19.892,4.5 18.527,4.5L6.473,4.5C5.108,4.5 4.241,5.347 4,6.307C3.44,8.537 3.65,10.429 3.65,10.429L5.417,10.429L5.417,14.571L3.572,14.545C3.572,14.545 3.49,17.328 4,18.693C4.351,19.633 5.108,20.5 6.473,20.5L18.527,20.5C19.892,20.5 20.811,19.697 21,18.693C21.954,13.614 21.882,11.445 21,6.288Z" style={{ fill: 'none', stroke: 'currentColor', strokeWidth: '2.36px' }} />
        </g>
        <g transform="matrix(1.375,0,0,0.888889,-4.38219,1.05556)">
          <path d="M12.5,10.625C12.5,10.004 12.174,9.5 11.773,9.5L9.227,9.5C8.826,9.5 8.5,10.004 8.5,10.625L8.5,12.875C8.5,13.496 8.826,14 9.227,14L11.773,14C12.174,14 12.5,13.496 12.5,12.875L12.5,10.625Z" style={{ fill: 'none', stroke: 'currentColor', strokeWidth: '1.3px' }} />
        </g>
        <g transform="matrix(1,0,0,1,2,-1.77636e-15)">
          <path d="M7.396,20L7.396,22L18,22L18,12.5" style={{ fill: 'none', stroke: 'currentColor', strokeWidth: '1px' }} />
        </g>
        <g transform="matrix(1.22465e-16,2,-1,6.12323e-17,23.3053,-24)">
          <path d="M22.5,12.5C22.5,12.224 22.388,12 22.25,12L21.75,12C21.612,12 21.5,12.224 21.5,12.5L21.5,15.5C21.5,15.776 21.612,16 21.75,16L22.25,16C22.388,16 22.5,15.776 22.5,15.5L22.5,12.5Z" />
        </g>
        <g transform="matrix(1,0,0,0.525347,0.5,6.53455)">
          <path d="M19.5,8.5L19.5,13.5" style={{ fill: 'none', stroke: 'currentColor', strokeWidth: '3.13px' }} />
        </g>
        <g transform="matrix(1,0,0,1,0.5,1.87899)">
          <path d="M19.5,3.742L19.5,9.5" style={{ fill: 'none', stroke: 'currentColor', strokeWidth: '1.5px' }} />
        </g>
      </g>
    </SvgIcon>
  );
};

Icon.propTypes = {
  style: PropTypes.object
};

export default Icon;
