/* eslint-disable no-unused-vars */
import React, { Suspense, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FetchingProvider as FetchingMessageSourceProvider } from 'react-message-source';

const RefreshingMessageSourceProvider = ({
  url,
  timeout = 30000,
  children,
  ...rest
}) => {
  const [messagesUrl, setMessagesUrl] = useState(`${url}?q=${Date.now()})}`);

  const handleMessageFetchError = (error) => {
    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to fetch messages');
        }
        response.text();
        setMessagesUrl(`${url}?q=${Date.now()})}`);
      })
      .catch((e) => {
        setTimeout(() => { handleMessageFetchError('no error'); }, timeout);
      });
  };

  return (
    <FetchingMessageSourceProvider
      url={messagesUrl}
      onFetchingError={handleMessageFetchError}
      {...rest}
    >
      {children}
    </FetchingMessageSourceProvider>
  );
};

RefreshingMessageSourceProvider.propTypes = {
  url: PropTypes.string.isRequired,
  timeout: PropTypes.number,
  children: PropTypes.node.isRequired,
};

export default RefreshingMessageSourceProvider;
