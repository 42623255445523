/* eslint-disable react/jsx-tag-spacing */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-indent */
/* eslint-disable max-len */
import React from 'react';
import { SvgIcon } from '@material-ui/core';
import PropTypes from 'prop-types';

const Icon = ({ style, ...rest }) => {
  return (
    <SvgIcon {...rest} style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinejoin: 'round', strokeMiterlimit: '2', ...style }}>
      <g transform="matrix(1.19888,0,0,1.15895,1.39456,2.00811)">
        <path d="M10.747,9.75L10.605,9.75L10.605,6.683C10.605,6.496 10.531,6.316 10.398,6.184C10.266,6.052 10.087,5.978 9.9,5.978C9.713,5.978 9.534,6.052 9.401,6.184C9.269,6.316 9.195,6.496 9.195,6.683L9.195,9.75L6.997,9.75C6.898,9.75 6.8,9.77 6.709,9.809C6.617,9.848 6.534,9.904 6.465,9.975C6.326,10.115 6.248,10.303 6.247,10.5L6.247,16.5C6.247,16.699 6.326,16.89 6.467,17.03C6.608,17.171 6.798,17.25 6.997,17.25L10.747,17.25C10.846,17.251 10.944,17.232 11.035,17.194C11.127,17.157 11.21,17.102 11.28,17.033C11.349,16.962 11.404,16.879 11.442,16.788C11.479,16.697 11.498,16.599 11.497,16.5L11.497,10.5C11.497,10.303 11.418,10.115 11.28,9.975C11.21,9.904 11.128,9.848 11.036,9.809C10.945,9.77 10.847,9.75 10.747,9.75Z" style={{ fillRule: 'nonzero' }}/>
        <path d="M8.85,3C8.16,2.999 7.477,3.134 6.84,3.398C6.204,3.664 5.626,4.051 5.137,4.538L6.195,5.595C6.544,5.247 6.956,4.97 7.41,4.778C7.867,4.59 8.356,4.493 8.85,4.493C9.341,4.493 9.828,4.59 10.282,4.778C10.737,4.972 11.152,5.249 11.505,5.595L12.562,4.538C12.074,4.051 11.495,3.664 10.86,3.398C10.222,3.134 9.539,2.999 8.85,3Z" style={{ fillRule: 'nonzero' }}/>
        <path d="M12.007,0.623C10.498,-0.003 8.837,-0.167 7.235,0.152C5.633,0.471 4.161,1.259 3.007,2.415L4.08,3.472C5.024,2.528 6.227,1.885 7.537,1.625C8.846,1.365 10.204,1.499 11.437,2.01C12.255,2.351 12.999,2.848 13.627,3.472L14.685,2.415C13.919,1.647 13.009,1.038 12.007,0.623Z" style={{ fillRule: 'nonzero' }}/>
    </g>
    </SvgIcon>
  );
};

Icon.propTypes = {
  style: PropTypes.object
};

export default Icon;
