import React, { useState, useRef, useImperativeHandle, forwardRef } from 'react';

import {
  Button,
  IconButton,
  Popover,
  Tooltip
} from '@material-ui/core';

import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

const ButtonPopover = forwardRef(({ icon, buttonLabel, buttonProps, popoverProps, onOpen, onClose, tooltip, children }, ref) => {
  if (!icon && !buttonLabel) {
    throw new Error('Invalid parameters. Expected "icon" or "buttonLabel" to be specified');
  }

  const [open, setOpen] = useState(false);
  const buttonRef = useRef(null);

  const handleClick = () => {
    if (onOpen) {
      onOpen();
    }
    setOpen(true);
  };

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
    setOpen(false);
  };

  useImperativeHandle(ref, () => ({
    close: () => setOpen(false)
  }));

  const id = open ? uuidv4() : undefined;

  let iconContent = null;
  if (icon) {
    iconContent = React.isValidElement(icon)
      ? icon
      : React.createElement(icon);
  }

  let buttonComponent;
  if (buttonLabel) {
    buttonComponent = (
      <Button ref={buttonRef} aria-describedby={id} onClick={handleClick} {...buttonProps}>
        {(typeof buttonLabel === 'function') ? React.createElement(buttonLabel) : buttonLabel}
      </Button>
    );
  } else {
    buttonComponent = (
      <IconButton ref={buttonRef} aria-describedby={id} onClick={handleClick} {...buttonProps}>
        {/* {React.createElement(icon)} */}
        {iconContent}
      </IconButton>
    );
  }

  if (tooltip) {
    buttonComponent = <Tooltip title={tooltip}>{buttonComponent}</Tooltip>;
  }

  return (
    <>
      {buttonComponent}
      <Popover
        open={open}
        anchorEl={() => buttonRef.current}
        onClose={handleClose}
        {...popoverProps}
      >
        {children}
      </Popover>
    </>
  );
});

ButtonPopover.propTypes = {
  icon: PropTypes.any,
  buttonLabel: PropTypes.any,
  buttonProps: PropTypes.object,
  popoverProps: PropTypes.object,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  tooltip: PropTypes.string,
  children: PropTypes.any
};

export default ButtonPopover;
