/* eslint-disable react/jsx-tag-spacing */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-indent */
/* eslint-disable max-len */
import React from 'react';
import { SvgIcon } from '@material-ui/core';
import PropTypes from 'prop-types';

const Icon = ({ style, ...rest }) => {
  return (
    <SvgIcon {...rest} style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinecap: 'round', strokeLinejoin: 'round', strokeMiterlimit: '1.5', ...style }}>
      <g id="Gateway" transform="matrix(0.722222,0,0,0.722222,5.83333,5.55556)">
        <g transform="matrix(0.947368,0,0,0.928571,1.10526,0.5)">
            <path d="M21,15.75C21,14.784 20.231,14 19.285,14L3.715,14C2.769,14 2,14.784 2,15.75L2,19.25C2,20.216 2.769,21 3.715,21L19.285,21C20.231,21 21,20.216 21,19.25L21,15.75Z" style={{ fill: 'none', stroke: 'currentColor', strokeWidth: '2.95px' }}/>
        </g>
        <g transform="matrix(1,0,0,1,2.75,-0.75)">
            <circle cx="15.25" cy="16.75" r="0.75"/>
        </g>
        <g transform="matrix(1,0,0,1,0.25,-0.75)">
            <circle cx="15.25" cy="16.75" r="0.75"/>
        </g>
        <g transform="matrix(1,0,0,1,-2.25,-0.75)">
            <circle cx="15.25" cy="16.75" r="0.75"/>
        </g>
        <g transform="matrix(1,0,0,1.10526,13.5,-1.42105)">
            <path d="M6,4.679C6,4.304 5.664,4 5.25,4C4.836,4 4.5,4.304 4.5,4.679L4.5,12.821C4.5,13.196 4.836,13.5 5.25,13.5C5.664,13.5 6,13.196 6,12.821L6,4.679Z"/>
        </g>
        <g transform="matrix(0.141435,0,0,0.141435,6.90834,2.94318)">
            <path d="M3,27.5L9,33.5C23.91,18.59 48.09,18.59 63,33.5L69,27.5C50.79,9.29 21.24,9.29 3,27.5ZM27,51.5L36,60.5L45,51.5C40.05,46.52 31.98,46.52 27,51.5ZM15,39.5L21,45.5C29.28,37.22 42.72,37.22 51,45.5L57,39.5C45.42,27.92 26.61,27.92 15,39.5Z" style={{ fillRule: 'nonzero' }}/>
        </g>
    </g>
    <g transform="matrix(0.722122,0,0,0.722122,0.833634,-0.888038)">
        <path d="M3,17.25L3,21L6.75,21L17.81,9.94L14.06,6.19L3,17.25ZM20.71,7.04C21.1,6.65 21.1,6.02 20.71,5.63L18.37,3.29C18.183,3.103 17.929,2.998 17.665,2.998C17.401,2.998 17.147,3.103 16.96,3.29L15.13,5.12L18.88,8.87L20.71,7.04Z" style={{ fillRule: 'nonzero' }}/>
    </g>
    </SvgIcon>
  );
};

Icon.propTypes = {
  style: PropTypes.object
};

export default Icon;
