import React, { useRef } from 'react';

import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  makeStyles
} from '@material-ui/core';

import HelpIcon from '@material-ui/icons/Help';
import CloseIcon from '@material-ui/icons/Close';
import { grey } from '@material-ui/core/colors';

import PropTypes from 'prop-types';
import ButtonPopover from './ButtonPopover';

const useStyles = makeStyles(() => ({
  helpCard: {
    borderWidth: '1px',
    borderStyle: 'solid',
    // borderColor: theme.palette.primary.main,
    borderColor: grey[200],
    minWidth: '200px',
    maxWidth: '600px'
  },

  helpCardContent: {
    '& > p:first-child': {
      marginTop: 0
    },

    '& > p:last-child': {
      marginBottom: 0
    }
  }
}));

const FieldHelp = ({ title, buttonProps, children, ...rest }) => {
  const classes = useStyles();
  const popoverRef = useRef();

  const handleClose = () => popoverRef.current && popoverRef.current.close();

  return (
    <ButtonPopover
      ref={popoverRef}
      icon={HelpIcon}
      buttonProps={{ edge: 'end', ...buttonProps }}
      popoverProps={{
        anchorOrigin: {
          vertical: 'center',
          horizontal: 'left'
        },
        transformOrigin: {
          vertical: 'center',
          horizontal: 'right'
        }
      }}
      {...rest}
    >
      <Card className={classes.helpCard} elevation={3}>
        <CardHeader
          title={title || 'Help'}
          action={(
            <IconButton onClick={handleClose} size="small">
              <CloseIcon />
            </IconButton>
          )}
        />
        <Divider />
        <CardContent className={classes.helpCardContent}>{children}</CardContent>
      </Card>
    </ButtonPopover>

  );
};

FieldHelp.propTypes = {
  title: PropTypes.string,
  buttonProps: PropTypes.object,
  children: PropTypes.any
};

export default FieldHelp;
