import React from 'react';

import {
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemIcon,
  MenuItem,
  Select,
  makeStyles,
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import clsx from 'clsx';
import { defaultTo, get } from 'lodash';

const useStyles = makeStyles((theme) => ({
  endAdornedSelect: {
    '& .MuiOutlinedInput-adornedEnd': {
      flexDirection: 'row',
      alignItems: 'stretch',
      paddingRight: 0
    },

    '& .MuiSelect-icon': {
      position: 'relative',
      marginLeft: '-24px',
      alignSelf: 'center'
    },
    // '& .Mui-focused .FormikSelect-end-adornment': {
    //   backgroundColor: 'white'
    // }
  },

  endAdornment: {
    display: 'flex',
    alignItems: 'center',
    // paddingLeft: theme.spacing(1),
    borderLeftWidth: '1px',
    borderLeftStyle: 'solid',
    borderLeftColor: grey[300],
    backgroundColor: '#FBFBFB'
  },

  root: {
    '& .MuiSelect-root': {
      display: 'flex',
      alignItems: 'center'
    },

    '& .MuiSelect-root > .MuiListItemIcon-root': {
      // position: 'absolute',
      marginTop: -theme.spacing(2),
      marginBottom: -theme.spacing(2),
    }
  },

  actionListItemIcon: {
    minWidth: 40,
    // fontSize: 24
  },
}));

const FormSelect = ({
  label,
  id,
  name,
  value: valueArg,
  defaultValue,
  variant = 'outlined',
  fullWidth,
  errorText,
  helperText,
  disabled,
  margin,
  required,
  firstBlank,
  options,
  checkIncludes,
  classes,
  selectProps,
  endAdornment,
  onFocus,
  onBlur,
  onChange,
  children,
  InputLabelProps,
  inputRef,
  optionValue,
  optionLabel,
  optionAvatar,
  ...rest
}) => {
  const controlId = id || uuid();
  const labelId = `${controlId}-label`;

  const baseClasses = useStyles();

  // const resolvedClasses = { ...classes || {} };
  // if (endAdornment) {
  //   // Add root adornment classes if necessary
  //   resolvedClasses.root = clsx(baseClasses.root, resolvedClasses.root, baseClasses.endAdornedSelect);
  // } else {
  //   resolvedClasses.root = clsx(baseClasses.root, resolvedClasses.root, baseClasses.endAdornedSelect);
  // }

  // Select label isn't actually shown but the outlined label spacing seems to be dependent on it.
  const selectLabel = required ? `${label} *` : label;

  const resolvedOptions = !options
    ? []
    : options.map((option) => ({
      value: optionValue ? optionValue(option) : defaultTo(defaultTo(option.value, option.id), option),
      label: optionLabel ? optionLabel(option) : defaultTo(defaultTo(option.label, option.name), option),
      avatar: optionAvatar ? optionAvatar(option) : defaultTo(option.avatar, option.icon),
    }));

  /*
   * Sometimes async loaded options are not initialized when the value is first set resulting in
   * a console log warning. checkIncludes insures the value exists in the current options.
   */
  let value = valueArg;
  if (defaultValue != null && (value == null || value === '')) {
    value = defaultValue;
  }
  if (checkIncludes && value) {
    // eslint-disable-next-line eqeqeq
    if (!resolvedOptions.find((e) => value == e.value)) {
      value = '';
    }
  }

  const tempHelperText = errorText || helperText;

  return (

    <FormControl
      variant={variant}
      fullWidth={fullWidth}
      disabled={disabled}
      error={errorText != null}
      margin={margin}
      // classes={clsx(classes, endAdornment && { root: formClasses.endAdornedSelect })}
      classes={{
        ...classes,
        root: clsx(baseClasses.root, endAdornment && baseClasses.endAdornedSelect, get(classes, 'root'))
      }}
      required={required}
      {...rest}
    >
      {label && <InputLabel id={labelId} {...InputLabelProps}>{label}</InputLabel>}

      <Select
        labelId={labelId}
        id={controlId}
        name={name}
        value={value}
        label={selectLabel}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        disabled={disabled}
        inputRef={inputRef}
        endAdornment={endAdornment && (
          <div className={baseClasses.endAdornment}>
            {endAdornment}
          </div>
        )}
        {...selectProps}
      >
        {firstBlank && (
          <MenuItem key="FIRST_BLANK" value={null}>{'\u00A0'}</MenuItem>
        )}
        {resolvedOptions.map((option) => {
          const avatarNode = (option.avatar == null || React.isValidElement(option.avatar))
            ? option.avatar
            : React.createElement(option.avatar);

          return (
            <MenuItem key={option.value} value={option.value}>
              {avatarNode && (
                <ListItemIcon classes={{ root: baseClasses.actionListItemIcon }}>
                  {avatarNode}
                </ListItemIcon>
              )}
              {option.label}
            </MenuItem>
          );
        })}
        {children}
      </Select>
      {tempHelperText && <FormHelperText>{tempHelperText}</FormHelperText>}
    </FormControl>
  );
};

FormSelect.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.any,
  defaultValue: PropTypes.any,
  variant: PropTypes.string,
  errorText: PropTypes.string,
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  margin: PropTypes.string,
  required: PropTypes.bool,
  firstBlank: PropTypes.bool,
  options: PropTypes.array,
  checkIncludes: PropTypes.bool,
  classes: PropTypes.object,
  selectProps: PropTypes.object,
  endAdornment: PropTypes.any,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  InputLabelProps: PropTypes.object,
  inputRef: PropTypes.object,
  optionValue: PropTypes.func,
  optionLabel: PropTypes.func,
  optionAvatar: PropTypes.func,
  children: PropTypes.any,
};

export default FormSelect;
