/* eslint-disable react/jsx-tag-spacing */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-indent */
/* eslint-disable max-len */
import React from 'react';
import { SvgIcon } from '@material-ui/core';
import PropTypes from 'prop-types';

const Icon = ({ style, ...rest }) => {
  return (
    <SvgIcon {...rest} style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinecap: 'round', strokeLinejoin: 'round', strokeMiterlimit: '1.5', ...style }}>
      <g id="Layer1" transform="matrix(1,0,0,1,0,1)">
        <g transform="matrix(1,0,0,1.33333,0,-1.33333)">
            <rect x="2" y="4" width="20" height="6" style={{ fill: 'none', stroke: 'currentColor', strokeWidth: '1.27px' }}/>
        </g>
        <g transform="matrix(1,0,0,1,-1,-0.0236146)">
            <path d="M6,8.5L6,11.24" style={{ fill: 'none', stroke: 'currentColor', strokeWidth: '1.5px' }}/>
        </g>
        <g transform="matrix(1,0,0,0.912264,2.5,1.74576)">
            <path d="M6,8.5L6,11.24" style={{ fill: 'none', stroke: 'currentColor', strokeWidth: '1.57px' }}/>
        </g>
        <g transform="matrix(1,0,0,1,6,-0.0236146)">
            <path d="M6,8.5L6,11.24" style={{ fill: 'none', stroke: 'currentColor', strokeWidth: '1.5px' }}/>
        </g>
        <g transform="matrix(1,0,0,0.864905,9.5,2.27809)">
            <path d="M6,8.5L6,11.24" style={{ fill: 'none', stroke: 'currentColor', strokeWidth: '1.6px' }}/>
        </g>
        <g transform="matrix(1,0,0,1,13,0.129782)">
            <path d="M6,8.5L6,11.24" style={{ fill: 'none', stroke: 'currentColor', strokeWidth: '1.5px' }}/>
        </g>
    </g>
    <g transform="matrix(1.02564,0,0,2.34,-0.564103,-20.025)">
        <path d="M5.352,16.687L5.352,17.5L2.5,16.25L5.352,15L5.352,15.813L19.148,15.813L19.148,15L22,16.25L19.148,17.5L19.148,16.687L5.352,16.687Z"/>
    </g>
    </SvgIcon>
  );
};

Icon.propTypes = {
  style: PropTypes.object
};

export default Icon;
