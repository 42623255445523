import { useEffect, useRef } from 'react';

import { v4 as uuid } from 'uuid';

const useFixedDelay = () => {
  const startIdRef = useRef(null);
  const timerRef = useRef(null);

  const execute = (callback, interval) => {
    const startId = startIdRef.current;
    if (startId) {
      Promise.resolve(callback())
        .finally(() => {
          /*
           * Re schedule timer if started and start id is the same.
           */
          if (startIdRef.current === startId) {
            timerRef.current = setTimeout(() => execute(callback, interval), interval);
          }
        });
    }
  };

  const stop = () => {
    startIdRef.current = null;
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
  };

  const start = (callback, interval, initialDelay) => {
    stop();
    startIdRef.current = uuid();
    if (initialDelay === 0) {
      execute(callback, interval);
    } else {
      timerRef.current = setTimeout(() => execute(callback, interval), initialDelay || interval);
    }
  };

  useEffect(() => {
    return stop;
  }, []);

  return {
    start,
    stop
  };
};

export default useFixedDelay;
