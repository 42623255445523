/* eslint-disable react/jsx-tag-spacing */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-indent */
/* eslint-disable max-len */
import React from 'react';
import { SvgIcon } from '@material-ui/core';
import PropTypes from 'prop-types';

const Icon = ({ style, ...rest }) => {
  return (
    <SvgIcon {...rest} style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinecap: 'round', strokeLinejoin: 'round', strokeMiterlimit: '1.5', ...style }}>
      <g id="IndustrialSensor" transform="matrix(6.12323e-17,1,-1,6.12323e-17,22.5,2.60428)">
        <g transform="matrix(0.702583,8.32693e-33,2.67633e-32,1.125,0.584124,-3.5625)">
            <path d="M21,6.288C20.829,5.285 19.892,4.5 18.527,4.5L6.473,4.5C5.108,4.5 4.241,5.347 4,6.307C3.134,11.46 2.977,13.554 4,18.693C4.351,19.633 5.108,20.5 6.473,20.5L18.527,20.5C19.892,20.5 20.811,19.697 21,18.693C22.036,13.368 22.079,11.499 21,6.288Z" style={{ fill: 'none', stroke: 'currentColor', strokeWidth: '2.13px' }}/>
        </g>
        <g transform="matrix(1.5,3.34837e-32,8.13539e-32,1.55556,-6.35428,-5.77778)">
            <path d="M12.5,10.464C12.5,9.932 12.052,9.5 11.5,9.5L9.5,9.5C8.948,9.5 8.5,9.932 8.5,10.464L8.5,13.036C8.5,13.568 8.948,14 9.5,14L11.5,14C12.052,14 12.5,13.568 12.5,13.036L12.5,10.464Z" style={{ stroke: 'currentColor', strokeWidth: '0.82px' }}/>
        </g>
        <g transform="matrix(4.89859e-17,-0.8,0.8,4.89859e-17,-0.547802,17.7)">
            <path d="M15.25,8.5L16.5,11L14,11L15.25,8.5Z" style={{ stroke: 'currentColor', strokeWidth: '1.25px' }}/>
        </g>
        <g transform="matrix(4.89859e-17,-0.8,-0.8,-4.89859e-17,19.3991,17.7)">
            <path d="M15.25,8.5L16.5,11L14,11L15.25,8.5Z" style={{ stroke: 'currentColor', strokeWidth: '1.25px' }}/>
        </g>
    </g>
    </SvgIcon>
  );
};

Icon.propTypes = {
  style: PropTypes.object
};

export default Icon;
