import React, { forwardRef } from 'react';

import {
  Button,
  Tooltip,
} from '@material-ui/core';

import PropTypes from 'prop-types';

/**
 * Hack because Tooltip doesn't like disabled buttons. By default will wrap the
 * button. Wrap can be disabled for components (like ButtonGroup) that
 * don't play nicely with the wrap trick.
 */
const TooltipButton = forwardRef(({ title, disabled, component, wrapComponent = 'span', wrap = true, children, ...rest }, ref) => {
  const ButtonComponent = component || Button;

  const button = <ButtonComponent ref={ref} disabled={disabled} {...rest}>{children}</ButtonComponent>;

  if (wrap) {
    return (
      <Tooltip title={title}>
        {React.createElement(wrapComponent, null, button)}
      </Tooltip>
    );
  }

  if (disabled) {
    return button;
  }

  return (
    <Tooltip title={title}>
      {button}
    </Tooltip>
  );
});

TooltipButton.propTypes = {
  title: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.any,
  component: PropTypes.elementType,
  wrapComponent: PropTypes.elementType,
  wrap: PropTypes.bool
};

export default TooltipButton;
