import * as loglevel from 'loglevel';
import remote from 'loglevel-plugin-remote';

let logUrl = process.env.REACT_APP_LOG_URL;
if (!logUrl) {
  const baseUrl = process.env.REACT_APP_API_URL;
  if (!baseUrl) {
    throw new Error('Required log url "REACT_APP_API_URL/log" or "REACT_APP_LOG_URL" not found. Set environment variable or add ".env" file.');
  }
  logUrl = `${baseUrl}/log`;
}

remote.apply(loglevel, {
  url: logUrl,
  level: 'info',
  stacktrace: {
    depth: 5
  },
  format: remote.json
});

const baseLogger = loglevel.getLogger('core.application');

if (process.env.NODE_ENV !== 'production') {
  baseLogger.setLevel('trace');
} else {
  baseLogger.setLevel('error');
}

export default baseLogger;
