import { useState, useEffect } from "react";

function useKeyPress(callback) {
  const [downEvent, setDownEvent] = useState(null);

  function downHandler(e) {
    const event = e || window.event;
    setDownEvent(event);
  }

  function upHandler() {
    setDownEvent(null);
  }

  useEffect(() => {
    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);

    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  }, []);

  useEffect(() => {
    if (downEvent != null && callback != null) {
      callback(downEvent);
    }
  }, [downEvent]);
}

export default useKeyPress;
