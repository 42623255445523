import React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  makeStyles
} from '@material-ui/core';
import {
  ErrorOutline as ErrorIcon,
  ReportProblemOutlined as WarningIcon,
  CheckCircleOutline as SuccessIcon,
} from '@material-ui/icons';

import PropTypes from 'prop-types';

import ActionDialogTitle from './ActionDialogTitle';

const SEVERITY_ICONS = {
  success: SuccessIcon,
  warning: WarningIcon,
  error: ErrorIcon,
};

const useStyles = makeStyles((theme) => ({
  successIcon: {
    color: theme.palette.success.main
  },
  warningIcon: {
    color: theme.palette.warning.main
  },
  errorIcon: {
    color: theme.palette.error.main
  }
}));

const MessageDialogComponent = ({
  title,
  message,
  onClose,
  children,
  severity,
  avatar,
  avatarProps,
  disableTypography = false,
  ...rest
}) => {
  const classes = useStyles();

  const performClose = () => {
    if (onClose) {
      onClose();
    }
  };

  let headerIconClass = null;
  if (!avatar && severity) {
    avatar = SEVERITY_ICONS[severity];
    headerIconClass = classes[`${severity}Icon`];
  }

  let content = message || children;
  if (!disableTypography) {
    content = <DialogContentText>{content}</DialogContentText>;
  }

  return (
    <Dialog
      open
      onClose={performClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      {...rest}
    >
      {title && (
        <ActionDialogTitle
          avatar={avatar}
          avatarProps={avatarProps}
          classes={{ headerIcon: headerIconClass }}
          id="alert-dialog-title"
        >
          {title}
        </ActionDialogTitle>
      )}
      <DialogContent>
        {content}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={performClose} color="primary">
          Ok
        </Button>
        {/* <Button onClick={performConfirm} color="primary" disabled={loading}>
          Ok
        </Button> */}
      </DialogActions>
    </Dialog>
  );
};

MessageDialogComponent.propTypes = {
  title: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  severity: PropTypes.string,
  avatar: PropTypes.oneOfType([PropTypes.element, PropTypes.elementType]),
  avatarProps: PropTypes.object,
  disableTypography: PropTypes.bool,
  children: PropTypes.node
};

const MessageDialog = ({ open, ...rest }) => {
  if (!open) {
    return null;
  }

  return <MessageDialogComponent {...rest} />;
};

MessageDialog.propTypes = {
  open: PropTypes.bool,
};

export default MessageDialog;
