/* eslint-disable react/jsx-tag-spacing */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-indent */
/* eslint-disable max-len */
import React from 'react';
import { SvgIcon } from '@material-ui/core';
import PropTypes from 'prop-types';

const Icon = ({ style, ...rest }) => {
  return (
    <SvgIcon {...rest} style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinecap: 'round', strokeLinejoin: 'round', strokeMiterlimit: '1.5', ...style }}>
      <g id="IndustrialSensor">
        <g transform="matrix(1.05882,0,0,1.125,-1.23529,-2.0625)">
            <path d="M21,12.84L21,6.827C21,5.543 19.892,4.5 18.527,4.5L6.473,4.5C5.108,4.5 4,5.543 4,6.827L4,18.173C4,19.457 5.108,20.5 6.473,20.5L10.972,20.5" style={{ fill: 'none', stroke: 'currentColor', strokeWidth: '1.83px' }}/>
        </g>
        <g transform="matrix(2.72144e-17,0.444444,-0.544444,3.33376e-17,23.6333,12.6667)">
            <path d="M19,3L5,3C3.9,3 3,3.9 3,5L3,19C3,20.1 3.9,21 5,21L19,21C20.1,21 21,20.1 21,19L21,5C21,3.9 20.1,3 19,3ZM9,17L7,17L7,10L9,10L9,17ZM13,17L11,17L11,7L13,7L13,17ZM17,17L15,17L15,13L17,13L17,17Z" style={{ fillRule: 'nonzero' }}/>
        </g>
    </g>
    <g id="Layer2" transform="matrix(1.1768,0,0,1.1768,-1.00552,-0.87002)">
        <g transform="matrix(0.198465,0,0,0.198465,3.90132,3.73026)">
            <path d="M36,6L19.5,33L52.5,33L36,6Z" style={{ fillRule: 'nonzero' }}/>
        </g>
        <g transform="matrix(0.198465,0,0,0.198465,3.90132,3.73026)">
            <rect x="9" y="40.5" width="24" height="24" style={{ fillRule: 'nonzero' }}/>
        </g>
    </g>
    </SvgIcon>
  );
};

Icon.propTypes = {
  style: PropTypes.object
};

export default Icon;
