class DecayCounter {
  constructor(initialDelayMillis, decayMillis, maxWaitMillis) {
    this._initialDelayMillis = initialDelayMillis;
    this._decayMillis = decayMillis;
    this._maxWaitMillis = maxWaitMillis;
    this.reset();
  }

  reset() {
    this._waitMillis = 0;
    if (this._initialDelayMillis > 0) {
      this._nextTrigger = Date.now() + this._initialDelayMillis;
    } else {
      this._nextTrigger = Date.now();
    }
  }

  advance() {
    this._waitMillis = Math.min(this._maxWaitMillis, this._waitMillis + this._decayMillis);
    this._nextTrigger = Date.now() + this._waitMillis;
  }

  test() {
    return Date.now() >= this._nextTrigger;
  }
}

export default DecayCounter;
