import React, { useState, useEffect, useRef } from 'react';

import {
  Badge,
  Box,
  CircularProgress,
  makeStyles,
  withStyles,
  Tooltip,
  IconButton
} from '@material-ui/core';
import {
  Notifications as NotificationsIcon,
  InfoOutlined as InfoIcon,
  CheckCircleOutline as SuccessIcon,
  ReportProblemOutlined as WarningIcon,
  ErrorOutline as ErrorIcon,
  Close as CloseIcon,
} from '@material-ui/icons';
import { grey } from '@material-ui/core/colors';

import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';
import ReactMarkdown from 'react-markdown';
import { keyBy } from 'lodash';
import { useErrorHandler } from 'react-error-boundary';
import { DateTime } from 'luxon';

import { useCompositionContext } from 'src/composition';
import log from 'src/support/Logger';
import { useFixedDelay, useIsMounted, useStateRef } from 'src/utils/react';
// import { useNavigate } from 'src/components/Router';
import { useSystemContext } from 'src/service/System';
import { ButtonPopover } from 'src/components/core';

const MAX_ERRORS = 4;

const PAGE_SIZE = 15;

const MAX_HEIGHT = 400;

const useStyles = makeStyles((theme) => ({
  container: {
    width: 340,
    maxWidth: '100%',
    minHeight: 75,
    maxHeight: MAX_HEIGHT,
    overflow: 'auto'
    // minHeight: 100,
    // maxHeight: 400,
    // height: 400
  },

  title: {
    marginTop: 6,
    fontWeight: 'bold',
    // marginRight: theme.spacing(2),
  },

  item: {
    // border: '1px solid green',
    // borderBottom: '1px solid green',
    position: 'relative',
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    // paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
  },

  severityContainer: {
    float: 'left',
    '& .MuiSvgIcon-root': {
      display: 'block'
    },
    marginRight: theme.spacing(1)
    // position: 'absolute',
    // top: 0,
    // left: theme.spacing(0.5),
  },

  actionContainer: {
    position: 'absolute',
    top: -6,
    right: 0,
    // float: 'right',
    // borderBottomLeftRadius: 4,
    // borderTopRightRadius: 3,
    // backgroundColor: 'rgba(245, 245, 245, 0.80)',
  },

  actionIconButton: {
    padding: 8,
  },

  actionIcon: {
    fontSize: 16,
    color: grey[400],
  },

  content: {
    marginTop: 6,
    // marginRight: theme.spacing(2),

    '& > p': {
      marginTop: '0.75em',
      marginBottom: '0.75em'
    },

    '& > p:first-child': {
      marginTop: 0
    },

    '& > p:last-child': {
      marginBottom: 0
    }
  },

  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: -4,
    marginTop: 2,
    marginBottom: 2
  },

  countContainer: {
    fontSize: 10,
    backgroundColor: grey[400],
    // color: grey[50],
    color: '#FFFFFF',
    fontWeight: 'bold',
    borderRadius: 25,
    marginLeft: 4,
    paddingLeft: 6,
    paddingRight: 6,
  },

  dateContainer: {
    fontSize: 10,
    marginLeft: 4,
    color: grey[600]
  },

  notFound: {
    textAlign: 'center',
    fontSize: '1.2em',
    fontWeight: 500,
    fontStyle: 'italic',
    color: grey[500],
    paddingTop: theme.spacing(3)
  },

  infiniteScrollComponent: {
    position: 'relative',
    '& > div': {
      borderBottom: `1px solid ${grey[300]}`
    },

    '& > div:last-child': {
      borderBottom: 'none',
    },
  },

  infoIcon: {
    color: theme.palette.info.main
  },

  successIcon: {
    color: theme.palette.success.main
  },

  warningIcon: {
    color: theme.palette.warning.main
  },

  errorIcon: {
    color: theme.palette.error.main
  },

}));

const SEVERITY_MAP = {
  INFO: { icon: InfoIcon, class: 'infoIcon' },
  SUCCESS: { icon: SuccessIcon, class: 'successIcon' },
  WARNING: { icon: WarningIcon, class: 'warningIcon' },
  ERROR: { icon: ErrorIcon, class: 'errorIcon' },
};

const StyledBadge = withStyles(() => ({
  badge: {
    height: 18,
    padding: '0 4px',
  },
}))(Badge);

const Loading = ({ ...rest }) => {
  return (
    <Box textAlign="center" {...rest}>
      <CircularProgress size={24} style={{ color: grey[500] }} />
    </Box>
  );
};

const formatRelativeDate = (value) => {
  return !value ? null : value.replace(/\.?\s+ago$/, '');
};

const NotificationItem = ({
  item,
  handleRemove,
  timeZone,
  baseDate,
  itemCallbacks,
  pluginsById
}) => {
  const classes = useStyles();
  const elementRef = useRef();

  const positionCallback = () => {
    const element = elementRef.current;
    return element == null ? null : element.clientHeight + element.offsetTop;
  };

  useEffect(() => {
    itemCallbacks[item.id] = positionCallback;
    return () => {
      delete itemCallbacks[item.id];
    };
  }, []);
  // console.log(`Item: ${JSON.stringify(item)}`);
  const plugin = item.type ? pluginsById[item.type] : null;
  let content = null;
  if (plugin) {
    content = <plugin.component log={item} />;
  } else if (item.message) {
    content = <ReactMarkdown>{item.message}</ReactMarkdown>;
  }

  const severity = SEVERITY_MAP[item.severity];
  const firstSpacer = [{ marginRight: 20 }];
  return (
    <Box className={classes.item} key={item.id} ref={elementRef}>
      <Box className={classes.actionContainer}>
        <Tooltip title="Remove">
          <IconButton className={classes.actionIconButton} onClick={handleRemove}>
            <CloseIcon className={classes.actionIcon} />
          </IconButton>
        </Tooltip>
      </Box>
      {severity && (
        <Box className={classes.severityContainer}>
          <severity.icon fontSize="small" className={classes[severity.class]} />
        </Box>
      )}
      {item.title && (
        <Box className={classes.title} style={firstSpacer.pop()}>
          {item.title}
        </Box>
      )}
      {content && (
        <Box className={classes.content} style={firstSpacer.pop()}>
          {content}
        </Box>
      )}
      <Box className={classes.footer}>
        {/* <Box flexGrow={1} /> */}
        <Box className={classes.dateContainer}>
          {/* {DateTime.fromMillis(item.logDate, { zone: timeZone }).toLocaleString(DateTime.DATETIME_MED)} */}
          {
            formatRelativeDate(
              DateTime.fromMillis(
                item.logDate,
                { zone: timeZone }
              )
                .toRelative(
                  { base: baseDate, style: 'short' }
                )
            )
          }
        </Box>
        {item.count > 1 && (
          <Box className={classes.countContainer}>
            x {item.count}
          </Box>
        )}
      </Box>
    </Box>
  );
};

NotificationItem.propTypes = {
  item: PropTypes.object,
  handleRemove: PropTypes.func,
  timeZone: PropTypes.string,
  baseDate: PropTypes.object,
  itemCallbacks: PropTypes.object,
  pluginsById: PropTypes.object
};

const NotificationContent = ({ setUnread }) => {
  const [items, setItems, itemsRef] = useStateRef(null);
  const asOfTimestampRef = useRef(null);
  const [hasMore, setHasMore] = useState(true);
  const system = useSystemContext();
  const classes = useStyles();
  const isMounted = useIsMounted();
  const handleError = useErrorHandler();
  const { timeZone } = system.getUser();
  const composition = useCompositionContext();
  const [pluginsById] = useState(() => keyBy(composition.fetchNotificationLogs(), 'id'));
  // console.log(`Plugins by ${JSON.stringify(pluginsById)}`);
  const containerRef = useRef();

  const maxScrollRef = useRef(MAX_HEIGHT);
  const itemCallbacksRef = useRef({});
  const debounceTimerRef = useRef();

  const fetchParams = () => {
    const params = {};

    const asOfTimestamp = asOfTimestampRef.current;
    if (asOfTimestamp) {
      params.asOfTimestamp = asOfTimestamp;
    }

    const tempItems = itemsRef.current;
    if (tempItems != null && tempItems.length > 0) {
      const last = tempItems[tempItems.length - 1];
      params.beforeTimestamp = last.maxLogDate;
      params.beforeId = last.maxId;
    }

    return params;
  };

  const updateValues = (data, itemsArg) => {
    if (!isMounted()) {
      return;
    }

    const tempItems = itemsArg || [];

    const { count, logs, asOfTimestamp } = data;
    asOfTimestampRef.current = asOfTimestamp;
    setUnread(count.unread);
    if (logs.length === 0) {
      setHasMore(false);
    }
    setItems(tempItems.concat(logs));
  };

  const updateRead = () => {
    if (!itemsRef.current) {
      return;
    }

    const updateIds = new Set();

    const tempItems = [...itemsRef.current];

    for (let i = 0; i < tempItems.length; i++) {
      const item = tempItems[i];

      if (item.unread > 0) {
        const callback = itemCallbacksRef.current[item.id];
        if (callback) {
          const itemPosition = callback();
          if (itemPosition) {
            if (itemPosition <= maxScrollRef.current) {
              updateIds.add(item.id);
            } else {
              // We are past seen values.
              break;
            }
          }
        }
      }
    }

    if (updateIds.size) {
      // console.log(JSON.stringify([...updateIds]));
      system.axios()
        .post('/notificationlogs/markread',
          {
            ids: [...updateIds],
            matching: true,
            asOfTimestamp: asOfTimestampRef.current
          })
        .then(({ data }) => {
          setUnread(data.unread);
          setItems(itemsRef.current.map((item) => ((updateIds.has(item.id)) ? { ...item, unread: 0 } : item)));
        })
        .catch(handleError);
    }
  };

  const updateReadDebounced = () => {
    if (debounceTimerRef.current) {
      clearTimeout(debounceTimerRef.current);
    }
    debounceTimerRef.current = setTimeout(updateRead, 1000);
  };

  const fetchMoreData = () => {
    system.axios()
      .get('/notificationlogs/fetch',
        {
          params: {
            maxResults: PAGE_SIZE,
            ...fetchParams()
          }
        })
      .then(({ data }) => {
        updateValues(data, items);
      })
      .catch(handleError);
  };

  useEffect(() => {
    fetchMoreData();
    updateReadDebounced();
  }, []);

  const handleRemove = (id) => {
    system.axios()
      .post('/notificationlogs/remove',
        {
          ids: [id],
          matching: true,
          ...fetchParams()
        })
      .then(({ data }) => {
        const tempItems = !items ? [] : items.filter((e) => e.id !== id);
        updateValues(data, tempItems);
        updateReadDebounced();
      })
      .catch(handleError);
  };

  const handleScroll = (event) => {
    const { target } = event;
    const scroll = target.clientHeight + target.scrollTop;
    maxScrollRef.current = Math.max(maxScrollRef.current, scroll);
    updateReadDebounced();
  };

  let content = null;

  if (!containerRef.current || !items) {
    content = <Loading pt={3} />;
  } else if (items.length === 0) {
    content = (
      <Box className={classes.notFound}>
        No items found
      </Box>
    );
  } else {
    // const now = system.now();
    const baseDate = asOfTimestampRef.current ? DateTime.fromMillis(asOfTimestampRef.current, { zone: timeZone }) : system.now();

    content = (
      <InfiniteScroll
        dataLength={items.length}
        next={fetchMoreData}
        hasMore={hasMore}
        // scrollThreshold={0.95}
        // scrollThreshold="15px"
        loader={<Loading py={1} />}
        scrollableTarget={containerRef.current}
        className={classes.infiniteScrollComponent}
        onScroll={handleScroll}
        // endMessage={(
        //   <Box className={classes.noMoreItems}>
        //     No more items
        //   </Box>
        // )}
      >
        {items.map((item) => (
          <NotificationItem
            key={item.id}
            item={item}
            handleRemove={() => handleRemove(item.id)}
            timeZone={timeZone}
            baseDate={baseDate}
            itemCallbacks={itemCallbacksRef.current}
            pluginsById={pluginsById}
          />
        ))}
      </InfiniteScroll>
    );
  }

  /*
   * JE: Using the height/style on the InfiniteScroll with a variable height
   * (e.g. maxHeight:300) breaks the infinite scroll for some reason. Using
   * an external container for scrolling works.
   */
  return (
    <Box ref={containerRef} className={classes.container}>
      {content}
    </Box>
  );
};

NotificationContent.propTypes = {
  setUnread: PropTypes.func
};

const NotificationPopover = ({ buttonColor = 'inherit' }) => {
  const popoverRef = useRef();
  const [unread, setUnread] = useState(0);
  const isMounted = useIsMounted();
  const errorCount = useRef(0);

  const system = useSystemContext();
  const fixedDelay = useFixedDelay();

  const fetchData = () => {
    if (!system.isUserLoggedIn()) {
      return null;
    }

    return system.axios()
      .get('/notificationlogs/count')
      .then(({ data }) => {
        if (!isMounted()) {
          return;
        }
        setUnread(data.unread);
        errorCount.current = 0;
      })
      .catch((error) => {
        if (errorCount.current >= MAX_ERRORS) {
          log.error(`Max ${MAX_ERRORS} status fetch errors exceeded. Stopping notification poll`, error);
          fixedDelay.stop();
        } else {
          log.error('Error fetching notification status', error);
        }
        errorCount.current += 1;
      });
  };

  useEffect(() => {
    if (system.isUserLoggedIn()) {
      fixedDelay.start(fetchData, 30 * 1000, 0);
    }
  }, []);

  if (!system.isUserLoggedIn()) {
    return null;
  }

  return (
    <ButtonPopover
      tooltip="Notifications"
      ref={popoverRef}
      icon={() => (
        <StyledBadge
          badgeContent={unread}
          max={99}
          color="error"
        >
          <NotificationsIcon />
        </StyledBadge>
      )}
      buttonProps={{
        color: buttonColor
      }}
      popoverProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right'
          // vertical: 56,
          // horizontal: 'left'
        },
        transformOrigin: {
          // horizontal: 'right'
          vertical: 'top',
          horizontal: 'right'
        }
      }}
    >
      <NotificationContent setUnread={setUnread} />
    </ButtonPopover>
  );
};

NotificationPopover.propTypes = {
  buttonColor: PropTypes.string
};

export default NotificationPopover;
