import React from 'react';

import {
  Box,
} from '@material-ui/core';

import PropTypes from 'prop-types';
import { isArrayLike } from 'lodash';

const FlexList = ({ items, keyCallback, separator, component, margin = 1 }) => {
  if (!isArrayLike(items)) {
    return null;
  }

  if (items.length === 0) {
    return null;
  }

  return (
    <Box display="flex" flexWrap="wrap" marginLeft={-margin}>
      {items.map((item, index) => (
        <Box marginLeft={margin} key={keyCallback ? keyCallback(item) : index}>
          {component ? React.createElement(component, { item }) : item}
          {(index < items.length - 1) && separator}
        </Box>
      ))}
    </Box>
  );
};

FlexList.propTypes = {
  items: PropTypes.array,
  keyCallback: PropTypes.func,
  separator: PropTypes.node,
  component: PropTypes.any,
  margin: PropTypes.number,
};

export default FlexList;
