import React from 'react';
import { makeStyles } from '@material-ui/core';

import PropTypes from 'prop-types';
import { resolveHref } from 'src/components/Router';
import { useCompositionContext } from 'src/composition';

const DEFAULT_LOGO = '/static/composition/checkit/images/checkit-logo-horizontal-light-54.png';

const useStyles = makeStyles((theme) => ({
  small: {
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },

  large: {
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  }
}));

const Logo = ({ largeOnly = false, ...rest }) => {
  const composition = useCompositionContext();

  const classes = useStyles();

  const image = composition.options.logo || DEFAULT_LOGO;
  const imageSmall = largeOnly ? null : composition.options.logoSmall;

  const largeClassName = imageSmall ? classes.large : null;

  return (
    <>
      {imageSmall && <img alt="Logo" src={resolveHref(imageSmall)} {...rest} className={classes.small} />}
      <img alt="Logo" src={resolveHref(image)} {...rest} className={largeClassName} />
    </>
  );
};

Logo.propTypes = {
  largeOnly: PropTypes.bool
};

export default Logo;
