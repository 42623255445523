/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React, { useRef } from 'react';
import {
  Avatar,
  Box,
  ListItemIcon,
  MenuList,
  MenuItem,
  Typography,
  makeStyles,
  withStyles
} from '@material-ui/core';
import {
  SupervisedUserCircleOutlined as CustomerIcon,
} from '@material-ui/icons';

import { SharedDashboard as SharedDashboardIcon } from 'src/icons';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { grey } from '@material-ui/core/colors';

import PropTypes from 'prop-types';
import clsx from 'clsx';
import { get } from 'lodash';

import { trimToNull } from 'src/utils/core';
import { ButtonPopover } from 'src/components/core';

const useStyles = makeStyles((theme) => ({
  notFound: {
    textAlign: 'center',
    fontSize: '1.2em',
    fontWeight: 500,
    fontStyle: 'italic',
    color: grey[500],
    padding: theme.spacing(2)
  },

  menuContainer: {
    // width: '30ch',
    minWidth: 100,
    maxWidth: 400,
    maxHeight: 600,
    overflow: 'auto'
  },

  buttonRoot: {
    // borderRadius: 4,
    // color: theme.palette.getContrastText(grey[400]),
    textTransform: 'none',
    // backgroundColor: grey[400],
    // '&:hover': {
    //   backgroundColor: grey[500],
    // },
  },

  sharedMarked: {
    color: grey[400]
  },

  defaultMarker: {
    color: grey[400]
  },

  listItemIcon: {
    minWidth: 30,
    color: grey[500]
  },

  label: {
    maxWidth: 130,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  avatar: {
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    fontSize: '0.8rem'
  }
}));

const StyledMenuItem = withStyles(() => ({
  root: {
    // borderBottom: '1px solid green'
  },
}))(MenuItem);

const firstLetter = (name = '') => {
  const value = trimToNull(name);
  return !value ? '' : value[0].toUpperCase();
};

const CustomerPicker = ({ summaries = [], selectedId, onSelect }) => {
  const classes = useStyles();

  const showLegend = summaries.find((e) => e.shared || e.dflt) != null;

  return (
    <Box className={classes.menuContainer}>
      {(!summaries || summaries.length === 0)
        ? (
          <Box className={classes.notFound}>
            No items found
          </Box>
        ) : (
          <>
            <MenuList variant="selectedMenu" autoFocusItem>
              {summaries.map((summary) => (
                <StyledMenuItem key={summary.id} selected={summary.id == selectedId} onClick={() => onSelect(summary.id)}>
                  <ListItemIcon className={classes.listItemIcon}>
                    {/* <CustomerIcon fontSize="small" /> */}
                    <Avatar className={classes.avatar}>
                      {firstLetter(summary.name)}
                    </Avatar>
                  </ListItemIcon>
                  <Typography variant="inherit" noWrap>
                    {summary.name}
                  </Typography>
                </StyledMenuItem>
              ))}
            </MenuList>
            {showLegend && (
              <Box px={2} style={{ backgroundColor: grey[50] }}>
                <span className={classes.sharedMarked}>* <i>Shared</i></span>
                <span className={classes.sharedMarked}>{', \u2713'}<i>Default</i></span>
              </Box>
            )}
          </>
        )}
    </Box>
  );
};

CustomerPicker.propTypes = {
  onSelect: PropTypes.func,
  selectedId: PropTypes.any,
  summaries: PropTypes.array
};

const CustomerPickerPopover = ({
  summaries = [],
  selectedId,
  onOpen,
  onSelect,
  classes,
  ...rest
}) => {
  const baseClasses = useStyles();
  const popoverRef = useRef();

  const handleSelect = (id) => {
    if (popoverRef.current) {
      popoverRef.current.close();
    }
    onSelect(id);
  };

  const handleOpen = () => {
    if (onOpen) {
      onOpen();
    }
  };

  const selected = summaries.find((summary) => summary.id == selectedId);
  const buttonLabel = get(selected, 'name', 'Customer');
  // const Icon = get(selected, 'shared', false) ? SharedDashboardIcon : DashboardIcon;

  const sharedButtonProps = {
    size: 'small',
    variant: 'outlined',
    startIcon: <CustomerIcon style={{ color: grey[600] }} fontSize="small" />,
    classes: {
      root: baseClasses.buttonRoot
    }
  };

  return (
    <ButtonPopover
      ref={popoverRef}
      onOpen={handleOpen}
      buttonLabel={() => (
        <Typography variant="inherit" className={clsx(baseClasses.label, get(classes, 'label'))}>
          {buttonLabel}
        </Typography>
      )}
      buttonProps={{
        ...sharedButtonProps,
        endIcon: <ExpandMoreIcon fontSize="small" />,
      }}
      popoverProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left'
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left'
        }
      }}
    >
      <CustomerPicker summaries={summaries} selectedId={selectedId} onSelect={handleSelect} {...rest} />
    </ButtonPopover>
  );
};

CustomerPickerPopover.propTypes = {
  onOpen: PropTypes.func,
  onSelect: PropTypes.func,
  selectedId: PropTypes.any,
  summaries: PropTypes.array,
  classes: PropTypes.object
};

export default CustomerPickerPopover;
