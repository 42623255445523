/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'src/components/Router';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Backdrop,
  Box,
  Button,
  Link,
  CircularProgress,
  Hidden,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
  makeStyles,
} from '@material-ui/core';

import {
  Menu as MenuIcon,
  MenuOpen as MenuOpenIcon,
  Close as CloseIcon,
  Build as BuildIcon,
  Close as ExitIcon,
  HelpOutline as HelpIcon,
} from '@material-ui/icons';

import Logo from 'src/components/Logo';
import ProfilePopover from 'src/components/ProfilePopover';
import NotificationPopover from 'src/components/NotificationPopover';
import { useSystemContext } from 'src/service/System';
import { useIsMounted } from 'src/utils/react';
import { useCompositionContext } from 'src/composition';


const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    width: 60,
    height: 60
  },

  proxy: {
    position: 'fixed',
    left: '50%',
    transform: 'translate(-50%, 0)',
    zIndex: 2000,
    borderWidth: '0 2px 2px 2px',
    borderStyle: 'solid',
    borderColor: '#b7332c',
    backgroundColor: '#f44336',
    color: theme.palette.getContrastText('#f44336'),
    textTransform: 'none',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    '&:hover': {
      backgroundColor: '#dd3e33',
    }
    // backgroundColor: 'red',
  },

  proxyLabel: {
    marginLeft: 20,
    minWidth: '140px',
    maxWidth: '220px',
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },

}));

const TopBar = ({ className, onMobileNavOpen, onDesktopNavToggle, isDesktopNavOpen, admin = false, ...rest }) => {
  const [waitingBackdrop, setWaitingBackdrop] = useState(false);

  const classes = useStyles();
  const system = useSystemContext();
  const isMounted = useIsMounted();
  const composition = useCompositionContext();
  const navigate = useNavigate();

  const { help } = composition.options;

  const unproxy = () => {
    setWaitingBackdrop(true);
    navigate('/unproxy');
    setWaitingBackdrop(false);
  };

  const proxyUser = system.isProxy() ? system.getUser() : null;

  const appBarColor = admin ? 'secondary' : 'primary';
  // const appBarIconColor = admin ? 'inherit' : 'primary';
  const appBarIconColor = 'inherit';

  return (
    <>
      <Backdrop open={waitingBackdrop} className={classes.backdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {proxyUser && (
        <Button
          type="button"
          size="small"
          className={classes.proxy}
          onClick={unproxy}
          endIcon={<CloseIcon />}
        >
          <Typography
            variant="body2"
            color="inherit"
            noWrap
            className={classes.proxyLabel}
          >
            <strong>{proxyUser.firstName} {proxyUser.lastName}</strong>
          </Typography>
        </Button>
      )}
      <AppBar
        className={clsx(classes.root, className)}
        elevation={3}
        color={appBarColor}
        {...rest}
      >
        <Toolbar>
          <Hidden lgUp>
            <IconButton color={appBarIconColor} onClick={onMobileNavOpen}>
              <MenuIcon />
            </IconButton>
          </Hidden>

          <Hidden mdDown>
            <IconButton color={appBarIconColor} onClick={onDesktopNavToggle}>
              {isDesktopNavOpen ? <MenuOpenIcon /> : <MenuIcon />}
            </IconButton>
          </Hidden>

          <Box ml={1}>
            <RouterLink to="/">
              <Logo style={{ maxHeight: 54 }} />
            </RouterLink>
          </Box>

          <Box flexGrow={1} />
          {admin && (
            <Box mr={0.5}>
              <Tooltip title="Exit Admin">
                <Button
                  size="small"
                  variant="outlined"
                  color={appBarIconColor}
                  component={RouterLink}
                  to="/"
                  endIcon={<ExitIcon fontSize="small" />}
                >
                  Exit <Hidden xsDown>Admin</Hidden>
                </Button>
              </Tooltip>
            </Box>
          )}
          {!admin && (
            <>
              {help && (
                <Tooltip title="Help">
                  <IconButton
                    color={appBarIconColor}
                    onClick={() => {
                      window.open(help, '_blank');
                    }}
                  >
                    <HelpIcon />
                  </IconButton>
                </Tooltip>
              )}
              {system.hasAuthority('ADMINISTRATION_VIEW') && (
                <Tooltip title="Admin View">
                  <IconButton
                    color={appBarIconColor}
                    component={RouterLink}
                    to="/admin/users"
                  >
                    <BuildIcon />
                  </IconButton>
                </Tooltip>
              )}
            </>
          )}
          <NotificationPopover />
          <ProfilePopover buttonColor={appBarIconColor} />
        </Toolbar>
      </AppBar>
    </>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
  onDesktopNavToggle: PropTypes.func,
  isDesktopNavOpen: PropTypes.bool,
  admin: PropTypes.bool
};

export default TopBar;
