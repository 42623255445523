import React from 'react';
import { Link } from '@material-ui/core';
import { useCompositionContext } from 'src/composition';

function Copyright() {
  const composition = useCompositionContext();

  const compCopyright = composition.options.copyright;

  if (compCopyright) {
    return React.isValidElement(compCopyright) ? compCopyright : React.createElement(compCopyright);
  }

  return (
    <>
      &copy; {new Date().getFullYear()}
      {' '}
      <Link color="inherit" href="https://www.check-it.ca/">
        Check-It Solutions
      </Link>
    </>
  );
}

export default Copyright;
