/* eslint-disable react/jsx-tag-spacing */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-indent */
/* eslint-disable max-len */
import React from 'react';
import { SvgIcon } from '@material-ui/core';
import PropTypes from 'prop-types';

const Icon = ({ style, ...rest }) => {
  return (
    <SvgIcon {...rest} style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinejoin: 'round', strokeMiterlimit: '2', ...style }}>
      <g id="Layer3" transform="matrix(1,0,0,1,0,-5)">
        <g>
            <path d="M21,17C21,14.792 19.208,13 17,13L7,13C4.792,13 3,14.792 3,17C3,19.208 4.792,21 7,21L17,21C19.208,21 21,19.208 21,17ZM16,14.75C17.242,14.75 18.25,15.758 18.25,17C18.25,18.242 17.242,19.25 16,19.25C14.758,19.25 13.75,18.242 13.75,17C13.75,15.758 14.758,14.75 16,14.75Z"/>
        </g>
    </g>
    </SvgIcon>
  );
};

Icon.propTypes = {
  style: PropTypes.object
};

export default Icon;
