import { createStyles, makeStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => createStyles({
  '@global': {
    // '*': {
    //   boxSizing: 'border-box',
    //   margin: 0,
    //   padding: 0,
    // },
    html: {
      '-webkit-font-smoothing': 'antialiased',
      '-moz-osx-font-smoothing': 'grayscale',
      height: '100%',
      width: '100%'
    },
    body: {
      backgroundColor: '#f4f6f8',
      height: '100%',
      width: '100%'
    },
    a: {
      textDecoration: 'none'
    },
    'a.MuiLink-root:hover': {
      cursor: 'pointer'
    },
    '#root': {
      height: '100%',
      width: '100%'
    },
    '.detailLabel': {
      fontWeight: 500
    },
    // '.detailIcon': {
    //   color: theme.palette.secondary.main
    // },
    '.tableCompact': {
      '& .MuiTableCell-root': {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1)
      }
    },
    '.tableNoBorders': {
      '& .MuiTableCell-root': {
        border: 'none'
      },
    },
    '.tableHeaderFill': {
      '& .MuiTableHead-root .MuiTableCell-root': {
        backgroundColor: grey[50]
      },
    },
    '.tableNoLastBorder': {
      '& .MuiTableBody-root .MuiTableRow-root:last-child .MuiTableCell-root': {
        borderColor: 'transparent'
      },
    },
    '.tableCellNotFound': {
      fontSize: '1rem',
      fontWeight: 500,
      fontStyle: 'italic',
      color: grey[500],
      paddingLeft: theme.spacing(2),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1)
    },
    // '.tableTransparentLastBorder': {
    //   '& .MuiTableBody-root .MuiTableRow-root:last-child .MuiTableCell-root': {
    //     borderColor: 'transparent'
    //   },
    // },
    '.dividerMarginTop': {
      marginTop: theme.spacing(1),
    },
    '.cardHeaderFill': {
      backgroundColor: grey[50]
    }
  }
}));

const GlobalStyles = () => {
  useStyles();

  return null;
};

export default GlobalStyles;
