/* eslint-disable no-bitwise */
export default (intValue, length) => {
  const value = intValue || 0;

  const bytes = [];
  let shift = (length - 1) * 8;
  for (let i = 0; i < length; i++) {
    bytes[i] = (value >>> shift) & 0xFF;
    shift -= 8;
  }
  return bytes;
};
