/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';

import {
  Box,
  Button,
  Container,
  IconButton,
  InputAdornment,
  Paper,
  Link,
  Typography,
  Tooltip,
  Grid,
  Avatar,
  makeStyles
} from '@material-ui/core';
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
  LockOutlined as LockOutlinedIcon
} from '@material-ui/icons';

import * as Yup from 'yup';
import { Formik, useFormikContext } from 'formik';

import { Link as RouterLink, useNavigate, useLocation } from 'src/components/Router';
import Page from 'src/components/Page';
import { useSystemContext } from 'src/service/System';
import Copyright from 'src/components/Copyright';
import TermsAndConditions from 'src/components/TermsAndConditions';
import { FormikTextField, FormProgress } from 'src/components/form';
import log from 'src/support/Logger';
import { SnackbarAlert } from 'src/components/core';
import ForgotPasswordDialog from './ForgotPasswordDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },

  loginBox: {
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3)
  },

  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  }
}));

const LoginForm = () => {
  // const [errorMessage, setErrorMessage] = useState(null);
  const navigate = useNavigate();
  const system = useSystemContext();
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(null);
  const [snackbar, setSnackbar] = useState(null);

  const { state } = useLocation();

  useEffect(() => {
    system.reset();
  }, []);

  return (
    <Formik
      initialValues={
        {
          // username: 'demo@devias.io',
          // password: 'Password123'
        }
      }
      validationSchema={Yup.object().shape({
        username: Yup.string()
          .max(255)
          .required('Username is required'),

        password: Yup.string()
          .max(255)
          .required('Password is required')
      })}
      onSubmit={(values, actions) => {
        system
          .login(values.username, values.password)
          .then(() => {
            const to = (state && state.successPath && !state.successPath.startsWith("/login")) ? state.successPath : '/app/dashboard';
            // log.info('Successfully logged in. Nav to', to);
            navigate(to, { replace: true });
          })
          .catch((error) => {
            log.info('Login error', error);
            // setErrorMessage('Error logging in');
            actions.setFieldError('username', 'Invalid username/password');
            actions.setSubmitting(false);
          });
      }}
    >
      {(props) => {
        const { handleSubmit, isSubmitting } = props;

        const fieldType = showPassword ? 'text' : 'password';

        const passwordEndAdornment = (
          <InputAdornment position="end">
            <Tooltip title={`${showPassword ? 'Hide' : 'Show'} Password`}>
              <IconButton
                tabIndex="-1"
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
                onMouseDown={(event) => event.preventDefault()}
                edge="end"
              >
                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton>
            </Tooltip>
          </InputAdornment>
        );

        return (
          <>
              <SnackbarAlert
                {...snackbar}
                ignoreClickaway
                onClose={() => setSnackbar(null)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              />

              <ForgotPasswordDialog
                open={showForgotPassword != null}
                focus="forgotName"
                onCancel={() => {
                  setShowForgotPassword(null);
                }}
                onSave={() => {
                  setShowForgotPassword(null);
                  setSnackbar({ message: 'Password Reset Sent', severity: 'success' });
                }}
              />

            <form onSubmit={handleSubmit} autoComplete="off" autoCapitalize="off">
              <Paper elevation={1} style={{ position: 'relative' }}>
                {isSubmitting && <FormProgress />}

                <Box className={classes.loginBox}>
                  <Box mb={2} display="flex" flexDirection="row" justifyContent="center">
                    <Box display="flex" flexDirection="column">
                      <Avatar className={classes.avatar}>
                        <LockOutlinedIcon />
                      </Avatar>
                      <Typography component="h1" variant="h5">
                        Sign in
                      </Typography>
                    </Box>
                  </Box>
                  {/*
                  {errorMessage != null && (
                    <Alert severity="error">{errorMessage}</Alert>
                  )}
                  */}
                  <FormikTextField name="username" label="Username" fullWidth margin="normal" />

                  <FormikTextField
                    type={fieldType}
                    name="password"
                    label="Password"
                    fullWidth
                    InputProps={{ endAdornment: passwordEndAdornment }}
                  />

                  <Box my={2}>
                    <Button color="primary" disabled={isSubmitting} fullWidth size="large" type="submit" variant="contained">
                      Sign in now
                    </Button>
                  </Box>

                  <Grid container>
                    <Grid item xs>
                      <Typography color="textSecondary" variant="body1">
                        <Link href="#" variant="body2" onClick={() => setShowForgotPassword({})}>
                          Forgot password?
                        </Link>
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography color="textSecondary" variant="body1">
                        Don&apos;t have an account?{' '}
                        <Link component={RouterLink} to="/signup/register" variant="h6">
                          Sign up
                        </Link>
                      </Typography>
                    </Grid>
                  </Grid>
                  <Box mt={4}>
                    <Typography variant="body2" color="textSecondary" align="center">
                      <Copyright />
                      (<TermsAndConditions />)
                    </Typography>
                  </Box>
                </Box>
              </Paper>
            </form>
          </>
        );
      }}
    </Formik>
  );
};

const LoginView = () => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Login">
      {/* <Box display="flex" flexDirection="column" height="100%" justifyContent="center"> */}
      <Container maxWidth="sm">
        <LoginForm />
      </Container>
      {/* </Box> */}
    </Page>
  );
};

export default LoginView;
