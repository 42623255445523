/**
 * View that will navigate to another view on render. Useful for
 * reinitializing pages you want to effectively reload.
 */
import React from 'react';
import { useLocation, Navigate } from 'src/components/Router';

const NavigateReload = () => {
  const { state } = useLocation();

  return <Navigate to={state.to} replace />;
};

export default NavigateReload;
