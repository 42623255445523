/* eslint-disable react/jsx-tag-spacing */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-indent */
/* eslint-disable max-len */
import React from 'react';
import { SvgIcon } from '@material-ui/core';
import PropTypes from 'prop-types';

const Icon = ({ style, ...rest }) => {
  return (
    <SvgIcon {...rest} style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinecap: 'round', strokeMiterlimit: '1.5', ...style }}>
      <g id="Layer1">
        <g transform="matrix(1,0,0,0.961705,8.88178e-16,0.114884)">
            <g transform="matrix(0.923077,0,0,0.923077,-9.92308,-7.37647)">
                <rect x="14" y="11.241" width="6.5" height="6.759" style={{ fill: 'none', stroke: 'currentColor', strokeWidth: '2.21px' }}/>
            </g>
            <g transform="matrix(1,0,0,1.03982,-8,-6.87829)">
                <rect x="13" y="11.5" width="2" height="2"/>
            </g>
        </g>
        <g transform="matrix(1,0,0,0.961705,12,0.114884)">
            <g transform="matrix(0.923077,0,0,0.923077,-9.92308,-7.37647)">
                <rect x="14" y="11.241" width="6.5" height="6.759" style={{ fill: 'none', stroke: 'currentColor', strokeWidth: '2.21px' }}/>
            </g>
            <g transform="matrix(1,0,0,1.03982,-8,-6.87829)">
                <rect x="13" y="11.5" width="2" height="2"/>
            </g>
        </g>
        <g transform="matrix(1,0,0,0.961705,1.77636e-15,12.1149)">
            <g transform="matrix(0.923077,0,0,0.923077,-9.92308,-7.37647)">
                <rect x="14" y="11.241" width="6.5" height="6.759" style={{ fill: 'none', stroke: 'currentColor', strokeWidth: '2.21px' }}/>
            </g>
            <g transform="matrix(1,0,0,1.03982,-8,-6.87829)">
                <rect x="13" y="11.5" width="2" height="2"/>
            </g>
        </g>
        <g transform="matrix(1.33333,0,0,1.33333,6,9.33333)">
            <rect x="10.5" y="2" width="1.5" height="1.5"/>
        </g>
        <g transform="matrix(0.666667,0,0,0.666667,14,12.6667)">
            <rect x="10.5" y="2" width="1.5" height="1.5"/>
        </g>
        <g transform="matrix(1.33333,0,0,1.33333,6,17.3333)">
            <rect x="10.5" y="2" width="1.5" height="1.5"/>
        </g>
        <g transform="matrix(0.666667,0,0,1.33333,12,10.3333)">
            <rect x="10.5" y="2" width="1.5" height="1.5"/>
        </g>
        <g transform="matrix(1.33333,0,0,1.33333,3,11.3333)">
            <rect x="10.5" y="2" width="1.5" height="1.5"/>
        </g>
        <g transform="matrix(1.33333,0,0,1.33333,1,12.3333)">
            <rect x="10.5" y="2" width="1.5" height="1.5"/>
        </g>
        <g transform="matrix(1.33333,0,0,0.666667,4,15.6667)">
            <rect x="10.5" y="2" width="1.5" height="1.5"/>
        </g>
        <g transform="matrix(1.33333,0,0,0.666667,-1,15.6667)">
            <rect x="10.5" y="2" width="1.5" height="1.5"/>
        </g>
        <g transform="matrix(1.33333,0,0,0.666667,-6,9.66667)">
            <rect x="10.5" y="2" width="1.5" height="1.5"/>
        </g>
        <g transform="matrix(1.64706,0,0,1.33333,-17.2353,-4)">
            <rect x="17.75" y="12" width="4.25" height="1.5"/>
        </g>
        <g transform="matrix(0.666667,0,0,2,4,-1)">
            <rect x="10.5" y="2" width="1.5" height="1.5"/>
        </g>
        <g transform="matrix(1.33333,0,0,2,6,12)">
            <rect x="10.5" y="2" width="1.5" height="1.5"/>
        </g>
        <g transform="matrix(1.33333,0,0,2.33333,-3,2.33333)">
            <rect x="10.5" y="2" width="1.5" height="1.5"/>
        </g>
        <g transform="matrix(1.33333,0,0,3.33333,-3,8.83333)">
            <rect x="10.5" y="2" width="1.5" height="1.5"/>
        </g>
        <g transform="matrix(1.11111,0,0,1.33333,0.666667,-5.66667)">
            <rect x="3" y="12.5" width="4.5" height="1.5"/>
        </g>
        <g transform="matrix(0.555556,0,0,0.666667,1.08333,3.66667)">
            <rect x="3" y="12.5" width="4.5" height="1.5"/>
        </g>
        <g transform="matrix(0.666667,0,0,2,11,-6)">
            <rect x="3" y="12.5" width="4.5" height="1.5"/>
        </g>
        <g transform="matrix(0.666667,0,0,1.33333,15,2.33333)">
            <rect x="3" y="12.5" width="4.5" height="1.5"/>
        </g>
        <g transform="matrix(0.666667,0,0,0.666667,13.5,12.6667)">
            <rect x="3" y="12.5" width="4.5" height="1.5"/>
        </g>
    </g>
    </SvgIcon>
  );
};

Icon.propTypes = {
  style: PropTypes.object
};

export default Icon;
