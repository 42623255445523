/* eslint-disable max-len */
import React from 'react';
import { SvgIcon } from '@material-ui/core';

const Icon = (props) => {
  return (
    <SvgIcon {...props}>
      <g id="Cloud">
        <path d="M5.67,8.963C3.195,9.761 1.408,12.115 1.408,14.886C1.408,17.984 3.481,20.569 6.5,21L17,21L17,18.985L6.5,19C4.581,18.62 3.413,16.893 3.413,14.886C3.413,12.608 5.21,10.75 7.452,10.71C7.658,8.107 9.921,5.415 12.691,5.415C15.597,5.415 18.446,8.349 17.841,11.505C17.774,11.851 17.917,11.16 17.841,11.505L17.853,11.507L17.849,11.526C19.56,11.921 20.773,13.445 20.773,15.202C20.773,17.286 19.084,18.985 17,18.985L17,21C20.202,21 22.798,18.404 22.798,15.202C22.798,13.08 21.646,11.179 19.876,10.167C19.717,6.5 16.559,3.57 12.691,3.57C9.258,3.57 6.383,5.879 5.67,8.963Z" />
      </g>
      <g id="Layer2">
        <g transform="matrix(0.839785,0,0,1,-0.403529,5.46573)">
          <g transform="matrix(8,0,0,8,5.98807,12.275)">
            <path d="M0.55,-0.292L0.744,-0.234C0.731,-0.18 0.71,-0.134 0.682,-0.098C0.654,-0.061 0.619,-0.034 0.578,-0.015C0.536,0.003 0.484,0.013 0.419,0.013C0.342,0.013 0.278,0.001 0.229,-0.021C0.179,-0.044 0.137,-0.084 0.101,-0.141C0.065,-0.198 0.047,-0.271 0.047,-0.359C0.047,-0.478 0.079,-0.569 0.142,-0.633C0.205,-0.696 0.294,-0.728 0.409,-0.728C0.499,-0.728 0.57,-0.71 0.622,-0.673C0.673,-0.637 0.712,-0.581 0.737,-0.505L0.542,-0.462C0.535,-0.484 0.528,-0.5 0.52,-0.51C0.508,-0.527 0.493,-0.54 0.475,-0.549C0.457,-0.558 0.437,-0.563 0.415,-0.563C0.364,-0.563 0.326,-0.542 0.299,-0.502C0.279,-0.472 0.269,-0.425 0.269,-0.361C0.269,-0.281 0.281,-0.227 0.305,-0.198C0.329,-0.168 0.363,-0.153 0.407,-0.153C0.449,-0.153 0.481,-0.165 0.503,-0.189C0.524,-0.213 0.54,-0.247 0.55,-0.292Z" style={{ fillRule: 'nonzero' }} />
          </g>
          <g transform="matrix(8,0,0,8,12.2107,12.275)">
            <path d="M0.045,-0.357C0.045,-0.474 0.078,-0.565 0.143,-0.63C0.208,-0.695 0.299,-0.728 0.415,-0.728C0.534,-0.728 0.626,-0.696 0.69,-0.632C0.755,-0.568 0.787,-0.479 0.787,-0.363C0.787,-0.28 0.773,-0.211 0.745,-0.157C0.717,-0.104 0.676,-0.062 0.623,-0.032C0.57,-0.003 0.503,0.012 0.424,0.012C0.343,0.012 0.276,-0.001 0.223,-0.026C0.17,-0.052 0.128,-0.093 0.095,-0.148C0.062,-0.204 0.045,-0.274 0.045,-0.357ZM0.267,-0.356C0.267,-0.284 0.28,-0.232 0.307,-0.201C0.334,-0.169 0.37,-0.153 0.417,-0.153C0.464,-0.153 0.501,-0.169 0.527,-0.2C0.553,-0.231 0.566,-0.286 0.566,-0.366C0.566,-0.434 0.552,-0.483 0.525,-0.514C0.498,-0.545 0.461,-0.561 0.415,-0.561C0.37,-0.561 0.334,-0.545 0.307,-0.513C0.28,-0.482 0.267,-0.429 0.267,-0.356Z" style={{ fillRule: 'nonzero' }} />
          </g>
        </g>
        <g transform="matrix(5,0,0,5,15.4308,18.1864)">
          <path d="M0.623,-0L0.026,-0C0.033,-0.059 0.054,-0.114 0.089,-0.166C0.123,-0.218 0.188,-0.279 0.284,-0.35C0.342,-0.393 0.379,-0.426 0.396,-0.449C0.412,-0.471 0.42,-0.493 0.42,-0.513C0.42,-0.535 0.412,-0.553 0.396,-0.569C0.38,-0.584 0.359,-0.592 0.335,-0.592C0.31,-0.592 0.289,-0.584 0.273,-0.568C0.257,-0.552 0.246,-0.524 0.24,-0.483L0.041,-0.5C0.049,-0.556 0.063,-0.599 0.084,-0.631C0.105,-0.662 0.134,-0.686 0.172,-0.703C0.21,-0.72 0.263,-0.728 0.33,-0.728C0.4,-0.728 0.454,-0.72 0.493,-0.704C0.532,-0.688 0.562,-0.664 0.585,-0.631C0.607,-0.598 0.618,-0.561 0.618,-0.52C0.618,-0.476 0.605,-0.434 0.58,-0.395C0.554,-0.355 0.508,-0.311 0.44,-0.264C0.4,-0.236 0.374,-0.217 0.36,-0.206C0.347,-0.194 0.331,-0.18 0.313,-0.162L0.623,-0.162L0.623,-0Z" style={{ fillRule: 'nonzero' }} />
        </g>
      </g>
    </SvgIcon>
  );
};

export default Icon;
