/* eslint-disable react/jsx-tag-spacing */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-indent */
/* eslint-disable max-len */
import React from 'react';
import { SvgIcon } from '@material-ui/core';
import PropTypes from 'prop-types';

const Icon = ({ style, ...rest }) => {
  return (
    <SvgIcon {...rest} style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinecap: 'round', strokeLinejoin: 'round', strokeMiterlimit: '1.5', ...style }}>
      <g id="Layer1">
        <path d="M10.075,21.145C5.36,20.397 1.75,16.309 1.75,11.384C1.75,5.929 6.179,1.5 11.634,1.5C17.089,1.5 21.518,5.929 21.518,11.384C21.518,11.429 21.518,11.473 21.517,11.518C21.059,10.905 20.51,10.364 19.889,9.915C19.742,9.084 19.472,8.295 19.099,7.567L15.714,7.567C15.768,7.873 15.816,8.187 15.857,8.508C15.742,8.503 15.626,8.5 15.509,8.5C15.118,8.5 14.734,8.53 14.358,8.588C14.312,8.237 14.256,7.896 14.193,7.567L9.075,7.567C8.894,8.507 8.779,9.542 8.743,10.634L10.275,10.634C9.818,11.08 9.418,11.584 9.087,12.134L8.743,12.134C8.749,12.33 8.758,12.525 8.77,12.718C8.288,13.707 8.018,14.818 8.018,15.991C8.018,16.627 8.097,17.245 8.247,17.836C8.117,17.481 7.996,17.102 7.888,16.701L5.153,16.701C6.099,17.852 7.345,18.747 8.771,19.265C9.108,19.959 9.55,20.592 10.075,21.145ZM7.242,12.134L3.283,12.134C3.381,13.23 3.689,14.266 4.169,15.201L7.554,15.201C7.385,14.249 7.277,13.217 7.242,12.134ZM7.554,7.567L4.169,7.567C3.689,8.502 3.381,9.538 3.283,10.634L7.242,10.634C7.277,9.551 7.385,8.519 7.554,7.567ZM8.938,3.444C7.442,3.952 6.136,4.871 5.153,6.067L7.888,6.067C8.14,5.134 8.457,4.317 8.818,3.654C8.858,3.582 8.898,3.512 8.938,3.444ZM11.634,3C11.872,3 12.094,3.114 12.308,3.292C12.609,3.543 12.882,3.913 13.133,4.372C13.395,4.853 13.628,5.424 13.825,6.067L9.443,6.067C9.64,5.424 9.873,4.853 10.135,4.372C10.386,3.913 10.659,3.543 10.96,3.292C11.174,3.114 11.396,3 11.634,3ZM14.33,3.444C14.37,3.512 14.41,3.582 14.45,3.654C14.811,4.317 15.127,5.134 15.38,6.067L18.115,6.067C17.132,4.871 15.826,3.952 14.33,3.444Z"/>
    </g>
    <g id="Layer2">
        <g transform="matrix(1.29412,0,0,1.22222,-3.41138,-1.72222)">
            <ellipse cx="14.75" cy="14.5" rx="4.25" ry="4.5" style={{ fill: 'none', stroke: 'currentColor', strokeWidth: '1.19px' }}/>
        </g>
        <g transform="matrix(1,0,0,1,0.323145,0)">
            <path d="M15.677,13L15.677,16.093L14.419,17.5" style={{ fill: 'none', stroke: 'currentColor', strokeWidth: '1.5px' }}/>
        </g>
    </g>
    </SvgIcon>
  );
};

Icon.propTypes = {
  style: PropTypes.object
};

export default Icon;
