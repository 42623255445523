export default (timestamp, ttlMillis) => {
  if (!timestamp) {
    return true;
  }

  if (!ttlMillis) {
    return false;
  }

  return (timestamp + ttlMillis) < Date.now();
};
