/* eslint-disable react/jsx-tag-spacing */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-indent */
/* eslint-disable max-len */
import React from 'react';
import { SvgIcon } from '@material-ui/core';
import PropTypes from 'prop-types';

const Icon = ({ style, ...rest }) => {
  return (
    <SvgIcon {...rest} style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinecap: 'round', strokeLinejoin: 'round', strokeMiterlimit: '1.5', ...style }}>
      <g id="HomeSensor" transform="matrix(0.888889,0,0,0.888889,1.33333,1.33333)">
        <g transform="matrix(1.23722,0,0,1.23722,-1.60947,-5.09394)">
            <path d="M11,4.514C16.379,10.463 17,12.689 17,16C17,19.311 14.311,22 11,22C7.689,22 5,19.311 5,16C5,12.689 5.854,10.494 11,4.514Z" style={{ fill: 'none', stroke: 'currentColor', strokeWidth: '1.82px' }}/>
        </g>
        <g transform="matrix(0.641197,0,0,0.641197,-0.849015,8.52778)">
            <text x="11.115px" y="11.556px" style={{ fontFamily: '\'Arial-Black\', \'Arial Black\', sans-serif', fontWeight: '900', fontSize: '12px' }}>pH</text>
        </g>
    </g>
    </SvgIcon>
  );
};

Icon.propTypes = {
  style: PropTypes.object
};

export default Icon;
