import React from 'react';

import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { defaultTo, get } from 'lodash';

import TimeZoneSelect from './TimeZoneSelect';

const FormikTimeZoneSelect = ({ name, showAllName, ...rest }) => {
  const {
    values,
    errors,
    touched,
    submitCount,
    handleBlur,
    handleChange
  } = useFormikContext();

  const errorText = (submitCount > 0 || get(touched, name)) ? get(errors, name) : null;

  return (
    <TimeZoneSelect
      name={name}
      onBlur={handleBlur}
      onChange={handleChange}
      errorText={errorText}
      value={defaultTo(get(values, name), '')}
      showAll={defaultTo(get(values, showAllName), false)}
      showAllName={showAllName}
      onShowAllChange={handleChange}
      {...rest}
    />
  );
};

FormikTimeZoneSelect.propTypes = {
  name: PropTypes.string.isRequired,
  showAllName: PropTypes.string.isRequired,
};

export default FormikTimeZoneSelect;
