/*
 * Returned a parsed integer or null if not a number.
 */
export default (val) => {
  if (val == null || val === '') {
    return null;
  }

  const parsed = parseInt(val, 10);

  return Number.isNaN(parsed) ? null : parsed;
};
