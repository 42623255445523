/* eslint-disable max-len */
import React from 'react';
import { SvgIcon } from '@material-ui/core';

const Icon = (props) => {
  return (
    <SvgIcon {...props}>
      <g transform="matrix(1.09375,0,0,1.09375,1.35938,0.265625)">
        <ellipse cx="9.75" cy="10.5" rx="8.25" ry="8" style={{ fillOpacity: '0', stroke: 'currentColor', strokeWidth: '1.83px' }} />
      </g>
      <g transform="matrix(1,0,0,1,1.5,0)">
        <path d="M13,5L6.5,13L9.5,13L8,18.5L14.5,11.125L11.5,11L13,5Z" />
      </g>
      <g transform="matrix(1,0,0,1,-0.5,0.625)">
        <path d="M5,12.375L7.5,12.375L9,10.5L5,10.5L5,12.375Z" />
      </g>
      <g transform="matrix(-1,0,0,-1,24.5,22.875)">
        <path d="M5,11.75L7.5,11.75L9,9.875L5,9.875L5,11.75Z" />
      </g>
    </SvgIcon>
  );
};

export default Icon;
