import React, { useState, useEffect, useRef } from 'react';

import {
  CircularProgress,
  TextField,
  makeStyles,
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { useErrorHandler } from 'react-error-boundary';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import { CancelToken } from 'axios';

import { Bucket } from 'src/utils/core';
import { useStateRef, useIsMounted } from 'src/utils/react';
// import { defaultTo } from 'lodash';

const useStyles = makeStyles(() => ({
  loadingProgress: {
    color: grey[500],
  },

  textField: {
    // '& .MuiAutocomplete-endAdornment': {
    //   border: '1px solid green'
    // }
  }
}));

const BaseAsyncAutocomplete = ({
  label,
  required,
  error,
  helperText,
  variant = 'outlined',
  load,
  debounceInterval = 300,
  value,
  onBlur,
  onInputChange,
  TextFieldProps,
  ...rest
}) => {
  const [search, setSearch, searchRef] = useStateRef();
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const isMounted = useIsMounted();
  const handleError = useErrorHandler();
  const processIdRef = useRef();
  const cancelSourceRef = useRef(null);
  const previousIdsRef = useRef(new Bucket());
  const classes = useStyles();

  const update = (processId, updateData) => {
    if (!isMounted() || processId !== processIdRef.current) {
      // Ignore if a newer request is pending.
      return;
    }

    cancelSourceRef.current = null;
    setOptions(updateData);
    setLoading(false);
  };

  const performLoad = (searchArg) => {
    previousIdsRef.current.push(processIdRef.current);
    const processId = uuid();
    processIdRef.current = processId;
    setLoading(true);

    if (cancelSourceRef.current) {
      cancelSourceRef.current.cancel('Operation cancelled by the user.');
    }

    const source = CancelToken.source();
    cancelSourceRef.current = source;

    // setOptions([]);

    load(searchArg, source)
      .then((response) => {
        update(processId, response.data);
      }).catch((loadError) => {
        // axios.isCancel does not work. __CANCEL__ is not set on the error
        // !axios.isCancel(error)) {
        if (!previousIdsRef.current.includes(processId)) {
          handleError(loadError);
        }
      });
  };

  useEffect(() => {
    if (!open) {
      setOptions([]);
    } else {
      performLoad(search);
    }
  }, [open]);

  const onChangeDebounced = (searchText) => {
    performLoad(searchText);
  };

  // const searchTouched = useRef(false);
  const debounceTimerRef = useRef();

  const debounce = () => {
    if (debounceTimerRef.current) {
      clearTimeout(debounceTimerRef.current);
    }
    debounceTimerRef.current = setTimeout(() => onChangeDebounced(searchRef.current), debounceInterval);
  };

  const handleInputChange = (event, inputText) => {
    setSearch(inputText);
    if (open) {
      debounce();
    }
    if (onInputChange) {
      onInputChange(event, inputText);
    }
  };

  return (
    <Autocomplete
      onInputChange={handleInputChange}
      open={open}
      value={typeof value === 'undefined' ? null : value}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant={variant}
          required={required}
          error={error}
          helperText={helperText}
          onBlur={onBlur}
          className={classes.textField}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={24} className={classes.loadingProgress} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          {...TextFieldProps}
        />
      )}
      {...rest}
    />
  );
};

BaseAsyncAutocomplete.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  variant: PropTypes.string,
  load: PropTypes.func.isRequired,
  debounceInterval: PropTypes.number,
  value: PropTypes.any,
  onBlur: PropTypes.func,
  onInputChange: PropTypes.func,
  TextFieldProps: PropTypes.object
};

export default BaseAsyncAutocomplete;
