/* eslint-disable react/jsx-tag-spacing */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-indent */
/* eslint-disable max-len */
import React from 'react';
import { SvgIcon } from '@material-ui/core';
import PropTypes from 'prop-types';

const Icon = ({ style, ...rest }) => {
  return (
    <SvgIcon {...rest} style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinejoin: 'round', strokeMiterlimit: '2', ...style }}>
      <g id="IndustrialSensor">
        <g transform="matrix(0.875,0,0,0.875,0.5,0.25)">
            <path d="M11,22L11,15L20,15L20,6.5C20,4.016 17.984,2 15.5,2L8.5,2C6.016,2 4,4.016 4,6.5C4,6.5 4,17.5 4,17.5C4,19.984 6.016,22 8.5,22L11,22ZM16.5,7.25L16.5,11.75C16.5,12.716 15.716,13.5 14.75,13.5C14.75,13.5 9.25,13.5 9.25,13.5C8.284,13.5 7.5,12.716 7.5,11.75C7.5,11.75 7.5,7.25 7.5,7.25C7.5,6.284 8.284,5.5 9.25,5.5L14.75,5.5C15.716,5.5 16.5,6.284 16.5,7.25Z"/>
        </g>
        <g transform="matrix(0.199005,0,0,0.214925,10.3333,10.8)">
            <path d="M8.375,36.292L13.958,36.292L13.958,30.708L8.375,30.708L8.375,36.292ZM8.375,47.458L13.958,47.458L13.958,41.875L8.375,41.875L8.375,47.458ZM8.375,25.125L13.958,25.125L13.958,19.542L8.375,19.542L8.375,25.125ZM19.542,36.292L58.625,36.292L58.625,30.708L19.542,30.708L19.542,36.292ZM19.542,47.458L58.625,47.458L58.625,41.875L19.542,41.875L19.542,47.458ZM19.542,19.542L19.542,25.125L58.625,25.125L58.625,19.542L19.542,19.542Z" style={{ fillRule: 'nonzero' }}/>
        </g>
    </g>
    </SvgIcon>
  );
};

Icon.propTypes = {
  style: PropTypes.object
};

export default Icon;
