/* eslint-disable no-unused-vars */
import React, { useState, useContext, useRef } from 'react';

import {
  IconButton,
  Popover,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Box,
  Button,
  Chip,
  Link,
  Typography,
  Avatar,
  makeStyles
} from '@material-ui/core';
import {
  AccountCircle as AccountCircleIcon,
  Person as PersonIcon,
  Edit as EditIcon
} from '@material-ui/icons';
import { grey } from '@material-ui/core/colors';

import PropTypes from 'prop-types';
import { useNavigate } from 'src/components/Router';
import { useSystemContext } from 'src/service/System';
import AuthImage from 'src/components/core/AuthImage';
import { ButtonPopover } from 'src/components/core';
import TermsAndConditions from 'src/components/TermsAndConditions';
import Copyright from 'src/components/Copyright';

const useStyles = makeStyles((theme) => ({
  root: {},
  accountIcon: {
    fontSize: 40
    // color: grey[300]
  },
  accountAvatar: {
    width: 40,
    height: 40,
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.getContrastText(theme.palette.primary.main),
  },
  popoverCard: {
    minWidth: '240px'
  },
  popoverAccountIcon: {
    width: 64,
    height: 64,
    color: grey[400]
  },
  popoverTitle: {
    paddingTop: theme.spacing(1),
    textAlign: 'center'
  },
  termsLink: {
    color: '#666666',
    fontSize: '0.7rem'
  },
  popoverAvatarIcon: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  avatar: {
    height: 64,
    width: 64
  },
  avatarImage: {
    // maxWidth: 100,
    maxWidth: 96,
    maxHeight: 96,
  },
  buttonAvatarImage: {
    // maxWidth: 100,
    maxWidth: 60,
    maxHeight: 60,
  },
}));

const ProfilePopover = ({ buttonColor = 'inherit' }) => {
  const classes = useStyles();
  const popoverRef = useRef();

  const handleClose = () => popoverRef.current && popoverRef.current.close();

  const system = useSystemContext();

  const navigate = useNavigate();

  if (!system.isUserLoggedIn()) {
    return null;
  }

  const user = system.getUser();

  const handleLogout = () => {
    system.logout();
  };

  const handleEditProfile = () => {
    navigate('/app/account');
    handleClose();
  };

  const name = [user.firstName, user.lastName].join(' ');
  const email = user.email || '';

  let initials = '';
  if (user.firstName) {
    initials += user.firstName[0].toUpperCase();
  }
  if (user.lastName) {
    initials += user.lastName[0].toUpperCase();
  }

  let avatarContent = null;
  if (user.image) {
    avatarContent = <AuthImage src={`/files/${user.image}`} alt="Profile Image" className={classes.buttonAvatarImage} />;
  } else if (initials) {
    avatarContent = initials;
  }
  // avatarContent = null;

  return (
    <ButtonPopover
      ref={popoverRef}
      // icon={() => <AccountCircleIcon className={classes.accountIcon} />}
      icon={() => <Avatar className={classes.accountAvatar}>{avatarContent}</Avatar>}
      buttonProps={{
        color: buttonColor,
        style: { padding: 8 }
      }}
      popoverProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right'
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'right'
        }
      }}
    >
      <Card className={classes.popoverCard}>
        <Box display="flex" justifyContent="center" pt={2}>
          <Avatar className={classes.avatar}>
            {user.image
              && <AuthImage src={`/files/${user.image}`} alt="Profile Image" className={classes.avatarImage} />}
          </Avatar>
        </Box>
        <CardHeader
          className={classes.popoverTitle}
          title={name}
          subheader={email}
        />
        <Box display="flex" justifyContent="center" pb={1}>
          <Chip
            label="Edit Profile"
            variant="outlined"
            color="primary"
            icon={<EditIcon />}
            onClick={handleEditProfile}
          //   clickable="true"
          //   component={NavLink}
          //   to="/app/account"
          />
        </Box>
        {/* <Divider />
        <CardContent>Content</CardContent> */}

        <Divider />

        <CardContent style={{ textAlign: 'center' }}>
          <Button variant="outlined" onClick={handleLogout}>
            Logout
          </Button>
        </CardContent>

        <Divider />

        <Box display="flex" flexDirection="column" justifyContent="center" p={1}>
          <Typography variant="body2" color="textSecondary" align="center">
            <Copyright />
          </Typography>
          <Typography variant="body2" color="textSecondary" align="center">
            <TermsAndConditions />
          </Typography>
        </Box>
      </Card>
    </ButtonPopover>
  );
};

ProfilePopover.propTypes = {
  buttonColor: PropTypes.string
};

export default ProfilePopover;
