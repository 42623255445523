import { useEffect } from 'react';

import { get } from 'lodash';

import { useNavigate, useLocation } from 'src/components/Router';

const useSnackbarLocationState = (setSnackbar, navigateTo) => {
  const location = useLocation();
  const navigate = useNavigate();

  const fetchPath = () => {
    const prefix = process.env.PUBLIC_URL;
    let path = location.pathname;
    if (prefix && path.indexOf(prefix) === 0) {
      path = path.substring(prefix.length);
    }
    return path;
  };

  useEffect(() => {
    const snackbarState = get(location, 'state.snackbar');
    if (snackbarState) {
      setSnackbar(snackbarState);
      // Navigate to remove snackbar from location state.
      navigate(navigateTo || fetchPath(), { replace: true });
    }
  }, []);
};

export default useSnackbarLocationState;
