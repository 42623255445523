/* eslint-disable react/jsx-tag-spacing */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-indent */
/* eslint-disable max-len */
import React from 'react';
import { SvgIcon } from '@material-ui/core';
import PropTypes from 'prop-types';

const Icon = ({ style, ...rest }) => {
  return (
    <SvgIcon {...rest} style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinejoin: 'round', strokeMiterlimit: '2', ...style }}>
      <g id="Layer1">
        <path d="M2.005,12L2.01,6C2.01,4.9 2.9,4 4,4L20,4C21.1,4 22,4.9 22,6L22,18C22,19.1 21.1,20 20,20L10,20L10,18L20,18L20,8L12,13L4,8L4,12L2.005,12ZM12,11L20,6L4,6L12,11Z" style={{ fillRule: 'nonzero' }} />
      </g>
      <g transform="matrix(1,0,0,1,-3.05704,6.08922)">
        <g id="Layer2">
          <path d="M7.049,14.919L7.049,11.942L4.049,11.942L4.049,9.887L7.049,9.887L7.049,6.911L9.049,6.911L9.049,9.887L12.057,9.887L12.057,11.942L9.049,11.942L9.049,14.919L7.049,14.919Z" style={{ fillRule: 'nonzero' }} />
        </g>
      </g>
    </SvgIcon>
  );
};

Icon.propTypes = {
  style: PropTypes.object
};

export default Icon;
