/* eslint-disable react/jsx-tag-spacing */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-indent */
/* eslint-disable max-len */
import React from 'react';
import { SvgIcon } from '@material-ui/core';
import PropTypes from 'prop-types';

const Icon = ({ style, ...rest }) => {
  return (
    <SvgIcon {...rest} style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinecap: 'round', strokeLinejoin: 'round', strokeMiterlimit: '1.5', ...style }}>
      <g transform="matrix(1.09091,0,0,1.125,1.36364,0.1875)">
        <ellipse cx="9.75" cy="10.5" rx="8.25" ry="8" style={{ fillOpacity: '0', stroke: 'currentColor', strokeWidth: '1.8px' }}/>
    </g>
    <path d="M7,12C7,12 9.5,7.5 12,12C14.5,16.5 17,12 17,12" style={{ fill: 'none', stroke: 'currentColor', strokeWidth: '2px', strokeLinecap: 'square' }}/>
    </SvgIcon>
  );
};

Icon.propTypes = {
  style: PropTypes.object
};

export default Icon;
