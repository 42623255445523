/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Link,
  Divider,
  Paper,
  makeStyles,
  CardActions
} from '@material-ui/core';
import {
  // Notifications as NotificationsIcon
  NotificationsNone as NotificationsIcon
} from '@material-ui/icons';
import { grey } from '@material-ui/core/colors';

import { useErrorHandler } from 'react-error-boundary';

import { useSystemContext } from 'src/service/System';
import { useTheme } from '@material-ui/styles';

import { NavLink, useNavigate } from 'src/components/Router';
import { useIsMounted } from 'src/utils/react';
import { get, pick, includes } from 'lodash';
import {
  FormikTextField,
  FormProgress,
  FormikSelect,
  FormikCheckbox,
  formMergeErrors,
  FormikTimeZoneSelect,
  FormikCheckboxes
} from 'src/components/form';
import { DetailItem, RelativeCard, CardHeaderStyled, GlobalMessages } from 'src/components/core';
import { set } from 'nprogress';

const UnproxyView = ({ id }) => {

  const system = useSystemContext();

  useEffect(() => {
    system.unproxy();
    // setMessages({ message: 'Check it out', severity: 'success' });
  }, []);

  return (
    <>
    </>
  );
};

export default UnproxyView;
