/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';

import {
  Box,
  Divider,
  Drawer,
  Hidden,
  Typography,
  makeStyles,
} from '@material-ui/core';

import PropTypes from 'prop-types';

import { useLocation } from 'src/components/Router';
import { useCompositionContext } from 'src/composition';
import TermsAndConditions from 'src/components/TermsAndConditions';
import Copyright from 'src/components/Copyright';

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },

  desktopDrawer: {
    zIndex: 1000,
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  }
}));

const NavBar = ({
  onMobileClose = () => {},
  openMobile = false,
  openDesktop = true,
  children
}) => {
  const classes = useStyles();
  const location = useLocation();

  const composition = useCompositionContext();

  const compFooter = composition.options.drawerFooter;

  let drawerFooter;
  if (compFooter) {
    drawerFooter = React.isValidElement(compFooter) ? compFooter : React.createElement(compFooter);
  } else {
    drawerFooter = (
      <>
        <Typography variant="body2" color="textSecondary" align="center">
          <Copyright />
        </Typography>
        <Typography variant="body2" color="textSecondary" align="center">
          <TermsAndConditions />
        </Typography>
      </>
    );
  }

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      {children}
      <Box flexGrow={1} />
      <Divider />
      <Box p={1}>
        {drawerFooter}
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open={openDesktop}
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
  openDesktop: PropTypes.bool,
  children: PropTypes.any
};

export default NavBar;
