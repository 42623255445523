/* eslint-disable no-unused-vars */
import { get } from 'lodash';

import log from 'src/support/Logger';

const processMenu = (containers, path, items) => {
  if (!items) {
    return;
  }

  const parent = containers.get(path);

  items.forEach((item) => {
    if (item.id && item.items) {
      const newPath = `${path}/${item.id}`;
      let container = containers.get(newPath);
      if (!container) {
        container = { title: item.title, icon: item.icon, sort: item.sort, items: [] };
        parent.items.push(container);
        containers.set(newPath, container);
      }
      processMenu(containers, newPath, item.items);
    } else {
      // Menu item
      parent.items.push(item);
    }
  });
};

export default (...menus) => {
  const root = {
    items: []
  };
  const containers = new Map();
  containers.set('root', root);

  if (menus) {
    menus.forEach((menu) => {
      processMenu(containers, 'root', menu);
    });
  }

  return root.items;
};
