/* eslint-disable react/jsx-tag-spacing */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-indent */
/* eslint-disable max-len */
import React from 'react';
import { SvgIcon } from '@material-ui/core';
import PropTypes from 'prop-types';

const Icon = ({ style, ...rest }) => {
  return (
    <SvgIcon {...rest} style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeMiterlimit: '1.5', ...style }}>
      <g id="IndustrialSensor" transform="matrix(1,0,0,1,0.5,1.2735)">
        <g transform="matrix(1,0,0,0.965812,-1,-1.34616)">
            <path d="M21,6.827C21,5.543 19.993,4.5 18.752,4.5L6.248,4.5C5.007,4.5 4,5.543 4,6.827L4,18.173C4,19.457 5.007,20.5 6.248,20.5L18.752,20.5C19.993,20.5 21,19.457 21,18.173L21,6.827Z" style={{ fill: 'none', stroke: 'currentColor', strokeWidth: '2.03px', strokeDasharray: '6.1,2.03,0,0', strokeDashoffset: '2.75' }}/>
        </g>
        <g transform="matrix(0.73515,0,0,0.73515,4.2376,-6.2327)">
            <circle cx="17" cy="17" r="1"/>
        </g>
        <g transform="matrix(0.73515,0,0,0.73515,-6.2327,-6.2327)">
            <circle cx="17" cy="17" r="1"/>
        </g>
        <g transform="matrix(0.73515,0,0,0.73515,-6.2327,2.7376)">
            <circle cx="17" cy="17" r="1"/>
        </g>
        <g transform="matrix(0.73515,0,0,0.73515,4.2376,2.7376)">
            <circle cx="17" cy="17" r="1"/>
        </g>
    </g>
    </SvgIcon>
  );
};

Icon.propTypes = {
  style: PropTypes.object
};

export default Icon;
