import { useState, useRef } from 'react';

const useStateRef = (initialValue) => {
  const [state, setState] = useState(initialValue);
  const ref = useRef(state);

  const setStateRef = (value) => {
    ref.current = value;
    return setState(value);
  };

  return [state, setStateRef, ref];
};

export default useStateRef;
