/* eslint-disable react/jsx-tag-spacing */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-indent */
/* eslint-disable max-len */
import React from 'react';
import { SvgIcon } from '@material-ui/core';
import PropTypes from 'prop-types';

const Icon = ({ style, ...rest }) => {
  return (
    <SvgIcon {...rest} style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinecap: 'round', strokeLinejoin: 'round', strokeMiterlimit: '1.5', ...style }}>
      <g id="IndustrialSensor">
        <g transform="matrix(0.705882,0,0,0.965812,0.176471,-0.0726562)">
            <path d="M21,6.307C21,5.31 19.892,4.5 18.527,4.5L6.473,4.5C5.108,4.5 4,5.31 4,6.307L4,18.693C4,19.69 5.108,20.5 6.473,20.5L18.527,20.5C19.892,20.5 21,19.69 21,18.693L21,6.307Z" style={{ fill: 'none', stroke: 'currentColor', strokeWidth: '2.36px' }}/>
        </g>
        <g transform="matrix(1,0,0,1,2,-1.77636e-15)">
            <path d="M11,20L11,22L18,22L18,12.5" style={{ fill: 'none', stroke: 'currentColor', strokeWidth: '1px' }}/>
        </g>
        <g transform="matrix(1.22465e-16,2,-1,6.12323e-17,23,-40.5)">
            <path d="M22.5,12.5C22.5,12.224 22.388,12 22.25,12L21.75,12C21.612,12 21.5,12.224 21.5,12.5L21.5,15.5C21.5,15.776 21.612,16 21.75,16L22.25,16C22.388,16 22.5,15.776 22.5,15.5L22.5,12.5Z"/>
        </g>
        <g transform="matrix(1.22465e-16,2,-1,6.12323e-17,23,-23.5)">
            <path d="M22.5,12.5C22.5,12.224 22.388,12 22.25,12L21.75,12C21.612,12 21.5,12.224 21.5,12.5L21.5,15.5C21.5,15.776 21.612,16 21.75,16L22.25,16C22.388,16 22.5,15.776 22.5,15.5L22.5,12.5Z"/>
        </g>
        <g transform="matrix(1,0,0,0.525347,0.5,8.53455)">
            <path d="M19.5,8.5L19.5,13.5" style={{ fill: 'none', stroke: 'currentColor', strokeWidth: '3.13px' }}/>
        </g>
        <g transform="matrix(1.23515,0,0,1.23515,-9.99755,-11.9975)">
            <circle cx="17" cy="17" r="1"/>
        </g>
    </g>
    <g id="Layer1">
        <g transform="matrix(1.11111,0,0,1,-2.5,0)">
            <path d="M22.5,8.25C22.5,7.56 21.996,7 21.375,7L19.125,7C18.504,7 18,7.56 18,8.25L18,13.75C18,14.44 18.504,15 19.125,15L21.375,15C21.996,15 22.5,14.44 22.5,13.75L22.5,8.25Z"/>
        </g>
        <g transform="matrix(1,0,0,1,0,1.75)">
            <path d="M19,1.75L19,6.5" style={{ fill: 'none', stroke: 'currentColor', strokeWidth: '1px' }}/>
        </g>
        <g transform="matrix(1,0,0,1,2,1.75)">
            <path d="M19,3L19,6.5" style={{ fill: 'none', stroke: 'currentColor', strokeWidth: '1px' }}/>
        </g>
    </g>
    </SvgIcon>
  );
};

Icon.propTypes = {
  style: PropTypes.object
};

export default Icon;
