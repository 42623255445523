/* eslint-disable react/jsx-tag-spacing */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-indent */
/* eslint-disable max-len */
import React from 'react';
import { SvgIcon } from '@material-ui/core';
import PropTypes from 'prop-types';

const Icon = ({ style, ...rest }) => {
  return (
    <SvgIcon {...rest} style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinejoin: 'round', strokeMiterlimit: '2', ...style }}>
      <g id="IndustrialSensor">
        <path d="M20,6.5C20,4.016 17.984,2 15.5,2L8.5,2C6.016,2 4,4.016 4,6.5C4,6.5 4,17.5 4,17.5C4,19.984 6.016,22 8.5,22L15.5,22C17.984,22 20,19.984 20,17.5L20,6.5ZM12,20.5L9,18L15,18L12,20.5ZM12,14.5L15,17L9,17L12,14.5ZM16.5,7.25L16.5,11.75C16.5,12.716 15.716,13.5 14.75,13.5C14.75,13.5 9.25,13.5 9.25,13.5C8.284,13.5 7.5,12.716 7.5,11.75C7.5,11.75 7.5,7.25 7.5,7.25C7.5,6.284 8.284,5.5 9.25,5.5L14.75,5.5C15.716,5.5 16.5,6.284 16.5,7.25Z"/>
    </g>
    </SvgIcon>
  );
};

Icon.propTypes = {
  style: PropTypes.object
};

export default Icon;
