/* eslint-disable react/jsx-tag-spacing */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-indent */
/* eslint-disable max-len */
import React from 'react';
import { SvgIcon } from '@material-ui/core';
import PropTypes from 'prop-types';

const Icon = ({ style, ...rest }) => {
  return (
    <SvgIcon {...rest} style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinejoin: 'round', strokeMiterlimit: '2', ...style }}>
      <g transform="matrix(0.793103,-9.71272e-17,9.71272e-17,0.793103,6.45689,4.05172)">
        <g transform="matrix(3.80063e-17,0.62069,-0.999998,6.12322e-17,23.25,11.1379)">
            <path d="M3,12.562L3,9.938L11.458,9.938L11.458,7.5L17.5,11.25L11.458,15L11.458,12.562L3,12.562Z"/>
        </g>
        <g transform="matrix(3.80063e-17,-0.62069,-0.999998,-6.12322e-17,28.25,18.3621)">
            <path d="M3,12.562L3,9.938L11.458,9.938L11.458,7.5L17.5,11.25L11.458,15L11.458,12.562L3,12.562Z"/>
        </g>
    </g>
    <g transform="matrix(1.2619,0,0,1.2619,-0.14881,-3.30952)">
        <path d="M9.05,17L4.77,17C5.76,16.5 7.47,16 9,16C9.11,16 9.23,16.01 9.34,16.01C9.68,15.28 10.27,14.68 10.98,14.2C10.25,14.07 9.56,14 9,14C6.66,14 2,15.17 2,17.5L2,19L9,19L9,17.5C9,17.33 9.02,17.16 9.05,17ZM9,12C10.93,12 12.5,10.43 12.5,8.5C12.5,6.57 10.93,5 9,5C7.07,5 5.5,6.57 5.5,8.5C5.5,10.43 7.07,12 9,12ZM9,7C9.83,7 10.5,7.67 10.5,8.5C10.5,9.33 9.83,10 9,10C8.17,10 7.5,9.33 7.5,8.5C7.5,7.67 8.17,7 9,7Z" style={{ fillRule: 'nonzero' }}/>
    </g>
    </SvgIcon>
  );
};

Icon.propTypes = {
  style: PropTypes.object
};

export default Icon;
