import React from 'react';

import {
  Snackbar
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import PropTypes from 'prop-types';
import { isArray, first } from 'lodash';

const SnackbarAlert = ({
  open = true,
  message,
  onClose,
  autoHideDuration = 4000,
  severity,
  ignoreClickaway = false,
  ...rest
}) => {
  const displayMessage = isArray(message) ? first(message) : message;

  if (!open || !displayMessage) {
    return null;
  }

  const handleOnClose = (event, reason) => {
    if (ignoreClickaway && reason === 'clickaway') {
      return;
    }

    if (onClose) {
      onClose(event, reason);
    }
  };

  return (
    <Snackbar open={open} autoHideDuration={autoHideDuration} onClose={handleOnClose} {...rest}>
      <Alert elevation={6} variant="filled" onClose={handleOnClose} severity={severity}>
        {displayMessage}
      </Alert>
    </Snackbar>
  );
};

SnackbarAlert.propTypes = {
  open: PropTypes.bool,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onClose: PropTypes.func,
  autoHideDuration: PropTypes.number,
  severity: PropTypes.string,
  ignoreClickaway: PropTypes.bool,
};

export default SnackbarAlert;
