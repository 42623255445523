import React, { useState } from 'react';

import {
  IconButton,
  Tooltip,
} from '@material-ui/core';
import {
  AddBox as AddIcon,
} from '@material-ui/icons';

import PropTypes from 'prop-types';

import PickerDialog from './PickerDialog';

const PickerIconDialog = ({
  tooltip,
  icon = AddIcon,
  buttonProps,
  children,
  ...rest
}) => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const IconComponent = icon;

  return (
    <>
      <Tooltip title={tooltip}>
        <IconButton onClick={() => setOpen(true)} {...buttonProps}>
          <IconComponent />
        </IconButton>
      </Tooltip>
      <PickerDialog
        open={open}
        onClose={handleClose}
        {...rest}
      >
        {children}
      </PickerDialog>
    </>
  );
};

PickerIconDialog.propTypes = {
  tooltip: PropTypes.string,
  icon: PropTypes.elementType,
  buttonProps: PropTypes.object,
  children: PropTypes.node.isRequired,
};

export default PickerIconDialog;
