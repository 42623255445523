import React from 'react';

import PropTypes from 'prop-types';

import useItemIcon from './useItemIcon';

const ItemIcon = ({ item, defaultIcon, ...rest }) => {
  const { fetchItemIcon } = useItemIcon({ defaultIcon });

  const IconComponent = fetchItemIcon(item);

  return <IconComponent {...rest} />;
};

ItemIcon.propTypes = {
  item: PropTypes.object,
  defaultIcon: PropTypes.elementType
};

export default ItemIcon;
