/* eslint-disable react/jsx-tag-spacing */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-indent */
/* eslint-disable max-len */
import React from 'react';
import { SvgIcon } from '@material-ui/core';
import PropTypes from 'prop-types';

const Icon = ({ style, ...rest }) => {
  return (
    <SvgIcon {...rest} style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinecap: 'square', strokeLinejoin: 'round', strokeMiterlimit: '1.5', ...style }}>
      <g id="IndustrialSensor">
        <g transform="matrix(1,0,0,0.965812,-1,-1.34616)">
            <path d="M21,8.642L21,6.827C21,5.543 19.993,4.5 18.752,4.5L6.248,4.5C5.007,4.5 4,5.543 4,6.827L4,18.173C4,19.457 5.007,20.5 6.248,20.5L9,20.5" style={{ fill: 'none', stroke: 'currentColor', strokeWidth: '2.03px' }}/>
        </g>
        <g transform="matrix(0.73515,0,0,0.73515,4.2376,-6.2327)">
            <circle cx="17" cy="17" r="1"/>
        </g>
        <g transform="matrix(0.73515,0,0,0.73515,-6.2327,-6.2327)">
            <circle cx="17" cy="17" r="1"/>
        </g>
        <g transform="matrix(0.73515,0,0,0.73515,-6.2327,2.7376)">
            <circle cx="17" cy="17" r="1"/>
        </g>
    </g>
    <g transform="matrix(0.333333,0,0,0.333333,4,3)">
        <g id="Layer1">
            <path d="M33,21L51,21L51,27L33,27L33,21ZM33,33L51,33L51,39L33,39L33,33ZM33,45L51,45L51,51L33,51L33,45ZM21,21L27,21L27,27L21,27L21,21ZM21,33L27,33L27,39L21,39L21,33ZM21,45L27,45L27,51L21,51L21,45Z" style={{ fillRule: 'nonzero' }}/>
        </g>
    </g>
    </SvgIcon>
  );
};

Icon.propTypes = {
  style: PropTypes.object
};

export default Icon;
