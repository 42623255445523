/* eslint-disable react/jsx-tag-spacing */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-indent */
/* eslint-disable max-len */
import React from 'react';
import { SvgIcon } from '@material-ui/core';
import PropTypes from 'prop-types';

const Icon = ({ style, ...rest }) => {
  return (
    <SvgIcon {...rest} style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinecap: 'round', strokeLinejoin: 'round', strokeMiterlimit: '1.5', ...style }}>
      <g transform="matrix(1.05882,0,0,1.125,-1.23529,-2.0625)">
        <g id="IndustrialSensor">
            <path d="M21,6.827C21,5.543 19.892,4.5 18.527,4.5L6.473,4.5C5.108,4.5 4,5.543 4,6.827L4,18.173C4,19.457 5.108,20.5 6.473,20.5L18.527,20.5C19.892,20.5 21,19.457 21,18.173L21,6.827Z" style={{ fill: 'none', stroke: 'currentColor', strokeWidth: '1.83px' }}/>
        </g>
    </g>
    <g id="Layer2" transform="matrix(1.1768,0,0,1.1768,-1.00552,-0.87002)">
        <g transform="matrix(0.198465,0,0,0.198465,3.90132,3.73026)">
            <path d="M36,6L19.5,33L52.5,33L36,6Z" style={{ fillRule: 'nonzero' }}/>
        </g>
        <g transform="matrix(0.198465,0,0,0.198465,3.90132,3.73026)">
            <circle cx="52.5" cy="52.5" r="13.5"/>
        </g>
        <g transform="matrix(0.198465,0,0,0.198465,3.90132,3.73026)">
            <rect x="9" y="40.5" width="24" height="24" style={{ fillRule: 'nonzero' }}/>
        </g>
    </g>
    </SvgIcon>
  );
};

Icon.propTypes = {
  style: PropTypes.object
};

export default Icon;
