import React, { useState, useEffect } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Link,
  makeStyles
} from '@material-ui/core';

import PropTypes from 'prop-types';
import { useErrorHandler } from 'react-error-boundary';

import { FormProgress } from 'src/components/form';
import { useSystemContext } from 'src/service/System';
import { RawHtml } from 'src/components/core';
import { grey } from '@material-ui/core/colors';
import { defaultTo } from 'lodash';

const useStyles = makeStyles(() => ({
  dialog: {
    height: '100%'
  },

  loading: {
    textAlign: 'center',
    fontSize: '1rem',
    fontWeight: 'bold',
    color: grey[400]
  }
}));

const TermsAndConditionsDialog = ({ open, onClose, onConfirm }) => {
  const [loading, setLoading] = useState(true);
  const [termsHtml, setTermsHtml] = useState(null);

  const system = useSystemContext();
  const handleError = useErrorHandler();

  const classes = useStyles();

  useEffect(() => {
    if (open) {
      setLoading(true);
      const providerId = defaultTo(process.env.REACT_APP_PROVIDER_ID, 1);
      system
        .axios()
        .get(`/terms/view/${providerId}`)
        // .then((response) => {
        //   // Fake delay to test async loading.
        //   return new Promise((resolve) => {
        //     setTimeout(() => {
        //       resolve(response);
        //     }, 3000);
        //   });
        // })
        .then((response) => {
          setTermsHtml(response.data.data);
          setLoading(false);
        })
        .catch(handleError);
    }
  }, [open]);

  const handleAgree = () => {
    setLoading(true);
    system.axios()
      .post('/terms/agree')
      .then((response) => {
        system.setAgreedToTermsDate(response.data);
        setLoading(false);
        onConfirm(true);
      })
      .catch(handleError);
  };

  const handleClose = () => {
    if (onClose) { onClose(); }
  };

  if (!open) {
    return null;
  }

  return (
    <Dialog
      open
      onClose={handleClose}
      scroll="paper"
      aria-labelledby="terms-dialog-title"
      aria-describedby="terms-dialog-description"
      maxWidth="sm"
      fullWidth
      classes={{ paper: classes.dialog }}
    >
      {loading && <FormProgress />}
      <DialogTitle id="terms-dialog-title">Terms and Conditions</DialogTitle>
      <DialogContent dividers>
        {loading ? (
          <Box className={classes.loading}>
            Loading...
          </Box>
        ) : (
          <RawHtml html={termsHtml} />
        )}
      </DialogContent>
      <DialogActions>
        {onConfirm ? (
          <>
            <Button type="button" disabled={loading} onClick={() => onConfirm(false)} color="primary">
              Disagree
            </Button>
            <Button type="button" disabled={loading} onClick={handleAgree} color="primary">
              Agree
            </Button>
          </>
        ) : (
          <Button type="button" disabled={loading} onClick={handleClose} color="primary">
            Ok
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

TermsAndConditionsDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
};

const TermsAndConditions = ({ className }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Link component="button" type="button" color="inherit" onClick={() => setOpen(true)} className={className}>
        Terms and Conditions
      </Link>
      <TermsAndConditionsDialog open={open} onClose={() => setOpen(false)} />
    </>
  );
};

TermsAndConditions.propTypes = {
  className: PropTypes.string
};

export { TermsAndConditions, TermsAndConditionsDialog };
export default TermsAndConditions;
