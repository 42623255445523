/* eslint-disable no-unused-vars */
import React from 'react';

import {
  Box,
  Button,
  Divider,
  Icon,
  IconButton,
  List,
  Tooltip,
  Typography,
  makeStyles,
} from '@material-ui/core';
import {
  People as UsersIcon,
  Build as BuildIcon,
  Memory as CodeIcon,
  // Close as ExitIcon,
  HighlightOff as ExitIcon
} from '@material-ui/icons';
import {
  ExitLeft as ExitLeftIcon
} from 'src/icons';

import PropTypes from 'prop-types';

import { mergeMenu, formatMenu } from 'src/utils/menu';
import { useCompositionContext } from 'src/composition';
import { useSystemContext } from 'src/service/System';
import { Link as RouterLink } from 'src/components/Router';

import NavBar from './NavBar';
import NavItem from './NavItem';

const useStyles = makeStyles((theme) => ({
  adminIcon: {
    marginBottom: 5,
    fontSize: 36
  },

  adminHeader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: theme.palette.primary.main,
    padding: theme.spacing(2)
  }
}));

const AdminNavBar = ({ onMobileClose, openMobile, openDesktop }) => {
  const classes = useStyles();
  const system = useSystemContext();
  const composition = useCompositionContext();

  const items = formatMenu(
    mergeMenu(composition.fetchMenus('admin')),
    { filter: (item) => (
      (!item.feature || system.hasFeature(item.feature))
      && (!item.role || system.hasAuthority(item.role))
    ) }
  );

  return (
    <NavBar onMobileClose={onMobileClose} openMobile={openMobile} openDesktop={openDesktop}>
      <Box className={classes.adminHeader}>
        <BuildIcon color="secondary" className={classes.adminIcon} />
        <Box ml={1}>
          <Typography color="secondary" variant="h5">
            Administration
          </Typography>
        </Box>
      </Box>
      <Divider />
      <Box py={1} px={2}>
        <List>
          {items.map((item) => (
            <NavItem menuId="admin" key={item.title} {...item} />
          ))}
        </List>
      </Box>
    </NavBar>
  );
};

AdminNavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
  openDesktop: PropTypes.bool
};

export default AdminNavBar;
