/* eslint-disable no-bitwise */
import { fill } from 'lodash';

export default (value, length) => {
  const retValue = fill(Array(length), false);
  let bitFlags = value;
  if (!bitFlags) {
    return retValue;
  }

  for (let i = 0; i < length; i++) {
    if (bitFlags & 0x01) {
      retValue[i] = true;
    }
    bitFlags >>= 1;
  }

  return retValue;
};
