import React from 'react';

/*
  Renders a invisble submit button.

  This is a workaround so forms without a visible submit
  button within the form tags will submit on enter key.
*/
const HiddenSubmitButton = () => {
  return (
    <button
      type="submit"
      style={{
        position: 'absolute',
        visibility: 'hidden',
        border: 'none',
        width: 0,
        height: 0,
        padding: 0
      }}
    />
  );
};

export default HiddenSubmitButton;
