/* eslint-disable react/jsx-tag-spacing */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-indent */
/* eslint-disable max-len */
import React from 'react';
import { SvgIcon } from '@material-ui/core';
import PropTypes from 'prop-types';

const Icon = ({ style, ...rest }) => {
  return (
    <SvgIcon {...rest} style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinecap: 'round', strokeLinejoin: 'round', strokeMiterlimit: '1.5', ...style }}>
      <g id="Thermometer" transform="matrix(1,0,0,1,-3.5,0.888889)">
        <g transform="matrix(1,0,0,1,-1,-3)">
            <path d="M15.999,15C17.214,15.913 18,17.365 18,19C18,21.76 15.76,24 13,24C10.24,24 8,21.76 8,19C8,17.366 8.786,15.913 10,15C10,15 9.982,10.649 10,6C13,2 16,6 16,6C16,6 15.995,12.727 15.999,15Z" style={{ fill: 'none', stroke: 'currentColor', strokeWidth: '1.75px' }}/>
        </g>
        <g transform="matrix(0.916667,0,0,0.916667,0.0833333,0.416667)">
            <circle cx="13" cy="17" r="3"/>
        </g>
        <g transform="matrix(0.755845,0,0,1.04545,2.5866,-0.227273)">
            <path d="M13.478,5.74C13.478,5.332 13.019,5 12.454,5C11.889,5 11.43,5.332 11.43,5.74L11.43,15.26C11.43,15.668 11.889,16 12.454,16C13.019,16 13.478,15.668 13.478,15.26L13.478,5.74Z"/>
        </g>
    </g>
    <g transform="matrix(1.87498e-17,-0.827586,-0.913332,-1.07361e-16,28.275,20.4828)">
        <path d="M3,12.562L3,9.938L13.361,9.938L13.361,7.5L17.5,11.25L13.361,15L13.361,12.562L3,12.562Z"/>
    </g>
    </SvgIcon>
  );
};

Icon.propTypes = {
  style: PropTypes.object
};

export default Icon;
