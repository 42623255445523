export default (value, defaultValue = null) => {
  if (!value) {
    return defaultValue;
  }

  try {
    return JSON.parse(value);
  } catch (err) {
    // Ignore
  }
  return defaultValue;
};
