/* eslint-disable class-methods-use-this */
/* eslint-disable max-classes-per-file */
import { get, isArrayLike } from 'lodash';
import { isExpired } from 'src/utils/core';

const DEVICE_GROUPS_KEY = 'DEVICE_GROUPS';

const DEVICE_GROUP_KEY = 'DEVICE_GROUP';

class DeviceGroupService {
  constructor(id, system) {
    this.id = id;
    this._system = system;
  }

  updateDeviceGroups(ttlMillis) {
    return this._performUpdateDeviceGroups(ttlMillis);
  }

  getDeviceGroup() {
    return this._system.getAttribute(`${this.id}_${DEVICE_GROUP_KEY}`);
  }

  setDeviceGroup(deviceGroup) {
    return this._system.setAttribute(`${this.id}_${DEVICE_GROUP_KEY}`, deviceGroup);
  }

  getDeviceGroupId() {
    return get(this.getDeviceGroup(), 'id', null);
  }

  getPremises() {
    return this.getDeviceGroups(['PREMISE']);
  }

  getDeviceGroups(types) {
    let deviceGroups = get(this._getDeviceGroupsData(), 'data.deviceGroups') || [];

    if (isArrayLike(types)) {
      const typesSet = new Set(types);
      deviceGroups = deviceGroups.filter((e) => typesSet.has(e.deviceGroupType));
    }

    return deviceGroups;
  }

  _performUpdateDeviceGroups(ttlMillis) {
    if (ttlMillis) {
      const deviceGroupsData = this._getDeviceGroupsData();
      if (deviceGroupsData && !isExpired(deviceGroupsData.timestamp, ttlMillis)) {
        return Promise.resolve(deviceGroupsData.data);
      }
    }

    return this._system.axios()
      .get('/device/groups/search')
      .then((response) => {
        const { data } = response;
        this._setDeviceGroupsData({
          timestamp: Date.now(),
          data
        });
        return data;
      });
  }

  _getDeviceGroupsData() {
    let deviceGroupsData = this._system.getDataItem(`${this.id}_${DEVICE_GROUPS_KEY}`);
    if (deviceGroupsData) {
      return deviceGroupsData;
    }

    deviceGroupsData = this._system.getSessionItem(`${this.id}_${DEVICE_GROUPS_KEY}`);
    this._system.setDataItem(`${this.id}_${DEVICE_GROUPS_KEY}`, deviceGroupsData);
    return deviceGroupsData;
  }

  /**
   * Data stored in the following format:
   * {
   *  timestamp: 12312414142,
   *  data: {
   *   defaultDeviceGroup: {},
   *   deviceGroups: [...]
   *  }
   * }
   */
  _setDeviceGroupsData(deviceGroupsData) {
    this._system.setDataItem(`${this.id}_${DEVICE_GROUPS_KEY}`, deviceGroupsData);
    this._system.setSessionItem(`${this.id}_${DEVICE_GROUPS_KEY}`, deviceGroupsData);
  }
}

class DeviceGroupSystemPlugin {
  constructor(id = 'deviceGroup') {
    this.id = id;
  }

  onLogin(context) {
    return new DeviceGroupService(this.id, context.system)
      ._performUpdateDeviceGroups()
      .then((deviceGroupsData) => {
        if (deviceGroupsData.defaultDeviceGroup) {
          context.setAttribute(`${this.id}_${DEVICE_GROUP_KEY}`, deviceGroupsData.defaultDeviceGroup);
        } else {
          const deviceGroups = deviceGroupsData.deviceGroups || [];
          if (deviceGroups.length) {
            context.setAttribute(`${this.id}_${DEVICE_GROUP_KEY}`, deviceGroupsData.deviceGroups[0]);
          }
        }
      });
  }

  build(context) {
    return new DeviceGroupService(this.id, context.system);
  }
}

export default DeviceGroupSystemPlugin;
