/* eslint-disable react/jsx-tag-spacing */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-indent */
/* eslint-disable max-len */
import React from 'react';
import { SvgIcon } from '@material-ui/core';
import PropTypes from 'prop-types';

const Icon = ({ style, ...rest }) => {
  return (
    <SvgIcon {...rest} style={{ fillRule: 'evenodd', clipRule: 'evenodd', ...style }}>
      <g id="Layer1" transform="matrix(1.04878,0,0,1.04878,0.72561,-1.36665)">
        <g transform="matrix(0.103535,0,0,0.103535,-0.0176768,6.11869)">
            <path d="M15,5L193,5C198.523,5 203,9.477 203,15L203,113C203,118.523 198.523,123 193,123L15,123C9.477,123 5,118.523 5,113L5,15C5,9.477 9.477,5 15,5Z" style={{ fill: 'none', fillRule: 'nonzero', stroke: 'currentColor', strokeWidth: '10px' }}/>
        </g>
        <g transform="matrix(0.103535,0,0,0.103535,-0.0176768,6.11869)">
            <path d="M30,98L30,30L50,30L70,55L90,30L110,30L110,98L90,98L90,59L70,84L50,59L50,98L30,98ZM155,98L125,65L145,65L145,30L165,30L165,65L185,65L155,98Z" style={{ fillRule: 'nonzero' }}/>
        </g>
    </g>
    </SvgIcon>
  );
};

Icon.propTypes = {
  style: PropTypes.object
};

export default Icon;
