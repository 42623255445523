/* eslint-disable no-bitwise */
export default (bytes, separator = '') => {
  if (!bytes) {
    return '';
  }

  let result = '';
  for (let i = 0; i < bytes.length; i++) {
    if (i > 0 && separator) {
      result += separator;
    }
    const hex = (bytes[i] & 0xFF).toString(16);
    result += (hex.length === 2 ? hex : `0${hex}`);
  }
  return result.toUpperCase();
};
