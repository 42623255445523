export default (str, n) => {
  const retValue = [];

  if (str) {
    for (let i = 0; i < str.length; i += n) {
      retValue.push(str.substr(i, n));
    }
  }

  return retValue;
};
