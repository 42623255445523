/* eslint-disable class-methods-use-this */
/* eslint-disable no-unused-vars */
import React, { useContext, useState, useRef, useEffect } from 'react';

import PropTypes from 'prop-types';
import { isFunction, get, isArrayLike, concat } from 'lodash';
import { assertHasOwnProperty, CompareToBuilder } from 'src/utils/core';

const importComposition = (name) => {
  /*
   * JE Note: Something in ESLint blows up on dynamic imports.
   * Using a case statement as a workaround.
   */
  // return import(`./config/${COMPOSITION}.js`);
  switch (name) {
    case 'potash':
      return import('./config/potash/composition');
    case 'growlinc':
      return import('./config/growlinc/composition');
    case 'kb':
      return import('./config/kb/composition');
    case 'atg':
        return import('./config/atg/composition');
    default:
      return import('./config/checkit/composition');
  }
};

class CompositionWrapper {
  constructor(composition) {
    this.composition = composition;
    this.options = composition.options || {};
    this.resourceCache = new Map();
  }

  fetchResources() {
    return this.fetchArray('resources');
  }

  fetchResource(key) {
    if (!key) {
      return null;
    }

    const cached = this.resourceCache.get(key);
    if (cached) {
      return cached;
    }

    const resources = this.fetchResources();
    for (let i = 0; i < resources.length; i++) {
      const resource = get(resources[i], key);
      if (resource) {
        this.resourceCache.set(key, resource);
        return resource;
      }
    }

    return null;
  }

  fetchModules() {
    return get(this.composition, 'modules', []);
  }

  fetchWidgets() {
    return this.fetchArray('widgets').sort((a, b) => (
      new CompareToBuilder().appendString(a.name, b.name).toComparison()
    ));
  }

  fetchNotificationSubscriptions() {
    return this.fetchArray('notification.subscriptions').sort((a, b) => (
      new CompareToBuilder().appendString(a.name, b.name).toComparison()
    ));
  }

  fetchNotificationLogs() {
    return this.fetchArray('notification.logs');
  }

  fetchDevices() {
    return this.fetchArray('devices').sort((a, b) => (
      new CompareToBuilder().appendString(a.name, b.name).toComparison()
    ));
  }

  fetchMenus(name) {
    return this.fetchNamedArray('menus', name);
  }

  fetchRoutes(name) {
    return this.fetchNamedArray('routes', name);
    // return concat(
    //   this.fetchNamedArray('routes', name),
    //   this._fetchNamedArray(this.fetchWidgets(), 'routes', name),
    //   this._fetchNamedArray(this.fetchNotifications(), 'routes', name),
    // );
  }

  fetchNamedArray(sourceType, name) {
    return this._fetchNamedArray(this.fetchModules(), sourceType, name);
  }

  fetchArray(sourceType) {
    let retValue = [];
    this.fetchModules().forEach((module) => {
      const source = get(module, sourceType);
      const result = isFunction(source) ? source() : source;
      if (result) {
        retValue = retValue.concat(result);
      }
    });
    return retValue;
  }

  _fetchNamedArray(modules, sourceType, name) {
    let retValue = [];
    if (isArrayLike(modules)) {
      modules.forEach((module) => {
        const source = get(module, sourceType);
        const result = isFunction(source) ? source() : source;
        const items = get(result, name);
        if (items) {
          retValue = retValue.concat(items);
        }
      });
    }
    return retValue;
  }
}

const CompositionContext = React.createContext();

const CompositionProvider = ({ name, children }) => {
  const [composition, setComposition] = useState(null);

  useEffect(() => {
    importComposition(name)
      .then((module) => {
        setComposition(new CompositionWrapper(module.default));
      });
  }, []);

  if (composition == null) {
    return null;
  }

  return (
    <CompositionContext.Provider value={composition}>
      {children}
    </CompositionContext.Provider>
  );
};

CompositionProvider.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

const useCompositionContext = () => {
  return useContext(CompositionContext);
};

export { CompositionProvider, useCompositionContext };
