/* eslint-disable react/jsx-tag-spacing */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-indent */
/* eslint-disable max-len */
import React from 'react';
import { SvgIcon } from '@material-ui/core';
import PropTypes from 'prop-types';

const Icon = ({ style, ...rest }) => {
  return (
    <SvgIcon {...rest} style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinecap: 'round', strokeLinejoin: 'round', strokeMiterlimit: '1.5', ...style }}>
      <g id="HomeSensor" transform="matrix(0.888889,0,0,0.888889,1.33333,1.33333)">
        <g transform="matrix(1.05882,0,0,1.125,-1.23529,-2.0625)">
            <path d="M21,6.827C21,5.543 19.892,4.5 18.527,4.5L6.473,4.5C5.108,4.5 4,5.543 4,6.827L4,18.173C4,19.457 5.108,20.5 6.473,20.5L18.527,20.5C19.892,20.5 21,19.457 21,18.173L21,6.827Z" style={{ fill: 'none', stroke: 'currentColor', strokeWidth: '2.06px' }}/>
        </g>
        <g transform="matrix(1.25315,0,0,1.25315,-9.44796,2.71714)">
            <text x="11.115px" y="11.556px" style={{ fontFamily: '\'Arial-Black\', \'Arial Black\', sans-serif', fontWeight: '900', fontSize: '12px' }}>%</text>
        </g>
    </g>
    </SvgIcon>
  );
};

Icon.propTypes = {
  style: PropTypes.object
};

export default Icon;
