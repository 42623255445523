/* eslint-disable max-len */
import React from 'react';
import { SvgIcon } from '@material-ui/core';

const Icon = (props) => {
  return (
    <SvgIcon {...props}>
      <g id="Motion" transform="matrix(0.861781,0,0,0.861781,-0.834352,1.65937)">
        <g transform="matrix(0.609746,0,0,0.609746,5.16484,2.00852)">
          <path d="M19.673,7.992C21.277,7.992 22.59,6.679 22.59,5.075C22.59,3.471 21.277,2.158 19.673,2.158C18.069,2.158 16.756,3.471 16.756,5.075C16.756,6.679 18.069,7.992 19.673,7.992ZM14.423,28.263L15.881,21.846L18.944,24.763L18.944,33.513L21.86,33.513L21.86,22.575L18.798,19.658L19.673,15.283C21.569,17.471 24.485,18.929 27.694,18.929L27.694,16.013C24.923,16.013 22.59,14.554 21.423,12.513L19.965,10.179C19.381,9.304 18.506,8.721 17.485,8.721C17.048,8.721 16.756,8.867 16.319,8.867L8.735,12.075L8.735,18.929L11.652,18.929L11.652,13.971L14.277,12.95L11.944,24.763L4.798,23.304L4.215,26.221L14.423,28.263Z" style={{ fillRule: 'nonzero' }} />
        </g>
        <g transform="matrix(-0.837523,0,0,0.837523,28.9648,1.92587)">
          <g transform="matrix(1.30544,0,0,1.30544,-0.678886,-3.63777)">
            <path d="M5.072,8C4.37,9.216 4,10.596 4,12C4,13.404 4.37,14.784 5.072,16L5.876,15.536C5.255,14.461 4.928,13.241 4.928,12C4.928,10.759 5.255,9.539 5.876,8.464L5.072,8Z" />
          </g>
          <g transform="matrix(1.33445,0,0,1.33445,-1.027,-3.98589)">
            <path d="M3.808,6.264C2.631,7.945 2,9.948 2,12C2,14.052 2.631,16.055 3.808,17.736L4.567,17.205C3.499,15.679 2.926,13.862 2.926,12C2.926,10.138 3.499,8.321 4.567,6.795L3.808,6.264Z" />
          </g>
        </g>
        <g transform="matrix(0.837523,0,0,0.837523,1.00793,1.98251)">
          <g transform="matrix(1.30544,0,0,1.30544,-0.678886,-3.63777)">
            <path d="M5.072,8C4.37,9.216 4,10.596 4,12C4,13.404 4.37,14.784 5.072,16L5.876,15.536C5.255,14.461 4.928,13.241 4.928,12C4.928,10.759 5.255,9.539 5.876,8.464L5.072,8Z" />
          </g>
          <g transform="matrix(1.33445,0,0,1.33445,-1.027,-3.98589)">
            <path d="M3.808,6.264C2.631,7.945 2,9.948 2,12C2,14.052 2.631,16.055 3.808,17.736L4.567,17.205C3.499,15.679 2.926,13.862 2.926,12C2.926,10.138 3.499,8.321 4.567,6.795L3.808,6.264Z" />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default Icon;
