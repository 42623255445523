import React from 'react';

import {
  CardHeader,
  Typography,
  makeStyles,
} from '@material-ui/core';

import PropTypes from 'prop-types';
import clsx from 'clsx';
import { get, isString } from 'lodash';

const useStyles = makeStyles((theme) => ({
  headerIcon: {
    color: theme.palette.primary.main,
    display: 'block'
  },

  title: {
    marginRight: theme.spacing(2)
  },

  // subheader: {
  //   color: theme.palette.text.secondary
  // },

  cardHeaderButtons: {
    '& .MuiButtonBase-root': {
      marginTop: -6,
      marginBottom: -16,
    }
  },

  compact: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  }
}));

const CardHeaderStyled = ({ title, subheader, className, classes, compact, avatar, ...rest }) => {
  const baseClasses = useStyles();

  const avatarNode = (avatar == null || React.isValidElement(avatar))
    ? avatar
    : React.createElement(avatar, { className: clsx(baseClasses.headerIcon, get(classes, 'avatar')) });

  let titleNode = null;
  if (title) {
    if (isString(title)) {
      titleNode = (
        <Typography
          variant="h5"
          className={clsx(baseClasses.title, get(classes, 'title'))}
          component="span"
        >
          {title}
        </Typography>
      );
    } else {
      titleNode = title;
    }
  }

  let subheaderNode = null;
  if (subheader) {
    if (isString(subheader)) {
      subheaderNode = (
        <Typography
          variant="body2"
          className={clsx(baseClasses.subheader, get(classes, 'subheader'))}
          color="textSecondary"
          component="span"
        >
          {subheader}
        </Typography>
      );
    } else {
      subheaderNode = subheader;
    }
  }

  return (
    <CardHeader
      disableTypography
      className={clsx(baseClasses.cardHeaderButtons, className, compact ? baseClasses.compact : null)}
      title={titleNode}
      subheader={subheaderNode}
      avatar={avatarNode}
      classes={classes}
      {...rest}
    />
  );
};

CardHeaderStyled.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  subheader: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  className: PropTypes.string,
  classes: PropTypes.object,
  compact: PropTypes.bool,
  avatar: PropTypes.oneOfType([PropTypes.element, PropTypes.elementType]),
};

export default CardHeaderStyled;
