/* eslint-disable max-len */
import React from 'react';
import { SvgIcon } from '@material-ui/core';

const Icon = (props) => {
  return (
    <SvgIcon {...props}>
      <g id="Grow-Cycle" transform="matrix(1,0,0,1,1.12129,1.12128)">
        <g transform="matrix(0.625,0,0,0.625218,3.49997,1.60063)">
          <path
            d="M6.05,8.05C3.32,10.78 3.32,15.2 6.03,17.93C7.5,14.53 10.12,11.69 13.39,10C10.62,12.34 8.68,15.61 8,19.32C10.6,20.55 13.8,20.1 15.95,17.95C19.43,14.47 20,4 20,4C20,4 9.53,4.57 6.05,8.05Z"
          />
        </g>
        <g transform="matrix(1.31847,-0.000507009,0.000530152,1.37865,-7.36629,-8.3596)">
          <path d="M13.925,20.216C18.114,20.216 21.509,16.968 21.509,12.962C21.509,8.959 18.111,5.709 13.925,5.709C9.739,5.709 6.34,8.959 6.34,12.962C6.34,16.012 8.334,18.736 11.331,19.778L11.849,18.415C9.452,17.581 7.857,15.402 7.857,12.962C7.857,9.76 10.576,7.16 13.925,7.16C17.274,7.16 19.992,9.76 19.992,12.962C19.992,16.167 17.276,18.765 13.925,18.765L13.925,20.216Z" />
        </g>
        <g transform="matrix(-0.000863195,-1.02639,1.37136,-0.00115332,-19.4425,28.4042)">
          <path d="M10.016,20.356L13.406,24.052L6.629,24.052L10.016,20.356Z" />
        </g>
      </g>
    </SvgIcon>
  );
};

export default Icon;
