import { includes } from 'lodash';

class Bucket {
  constructor(maxSize) {
    this._maxSize = maxSize;
    this._values = [];
  }

  push(value) {
    if (value != null) {
      this._values.push(value);
      if (this._values.length > this._maxSize) {
        this._values.shift();
      }
    }
  }

  includes(value) {
    return includes(this._values, value);
  }

  values() {
    return this._values;
  }

  clear() {
    this._values = [];
  }
}

export default Bucket;
