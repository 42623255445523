/* eslint-disable react/jsx-tag-spacing */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-indent */
/* eslint-disable max-len */
import React from 'react';
import { SvgIcon } from '@material-ui/core';
import PropTypes from 'prop-types';

const Icon = ({ style, ...rest }) => {
  return (
    <SvgIcon {...rest} style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinecap: 'round', strokeLinejoin: 'round', strokeMiterlimit: '1.5', ...style }}>
      <g id="Layer1" transform="matrix(1,0,0,1,-2,0)">
        <path d="M19.394,5.071C19.547,5.053 19.7,5.102 19.815,5.205C19.929,5.309 19.993,5.456 19.991,5.61C19.952,8.181 19.802,18.108 19.766,20.486C19.764,20.617 19.706,20.741 19.608,20.828C19.509,20.914 19.378,20.954 19.248,20.938C17.74,20.756 13.325,20.221 11.929,20.052C11.684,20.022 11.5,19.815 11.5,19.568C11.5,17.495 11.5,8.57 11.5,6.452C11.5,6.194 11.693,5.977 11.949,5.947C13.371,5.78 17.774,5.262 19.394,5.071ZM13,12C13.414,12 13.75,12.448 13.75,13C13.75,13.552 13.414,14 13,14C12.586,14 12.25,13.552 12.25,13C12.25,12.448 12.586,12 13,12Z"/>
        <path d="M8,9L8,3L22,3L22,21" style={{ fill: 'none', stroke: 'currentColor', strokeWidth: '1.75px' }}/>
        <g transform="matrix(1,0,0,1,3,0)">
            <path d="M5,17L5,20.942" style={{ fill: 'none', stroke: 'currentColor', strokeWidth: '1.8px', strokeLinejoin: 'miter', strokeMiterlimit: '1' }}/>
        </g>
        <g transform="matrix(1,0,0,1.5,0.808642,-6.5)">
            <path d="M5,11C2.056,13.003 5,15 5,15" style={{ fill: 'none', stroke: 'currentColor', strokeWidth: '0.78px', strokeLinejoin: 'miter', strokeMiterlimit: '1' }}/>
        </g>
        <g transform="matrix(0.9,0,0,1,3,0.00461)">
            <path d="M5,11C2.5,12.995 5,15 5,15" style={{ fill: 'none', stroke: 'currentColor', strokeWidth: '1.05px', strokeLinejoin: 'miter', strokeMiterlimit: '1' }}/>
        </g>
        <g transform="matrix(1,0,0,0.626729,4.11728,4.85714)">
            <path d="M5,11C3.611,13.007 5,15 5,15" style={{ fill: 'none', stroke: 'currentColor', strokeWidth: '1.2px', strokeLinejoin: 'miter', strokeMiterlimit: '1' }}/>
        </g>
    </g>
    </SvgIcon>
  );
};

Icon.propTypes = {
  style: PropTypes.object
};

export default Icon;
