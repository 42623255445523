import React from 'react';
import {
  Box,
  Checkbox,
  FormControlLabel,
  makeStyles
} from '@material-ui/core';

import PropTypes from 'prop-types';
import { defaultTo } from 'lodash';
import clsx from 'clsx';

import { FormControlContainer } from 'src/components/form';

const useStyles = makeStyles((theme) => ({
  checkboxesContainer: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(2),
    display: 'flex',
    flexWrap: 'wrap'
  },

  checkboxLabel: {
    whiteSpace: 'nowrap',
    // color: '#FF0000'
  }
}));

const FormikCheckboxes = ({
  label,
  fullWidth,
  errorText,
  helperText,
  disabled,
  options,
  onChange,
  isChecked,
  optionValue,
  optionLabel,
  classes: classesArg
}) => {
  const baseClasses = useStyles();
  const classes = classesArg || {};

  const handleChange = (value, checked) => {
    if (onChange) {
      onChange(value, checked);
    }
  };

  return (
    <FormControlContainer
      label={label}
      fullWidth={fullWidth}
      errorMessage={errorText}
      helperText={helperText}
    >
      <Box className={clsx(baseClasses.checkboxesContainer, classes.container)}>
        {options && options.map((option) => {
          const optValue = optionValue ? optionValue(option) : defaultTo(option.value, option.id);
          const optLabel = optionLabel ? optionLabel(option) : defaultTo(option.label, option.name);
          return (
            <Box className={classes.item} key={optValue}>
              <FormControlLabel
                disabled={disabled}
                control={(
                  <Checkbox
                    checked={isChecked && isChecked(optValue)}
                    onChange={(event) => handleChange(optValue, event.target.checked)}
                  />
                )}
                label={optLabel}
                classes={{
                  root: classes.checkboxRoot,
                  label: clsx(baseClasses.checkboxLabel, classes.checkboxLabel)
                }}
              />
            </Box>
          );
        })}
      </Box>
    </FormControlContainer>
  );
};

FormikCheckboxes.propTypes = {
  label: PropTypes.string,
  fullWidth: PropTypes.bool,
  errorText: PropTypes.string,
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
  options: PropTypes.array,
  onChange: PropTypes.func,
  isChecked: PropTypes.func,
  optionValue: PropTypes.func,
  optionLabel: PropTypes.func,
  classes: PropTypes.object
};

export default FormikCheckboxes;
