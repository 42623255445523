import { isFinite } from 'lodash';

/* eslint-disable no-restricted-globals */
export default (value) => {
  if (value == null) {
    return false;
  }

  return isFinite(value) || isFinite(parseFloat(value));
};
