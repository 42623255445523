import React from 'react';
import { Navigate, withOutlet } from 'src/components/Router';

import DrawerLayout from 'src/layouts/DrawerLayout';
import SimpleLayout from 'src/layouts/SimpleLayout';
import NoExtraLayout from 'src/layouts/NoExtraLayout';

import LoginView from 'src/modules/core/views/auth/LoginView';
import NotFoundView from 'src/modules/core/views/errors/NotFoundView';
import SystemErrorView from 'src/modules/core/views/errors/SystemErrorView';
import NavigateReload from 'src/modules/core/views/common/NavigateReload';
import UnproxyView from './modules/core/views/common/UnproxyView';

// import log from 'src/support/Logger';

const DrawerLayoutOutlet = withOutlet(DrawerLayout);
const SimpleLayoutOutlet = withOutlet(SimpleLayout);
const NoExtraLayoutOutlet = withOutlet(NoExtraLayout);

const evaluateRoute = (systemService, config, pathPrefix, allowAuthKey) => {
  const { path, element, authenticated, role, feature, customerAccess, loginRedirect } = config;
  if (!authenticated && !role && !feature) {
    return { path, element };
  }

  if (!systemService.isUserLoggedIn()) {
    // Allow login redirect. Default to true if path does not contain variables.
    const allowLoginRedirect = (loginRedirect == null && path.indexOf(':') === -1) || loginRedirect;

    // User is not logged in. Send them to login page
    const state = !allowLoginRedirect ? null : { successPath: `/${pathPrefix}/${path}` };
    return { path, element: <Navigate to="/login" state={state} /> };
  }

  if (!allowAuthKey && systemService.isAuthKey()) {
    return { path, element: <Navigate to="/login" /> };
  }

  if (
    (role != null && !systemService.hasAuthority(role))
    || (feature != null && !systemService.hasFeature(feature))
    || (customerAccess != null && !systemService.hasCustomerAccess(customerAccess))
  ) {
    // User does not have access to this page. Send them to app start.
    return { path, element: <Navigate to="/" /> };
  }

  return { path, element };
};

const evaluateRoutes = (systemService, configs, pathPrefix, allowAuthKey) => {
  if (!configs) {
    return [];
  }

  return configs.map((config) => evaluateRoute(systemService, config, pathPrefix, allowAuthKey));
};

const buildRoutes = (systemService, composition) => {
  return [
    {
      path: 'app',
      element: <DrawerLayoutOutlet />,
      children: [
        ...evaluateRoutes(systemService, composition.fetchRoutes('app'), 'app', false),
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    {
      path: 'apn',
      element: <NoExtraLayoutOutlet />,
      children: [
        ...evaluateRoutes(systemService, composition.fetchRoutes('apn'), 'apn', true),
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    {
      path: 'admin',
      element: <DrawerLayoutOutlet admin />,
      children: [
        ...evaluateRoutes(systemService, composition.fetchRoutes('admin'), 'admin', false),
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    {
      path: '/',
      element: <SimpleLayoutOutlet />,
      children: [
        { path: 'navigatereload', element: <NavigateReload /> },
        { path: 'login', element: <LoginView /> },
        { path: 'unproxy', element: <UnproxyView /> },
        ...evaluateRoutes(systemService, composition.fetchRoutes('root'), ''),
        { path: '404', element: <NotFoundView /> },
        { path: 'error', element: <SystemErrorView /> },
        { path: '/', element: <Navigate to="/app/dashboard" /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    }
  ];
};

export default buildRoutes;
