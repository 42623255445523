/* eslint-disable react/jsx-tag-spacing */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-indent */
/* eslint-disable max-len */
import React from 'react';
import { SvgIcon } from '@material-ui/core';
import PropTypes from 'prop-types';

const Icon = ({ style, ...rest }) => {
  return (
    <SvgIcon {...rest} style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinejoin: 'round', strokeMiterlimit: '2', ...style }}>
      <g id="Layer6">
        <g transform="matrix(0.23145,0,0,0.23145,2.41694,-0.697045)">
          <path d="M9,52.25L9,63.5L20.25,63.5L53.43,30.32L42.18,19.07L9,52.25ZM62.13,21.62C63.3,20.45 63.3,18.56 62.13,17.39L55.11,10.37C54.55,9.808 53.788,9.493 52.995,9.493C52.202,9.493 51.44,9.808 50.88,10.37L45.39,15.86L56.64,27.11L62.13,21.62Z" style={{ fillRule: 'nonzero' }} />
        </g>
        <g transform="matrix(3.80063e-17,0.62069,-0.999998,6.12322e-17,23.25,11.1379)">
          <path d="M3,12.562L3,9.938L11.458,9.938L11.458,7.5L17.5,11.25L11.458,15L11.458,12.562L3,12.562Z" />
        </g>
        <g transform="matrix(3.80063e-17,-0.62069,-0.999998,-6.12322e-17,28.25,18.3621)">
          <path d="M3,12.562L3,9.938L11.458,9.938L11.458,7.5L17.5,11.25L11.458,15L11.458,12.562L3,12.562Z" />
        </g>
      </g>
    </SvgIcon>
  );
};

Icon.propTypes = {
  style: PropTypes.object
};

export default Icon;
