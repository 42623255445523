import React from 'react';

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from '@material-ui/core';

import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { defaultTo, get } from 'lodash';

const FormikCheckbox = ({
  name,
  label,
  disabled,
  helperText,
  labelPlacement,
  size,
  onChange,
  ...rest
}) => {
  const {
    values,
    errors,
    touched,
    submitCount,
    handleChange
  } = useFormikContext();

  // Show field error if form has been submitted or if the field has been touched
  const showError = Boolean((submitCount > 0 || get(touched, name)) && get(errors, name));

  const tempHelperText = showError ? get(errors, name) : helperText;

  const performChange = (...args) => {
    handleChange(...args);
    if (onChange) {
      onChange(...args);
    }
  };

  return (
    <FormControl
      disabled={disabled}
      error={showError}
      size={size}
      {...rest}
    >
      {/* <InputLabel id={labelId}>{label}</InputLabel> */}
      <FormControlLabel
        control={(
          <Checkbox
            name={name}
            checked={defaultTo(get(values, name), false)}
            onChange={performChange}
            size={size}
          />
        )}
        label={label}
        labelPlacement={labelPlacement}
      />
      {tempHelperText && <FormHelperText>{tempHelperText}</FormHelperText>}
    </FormControl>
  );
};

FormikCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.any,
  labelPlacement: PropTypes.string,
  disabled: PropTypes.bool,
  size: PropTypes.string,
  helperText: PropTypes.string,
  onChange: PropTypes.func
};

export default FormikCheckbox;
