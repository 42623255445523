import React, { useState, useRef, useEffect } from 'react';

import {
  Box,
  Collapse,
  Fade,
  // makeStyles
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import PropTypes from 'prop-types';
import { isEqual, slice, concat, isString, get } from 'lodash';

import { useIsMounted } from 'src/utils/react';

// const useStyles = makeStyles((theme) => ({

// }));

const GlobalMessages = ({
  messages,
  onClear,
  timeout = 7000,
  transitionTimeout = 750,
  maxMessages = 2,
  severity = 'info',
  containerComponent = Box,
  containerProps,
  alertProps
}) => {
  const [transitionIn, setTransitionIn] = useState(false);
  const messagesRef = useRef(null);
  const timerRef = useRef();
  const clearTimerRef = useRef();
  const isMounted = useIsMounted();

  const messagesArray = slice(concat([], messages || []), 0, maxMessages);

  const performClear = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
    if (clearTimerRef.current) {
      clearTimeout(clearTimerRef.current);
      clearTimerRef.current = null;
    }
    messagesRef.current = null;
  };

  useEffect(() => {
    if (!messagesArray.length) {
      performClear();
      return;
    }

    if (onClear && !isEqual(messagesArray, messagesRef.current)) {
      performClear();
      messagesRef.current = messagesArray;
      setTransitionIn(true);

      if (timeout > 0) {
        timerRef.current = setTimeout(() => {
          if (isMounted()) {
            setTransitionIn(false);
            clearTimerRef.current = setTimeout(() => {
              messagesRef.current = null;
              if (isMounted()) {
                onClear();
              }
            }, transitionTimeout);
          }
        }, timeout);
      }
    }
    // return performClearTimeout;
  });

  if (messagesArray.length === 0) {
    return null;
  }

  return (
    <Collapse in={transitionIn} timeout={transitionTimeout} style={{ width: '100%' }}>
      <Fade in={transitionIn} timeout={transitionTimeout}>
        {React.createElement(
          containerComponent,
          { ...containerProps },
          <Box mt={-1}>
            {messagesArray.map((message, index) => {
              let alertText = null;
              let alertSeverity = null;
              if (isString(message)) {
                alertText = message;
                alertSeverity = severity;
              } else {
                alertText = message.message;
                alertSeverity = get(message, 'severity', severity);
              }

              return (
                // eslint-disable-next-line react/no-array-index-key
                <Box mt={1} key={index}>
                  <Alert severity={alertSeverity} {...alertProps}>{alertText}</Alert>
                </Box>
              );
            })}
          </Box>)}
      </Fade>
    </Collapse>
  );
};

GlobalMessages.propTypes = {
  messages: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
  onClear: PropTypes.func,
  timeout: PropTypes.number,
  transitionTimeout: PropTypes.number,
  severity: PropTypes.string,
  maxMessages: PropTypes.number,
  containerComponent: PropTypes.elementType,
  containerProps: PropTypes.object,
  alertProps: PropTypes.object,
};

export default GlobalMessages;
