import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { get, includes } from 'lodash';

import { ConfirmDialog } from 'src/components/core';
import { FormCheckboxes } from 'src/components/form';

const FormikCheckboxes = ({ name, confirmCallback, ...rest }) => {
  const [confirm, setConfirm] = useState();
  const {
    values,
    errors,
    touched,
    submitCount,
    setFieldTouched,
    setFieldValue
  } = useFormikContext();

  const valueList = get(values, name) || [];

  const isChecked = (value) => {
    return includes(valueList, value);
  };

  const applyChange = (value, selected) => {
    if (!selected) {
      setFieldValue(name, valueList.filter((e) => e !== value));
    } else if (!isChecked(value)) {
      setFieldValue(name, [...valueList, value]);
    }
    setFieldTouched(name, true, false);
  };

  const handleChange = (value, selected) => {
    if (confirmCallback) {
      const dialogProps = confirmCallback(value, selected);
      if (dialogProps) {
        setConfirm({
          value,
          selected,
          dialogProps
        });
        return;
      }
    }

    applyChange(value, selected);
  };

  const errorText = (submitCount > 0 || get(touched, name)) ? get(errors, name) : null;

  return (
    <>
      {confirm && (
        <ConfirmDialog
          maxWidth="sm"
          {...confirm.dialogProps}
          open
          onCancel={() => setConfirm(null)}
          onConfirm={() => {
            applyChange(confirm.value, confirm.selected);
            setConfirm(null);
          }}
        />
      )}
      <FormCheckboxes
        onChange={handleChange}
        isChecked={isChecked}
        errorText={errorText}
        {...rest}
      />
    </>
  );
};

FormikCheckboxes.propTypes = {
  name: PropTypes.string,
  confirmCallback: PropTypes.func
};

export default FormikCheckboxes;
