export default (obj, property, message) => {
  const type = typeof obj;
  if (type !== 'object') {
    throw new Error(`${message}${message && '. '}Expected object but got '${type}'`);
  }

  if (!Object.prototype.hasOwnProperty.call(obj, property)) {
    throw new Error(`${message}${message && '. '}Missing property '${property}' on object ${JSON.stringify(obj)}`);
  }
};
