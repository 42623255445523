/* eslint-disable max-len */
import React from 'react';
import { SvgIcon } from '@material-ui/core';

const Icon = (props) => {
  return (
    <SvgIcon {...props}>
      <g id="Layer1">
        <g transform="matrix(1.2012,0,0,1.2012,0.0975976,-2.41441)">
          <path d="M9,11C10.66,11 11.99,9.66 11.99,8C11.99,6.34 10.66,5 9,5C7.34,5 6,6.34 6,8C6,9.66 7.34,11 9,11ZM9,13C6.67,13 2,14.17 2,16.5L2,19L9,19L9,16.75C9,15.9 8.792,14.375 9.695,13.139C9.695,13.139 9.525,13 9,13Z" style={{ fillRule: 'nonzero' }} />
        </g>
        <g transform="matrix(0.26986,0,0,0.246947,8.23716,7.69064)">
          <path d="M33,21.5L51,21.5L51,27.5L33,27.5L33,21.5ZM33,33.5L51,33.5L51,39.5L33,39.5L33,33.5ZM33,45.5L51,45.5L51,51.5L33,51.5L33,45.5ZM21,21.5L27,21.5L27,27.5L21,27.5L21,21.5ZM21,33.5L27,33.5L27,39.5L21,39.5L21,33.5ZM21,45.5L27,45.5L27,51.5L21,51.5L21,45.5Z" style={{ fillRule: 'nonzero' }} />
        </g>
      </g>
    </SvgIcon>
  );
};

export default Icon;
