/* eslint-disable no-unused-vars */
import { isArrayLike, isFunction, includes } from 'lodash';

const DEFAULT_OPTIONS = {
  // Format of global message. True for objects, false for plain strings.
  objectGlobal: true,
  // Safe fields. Other fields will be promoted to global.
  fields: null,
  // Method to override make global behavoir. 'fields' will be ignored.
  makeGlobals: null,
  // Map fields
  mapField: null
};

const formMergeErrors = (errors, actions, setMessages, options) => {
  const opts = { ...DEFAULT_OPTIONS, ...options };
  const { globalErrors, fieldErrors } = errors;

  const formatGlobal = (message) => {
    return opts.objectGlobal ? { message, severity: 'error' } : message;
  };

  let tempGlobal = [];
  if (globalErrors && globalErrors.length) {
    tempGlobal = globalErrors.map(formatGlobal);
  }

  if (fieldErrors) {
    const makeGlobal = opts.makeGlobal
      || ((key, value) => {
        if (!isArrayLike(opts.fields) || includes(opts.fields, key)) {
          return null;
        }
        return `'${key}' ${value}`;
      });

    Object.keys(fieldErrors).forEach((key) => {
      const value = fieldErrors[key];

      const globalMessage = makeGlobal(key, value);
      if (globalMessage) {
        tempGlobal.push(formatGlobal(globalMessage));
      } else if (actions != null && actions.setFieldError != null) {
        const mapKey = opts.mapField ? opts.mapField(key, value) : null;
        actions.setFieldError(mapKey || key, value);
      }
    });
  }

  if (setMessages && tempGlobal.length > 0) {
    setMessages(tempGlobal);
  }
};

export default formMergeErrors;
