import React, { useState, useRef } from 'react';
import {
  Button,
  Card,
  CardActions,
  Divider,
  makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { grey } from '@material-ui/core/colors';
import { useTheme } from '@material-ui/styles';
import { ButtonPopover } from 'src/components/core';
import { SketchPicker } from 'react-color';

const DEFAULT_COLOR = grey[400];

const isGrayScale = (value) => {
  if (!value) {
    return false;
  }

  value = value.trim().toLowerCase();
  if (value.startsWith('#')) {
    value = value.substring(1);
  }

  if (value.length === 6) {
    const red = value.substring(0, 2);
    return red === value.substring(2, 4) && red === value.substring(4, 6);
  }

  if (value.length === 3) {
    const red = value.substring(0, 1);
    return red === value.substring(1, 2) && red === value.substring(2, 3);
  }

  return value === 'black' || value === 'grey' || value === 'white';
};

const getPresets = (theme) => {
  const { palette } = theme;

  // let presets = [
  //   '#D0021B',
  //   '#DB3E00',
  //   '#F5A623',
  //   '#F8E71C',
  //   '#8B572A',
  //   '#7ED321',
  //   '#417505',
  //   '#BD10E0',
  //   '#9013FE',
  //   '#4A90E2',
  //   '#50E3C2',
  //   '#B8E986'
  // ];

  let presets = [
    '#B80000', '#DB3E00', '#FCCB00', '#008B02', '#006B76', '#1273DE', '#004DCF',
    '#5300EB', '#EB9694', '#FAD0C3', '#FEF3BD', '#C1E1C5', '#BEDADC', '#C4DEF6',
    '#BED3F3', '#D4C4FB'
  ];

  if (!isGrayScale(palette.primary.main)) {
    presets.push(palette.primary.dark);
    presets.push(palette.primary.main);
    presets.push(palette.primary.light);
  }

  if (!isGrayScale(palette.secondary.main)) {
    presets.push(palette.secondary.dark);
    presets.push(palette.secondary.main);
    presets.push(palette.secondary.light);
  }

  presets = presets.concat([
    '#000000',
    grey[800],
    grey[600],
    grey[400],
    grey[200],
    '#FFFFFF'
  ]);

  return presets;
};

const useStyles = makeStyles(() => ({
  root: {
    paddingLeft: 5,
    paddingRight: 5,
    minWidth: 50
  },
  label: {
    backgroundColor: (props) => props.buttonColor
  },
  actionsRoot: {
    justifyContent: 'flex-end'
  }
}));

const ColorPicker = ({ width = 240, color = DEFAULT_COLOR, okCancel, buttonClasses, buttonProps, onChange, onChangeComplete }) => {
  const theme = useTheme();
  const [pickerColor, setPickerColor] = useState(color);
  const originalColor = useRef(color);
  const [presetColors] = useState(() => getPresets(theme));
  const classes = useStyles({ buttonColor: pickerColor });
  const popoverRef = useRef();

  const closePopover = () => popoverRef.current && popoverRef.current.close();

  const handleColorChange = (hex) => {
    if (hex !== pickerColor) {
      if (onChange) {
        onChange(hex);
      }
      setPickerColor(hex);
    }
  };

  const handleChange = (changeColor) => {
    // Called by picker as color changes.
    handleColorChange(changeColor.hex);
  };

  const handleOnChangeComplete = () => {
    if (onChangeComplete && originalColor.current !== pickerColor) {
      onChangeComplete(pickerColor);
    }
  };

  const handleCancel = () => {
    handleColorChange(originalColor.current);
    closePopover();
  };

  const handleOk = () => {
    handleOnChangeComplete();
    closePopover();
  };

  const handleOnClose = () => {
    // Called on background click.
    if (okCancel) {
      // Cancel.
      handleColorChange(originalColor.current);
    } else {
      // Ok.
      handleOnChangeComplete();
    }
  };

  return (
    <ButtonPopover
      ref={popoverRef}
      buttonLabel="&nbsp;"
      buttonProps={{
        variant: 'outlined',
        size: 'medium',
        classes: {
          root: classes.root,
          label: classes.label,
          ...buttonClasses
        },
        ...buttonProps
      }}
      popoverProps={{
        anchorOrigin: {
          vertical: 'center',
          horizontal: 'left'
        },
        transformOrigin: {
          vertical: 'center',
          horizontal: 'right'
        }
      }}
      onClose={handleOnClose}
    >
      <Card>
        <SketchPicker
          width={width}
          disableAlpha
          presetColors={presetColors}
          color={pickerColor}
          onChange={handleChange}
          styles={{
            picker: {
              width,
              padding: '10px 10px 0',
              boxSizing: 'initial',
              background: '#FFFFFF'
            }
          }}
        />
        {okCancel && (
          <>
            <Divider />
            <CardActions
              classes={{
                root: classes.actionsRoot
              }}
            >
              <Button autoFocus onClick={handleCancel} color="primary">
                Cancel
              </Button>
              <Button onClick={handleOk} color="primary">
                Ok
              </Button>
            </CardActions>
          </>
        )}
      </Card>
    </ButtonPopover>

  );
};

ColorPicker.propTypes = {
  width: PropTypes.number,
  color: PropTypes.string,
  okCancel: PropTypes.bool,
  buttonClasses: PropTypes.object,
  buttonProps: PropTypes.object,
  onChange: PropTypes.func,
  onChangeComplete: PropTypes.func,
};

export default ColorPicker;
