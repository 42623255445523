/* eslint-disable react/jsx-tag-spacing */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-indent */
/* eslint-disable max-len */
import React from 'react';
import { SvgIcon } from '@material-ui/core';
import PropTypes from 'prop-types';

const Icon = ({ style, ...rest }) => {
  return (
    <SvgIcon {...rest} style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinejoin: 'round', strokeMiterlimit: '2', ...style }}>
      <g transform="matrix(0.861355,1.06261,-1.0811,0.876346,17.735,-3.41065)">
        <path d="M11.551,7.605C11.551,5.23 9.556,3.305 7.095,3.305C4.635,3.305 2.638,5.232 2.638,7.605C2.638,7.881 2.666,8.155 2.72,8.426L3.758,8.231C3.716,8.025 3.695,7.815 3.695,7.605C3.695,5.795 5.218,4.325 7.095,4.325C8.972,4.325 10.494,5.794 10.494,7.605L11.551,7.605Z"/>
    </g>
    <g transform="matrix(0.000808331,1,-1,0.000808331,19.2277,-3.02172)">
        <path d="M6.922,3.328C6.922,2.971 6.633,2.682 6.276,2.682L4.702,2.682C4.346,2.682 4.056,2.971 4.056,3.328C4.056,3.684 4.346,3.974 4.702,3.974L6.276,3.974C6.633,3.974 6.922,3.684 6.922,3.328Z"/>
    </g>
    <g transform="matrix(-0.707107,-0.707107,0.707107,-0.707107,11.2071,10.6509)">
        <path d="M6.922,3.328C6.922,2.971 6.633,2.682 6.276,2.682L4.702,2.682C4.346,2.682 4.056,2.971 4.056,3.328C4.056,3.684 4.346,3.974 4.702,3.974L6.276,3.974C6.633,3.974 6.922,3.684 6.922,3.328Z"/>
    </g>
    <g transform="matrix(-0.707107,-0.707107,0.707107,-0.707107,23.4258,22.9982)">
        <path d="M6.922,3.328C6.922,2.971 6.633,2.682 6.276,2.682L4.702,2.682C4.346,2.682 4.056,2.971 4.056,3.328C4.056,3.684 4.346,3.974 4.702,3.974L6.276,3.974C6.633,3.974 6.922,3.684 6.922,3.328Z"/>
    </g>
    <g transform="matrix(0.707107,-0.707107,0.707107,0.707107,15.7018,5.94455)">
        <path d="M6.922,3.328C6.922,2.971 6.633,2.682 6.276,2.682L4.702,2.682C4.346,2.682 4.056,2.971 4.056,3.328C4.056,3.684 4.346,3.974 4.702,3.974L6.276,3.974C6.633,3.974 6.922,3.684 6.922,3.328Z"/>
    </g>
    <g transform="matrix(1,0,0,1,18.2606,6.84927)">
        <path d="M6.922,3.328C6.922,2.971 6.633,2.682 6.276,2.682L4.702,2.682C4.346,2.682 4.056,2.971 4.056,3.328C4.056,3.684 4.346,3.974 4.702,3.974L6.276,3.974C6.633,3.974 6.922,3.684 6.922,3.328Z"/>
    </g>
    <g transform="matrix(0.829079,0,0,0.854785,-1.77636e-15,2.90429)">
        <path d="M12,6C14.62,6 16.88,7.86 17.39,10.43L17.69,11.93L19.22,12.04C20.78,12.14 22,13.45 22,15C22,16.65 20.65,18 19,18L6,18C3.79,18 2,16.21 2,14C2,11.95 3.53,10.24 5.56,10.03L6.63,9.92L7.13,8.97C8.08,7.14 9.94,6 12,6M12,4C9.11,4 6.6,5.64 5.35,8.04C2.34,8.36 0,10.91 0,14C0,17.31 2.69,20 6,20L19,20C21.76,20 24,17.76 24,15C24,12.36 21.95,10.22 19.35,10.04C18.67,6.59 15.64,4 12,4Z" style={{ fillRule: 'nonzero' }}/>
    </g>
    </SvgIcon>
  );
};

Icon.propTypes = {
  style: PropTypes.object
};

export default Icon;
