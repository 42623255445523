/* eslint-disable max-len */
import React from 'react';
import { SvgIcon } from '@material-ui/core';

const Icon = (props) => {
  return (
    <SvgIcon {...props}>
      <g id="Device">
        <g transform="matrix(1.05882,0,0,1.125,-1.23529,-2.0625)">
          <path d="M21,6.827C21,5.543 19.892,4.5 18.527,4.5L6.473,4.5C5.108,4.5 4,5.543 4,6.827L4,18.173C4,19.457 5.108,20.5 6.473,20.5L18.527,20.5C19.892,20.5 21,19.457 21,18.173L21,6.827Z" style={{ fill: 'none', stroke: 'currentColor', strokeWidth: '1.83px' }} />
        </g>
        <g transform="matrix(0.457143,0,0,0.457143,4,4)">
          <path d="M21.875,13.125L13.125,13.125L13.125,21.875L21.875,21.875L21.875,13.125ZM18.958,18.958L16.042,18.958L16.042,16.042L18.958,16.042L18.958,18.958ZM30.625,16.042L30.625,13.125L27.708,13.125L27.708,10.208C27.708,8.604 26.396,7.292 24.792,7.292L21.875,7.292L21.875,4.375L18.958,4.375L18.958,7.292L16.042,7.292L16.042,4.375L13.125,4.375L13.125,7.292L10.208,7.292C8.604,7.292 7.292,8.604 7.292,10.208L7.292,13.125L4.375,13.125L4.375,16.042L7.292,16.042L7.292,18.958L4.375,18.958L4.375,21.875L7.292,21.875L7.292,24.792C7.292,26.396 8.604,27.708 10.208,27.708L13.125,27.708L13.125,30.625L16.042,30.625L16.042,27.708L18.958,27.708L18.958,30.625L21.875,30.625L21.875,27.708L24.792,27.708C26.396,27.708 27.708,26.396 27.708,24.792L27.708,21.875L30.625,21.875L30.625,18.958L27.708,18.958L27.708,16.042L30.625,16.042ZM24.792,24.792L10.208,24.792L10.208,10.208L24.792,10.208L24.792,24.792Z" style={{ fillRule: 'nonzero' }} />
        </g>
      </g>
    </SvgIcon>
  );
};

export default Icon;
